.str-chat__channel-list-team__header{
        padding: 0px !important;
        box-shadow: none !important;
}

.str-chat__channel-preview button {
    text-decoration: none;
    margin: 0px;
}

.str-chat__channel-list-team__header {
    margin: 0px !important;
}

.str-chat__avatar {
    display: none;
}

.str-chat__channel-list-team__header--left {
    display: none;
}

.str-chat__channel-list-team {
    width: 300px;
}
.str-chat__channel-preview--active {
    background: none;
}

.report-index {
    margin-right: 30px;
}

.report-body .active {
    color: var(--light-purple);
}