.create-challenge .teacher-header {
  width: 1024px;
  height: 82px;
  box-shadow: 0 6px 8px 0 rgba(135, 153, 171, 0.1);
  background-color: var(--true-white);
  padding: 60px 0px 0px 0px;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.56px;
  text-align: left;
  color: var(--grey-blue);
}

.create-challenge .teacher-header .title {
  width: 500px;
  height: 49px;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.36;
  color: var(--charcoal-grey);
}

.create-challenge .teacher-header .arrow-point-to-right {
  width: 12px;
  height: 20px;
  object-fit: contain;
  margin: 10px 26px 0px 60px;
}

.create-challenge .teacher-header .progress {
  padding-right: 60px;
}

.create-challenge .progress-background {
  width: 205px;
  height: 10px;
  border-radius: 4px;
  background-color: #ededed;
}

.create-challenge .progress-progress {
  height: 10px;
  border-radius: 4px;
  background-color: #40d360;
}

.create-challenge .create-challenge-content {
  width: 674px;
  height: 556px;
  border-radius: 16px;
  box-shadow: 0 6px 8px 0 rgba(101, 125, 149, 0.1);
  background-color: var(--true-white);
  margin: 30px auto;
  padding: 10px 60px;
}

.create-challenge .create-challenge-content .title {
  width: 500px;
  height: 24px;
  font-family: Nunito;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: 0.36px;
  text-align: left;
  color: var(--charcoal-grey);
  padding: 30px 0px 14px 0px;
}

.create-challenge .create-challenge-content select {
  width: 674px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px var(--silver);
  background-color: var(--true-white);
  font-family: Nunito;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  text-align: left;
  color: var(--grey-blue);
}

.create-challenge .create-challenge-content .next-button {
  position: relative;
  width: fit-content;
  height: 48px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(153, 40, 249, 0.2);
  background-color: var(--light-purple);
  top: 226px;
  margin: auto;
  margin-bottom: 40px;
}

.create-challenge .create-challenge-content button {
  width: 130px;
  height: 48px;
  font-family: Nunito;
  margin: auto;
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.63px;
  text-align: center;
  color: var(--true-white);
}

.create-challenge .create-challenge-content input {
  width: 674px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px var(--silver);
  background-color: var(--true-white);
  font-family: Nunito;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  text-align: left;
  color: var(--grey-blue);
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
}

.create-challenge .step-3 {
  overflow-y: scroll;
  overflow-x: hidden;
  height: inherit;
}

.create-challenge .assign-button {}

.challenge-scrolling-wrapper {
  width: inherit;
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: rgb(51, 40, 40, 0.8) transparent;
  scrollbar-width: thin;
}

.challenge-scrolling-wrapper::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

.challenge-scrolling-wrapper::-webkit-scrollbar-thumb {
  background: rgb(51, 40, 40, 0.8);
  border-radius: 10px;
}

.challenge-scrolling-wrapper .single-com-item {
  display: block !important;
  overflow: hidden;
}

.challenge-scrolling-wrapper .com-response-text {
  max-width: 800px !important;
  width: inherit;
}

.challenge-scrolling-wrapper .com-response-text img {
  max-width: 300px;
}

.no-see {
  background: var(--true-white);
}