#adult-container {
  background: #f3f3f3;
  margin: auto;
  padding: 0px 60px !important;
  font-family: Nunito;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--charcoal-grey);
  padding-bottom: 40px;
}

.gyan-header {
  width: 100%;
  height: 114px;
  padding: 40px 40px 0px 0px;
}

.gyan-header h1 {
  float: left;
  width: fit-content;
  height: 49px;
  font-size: 36px;
  font-weight: 300;
  line-height: 1.36;
  font-weight: bold;
}

.gyan-header .profile-image {
  float: right;
  border-radius: 50%;
}

.gyan-header .message-image {
  float: right;
  height: 30px;
  margin-top: 30px;
  margin-right: 30px;
}

.recent-title {
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.78;
  letter-spacing: 0.4px;
  padding-top: 15px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: var(--charcoal-grey);
}

.small-donut {
  width: 48px;
  padding: 8px;
}

.large-donut {
  width: 140px;
  padding: 8px;
}

.gyan-performance {
  margin-top: 150px;
  width: 964px;
  height: 624px;
  border-radius: 16px;
  box-shadow: 0 6px 8px 0 rgba(101, 125, 149, 0.1);
  background-color: #ffffff;
  padding: 55px 60px;
}

.gyan-performance .recent-title {
  width: 264px;
  height: 32px;
  padding-bottom: 24px;
}

.gyan-performance .lesson-progress-donut {
  width: 25%;
  height: 200px;
  text-align: center;
}

.gyan-performance select {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: solid 1px silver;
  background-color: #ffffff;
  margin-bottom: 60px;
}

.grade-card {
  margin: 20px 0px;
}

.parent-grades .grade-card {
  margin: 0px !important;
}

.grade-card .grade {
  width: fit-content;
  height: 42px;
  background: transparent;
  border-radius: 10px 10px 0px 0px;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.25;
  letter-spacing: 0.7px;
  text-align: center;
  color: #657d95;
}

.grade-card .active {
  background: white !important;
}

.parent-grades .grade-tab :first-child {
  padding-left: 60px !important;
}

.grade-card .grade-tab {
  background: white;
  height: 58px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.7px;
  text-align: center;
  margin: 0px -60px;
  padding-bottom: 17px;
}

.grade-tab button :first {
  margin-left: 60px;
}

.parent-grades button {
  margin: 0px !important;
}

.grade-card .grade-tab button {
  float: left;
  padding: 30px 20px;
  width: fit-content;
  height: 22px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.7px;
  text-align: center;
  color: #657d95;
  margin-left: 60px;
}

.parent-reports .gyan-recent .challenge-card {
  width: 320px;
  height: 341px;
  border-radius: 18px;
  box-shadow: 0 6px 12px 0 rgba(65, 61, 61, 0.16);
  background-color: var(--true-white);
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
  margin: 0px 50px 10px 0px;
  display: inline-block;
  float: initial;
}

.parent-reports .view-challenge {
  width: 196px;
  height: 22px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.72px;
  text-align: center;
  color: var(--grey-blue);
  margin: auto;
}

.grade-card .grade-tab .create-challenge {
  margin-right: 80px;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background-color: #f3f3f3;
  margin-top: 20px;
  font-family: Nunito;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.48px;
  text-align: center;
  color: var(--grey-blue);
}

.grade-card .active-subject {
  color: var(--light-purple) !important;
  border-bottom: 3px solid var(--light-purple) !important;
}

.student-profile {
  text-align: center;
  height: 80px;
  margin: 10px;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.36;
  letter-spacing: 0.56px;
  text-align: center;
  color: var(--grey-blue);
}

.student-profile img {
  height: 70px;
  border-radius: 50%;
}

.student-scrolling-wrapper {
  width: 77%;
  height: 120px;
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  display: inline-block;
}

.student-scrolling-wrapper::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

/* .overflow-hidden {
  overflow: hidden;
  width: 30%;
} */

.gyan-grades {
  background: #f3f3f3;
}

.gyan-grades .dropdown {
  width: 300px;
}

.gyan-grades .child-picker {
  z-index: 50;
  position: absolute;
  left: 0;
  right: 0;
}

.gyan-grades .subject-picker {
  z-index: 50;
  position: absolute;
  left: 0;
  right: 0;
}

.parent-grades .gyan-grades .subject-picker {
  padding-top: 30px;
  padding-bottom: 5px;
}

.gyan-history {
  margin: 30px auto;
  width: 964px;
  height: fit-content;
  border-radius: 16px;
  box-shadow: 0 6px 8px 0 rgba(101, 125, 149, 0.1);
  background-color: #ffffff;
  padding: 55px 60px;
}

.gyan-history-view div {
  width: 152px;
  height: 42px;
  border-radius: 24px;
  background-color: #f3f3f3;
  background-repeat: no-repeat;
  background-position: 15% 50%;
  background-size: inherit;
  font-size: 12px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.54px;
  text-align: right;
  padding-right: 24px;
  color: var(--grey-blue);
  white-space: nowrap;
}

.gyan-history-view div button {
  position: relative;
  right: 30%;
  top: 10%;
}

.date-view {
  background-image: url('../../assets/images/line-chart.svg');
  margin-right: 22px !important;
}

.gyan-history-view .active {
  background-color: var(--light-purple) !important;
}

.gyan-history-view .active button {
  color: var(--true-white) !important;
}

.gyan-history-view .list-view active {
  background-image: url('../../assets/images/list-white.svg') !important;
}

.gyan-recent .history-row {
  width: 964px;
  height: 96px;
  margin: 23px 0px;
  background-color: var(--true-white);
  border-bottom: 1px solid #f3f3f3;
}

.gyan-recent .history-row:last-child {
  border-bottom: none !important;
}

.gyan-recent .history-subject {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px 10px 0px 0px;
}

.gyan-recent .history-details {
  width: 460px;
  height: 32px;
  margin-bottom: 8px;
  margin-right: 7px;
  font-family: Nunito;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: 0.36px;
  text-align: left;
  color: var(--charcoal-grey);
  overflow: hidden;
}

.gyan-recent .history-subject {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px 10px 0px 0px;
}

.gyan-recent .history-status {
  width: 223px;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: center;
  text-transform: uppercase;
}

.gyan-recent .history-status-complete {
  width: 109px;
  height: 24px;
  border-radius: 12px;
  background: rgba(30, 154, 34, 0.1);
  color: #1e9a22;
  padding-top: 10px;
}

.gyan-recent .history-status-incomplete {
  width: 123px;
  height: 24px;
  border-radius: 12px;
  background: rgba(255, 100, 30, 0.1);
  color: #ff641e;
  padding-top: 10px;
}

.gyan-recent .history-progress {}

.list-view {
  background-image: url('../../assets/images/list.svg');
}

.gyan-recent .list-view-header {
  width: 964px;
  height: 19px;
  margin: 40px 0px 20px 0px;
  float: left;
  text-transform: uppercase;
}

.gyan-recent .list-view-header :first-child {
  width: 467px;
  text-align: left;
}

.gyan-recent .list-view-header .middle {
  width: 223px;
}

.gyan-recent .list-view-header .last {
  width: 270px !important;
  text-align: right;
  width: auto;
}

.gyan-recent .list-view-header div {
  opacity: 0.7;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.56px;
  text-align: left;
  color: var(--grey-blue);
}

.gyan-grasp {
  width: 964px;
  height: fit-content;
  border-radius: 16px;
  box-shadow: 0 6px 8px 0 rgba(101, 125, 149, 0.1);
  background-color: #ffffff;
  margin: 30px auto;
  padding: 55px 55px !important;
  margin-bottom: 40px;
}

.gyan-grasp .recent-title {
  padding-bottom: 50px !important;
}

.gyan-grasp .grasp-widget {
  float: left;
  width: 54px;
  height: 54px;
  margin: 0px 23px;
  text-align: center;
}

.gyan-grasp .row {
  border-bottom: 1px solid #f3f3f3;
  height: 120px;
  padding-top: 30px;
}

.gyan-grasp .row:last-child {
  border-bottom: none;
}

.grasp-widget-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.grasp-widget-name {
  float: left;
  width: 250px;
}

.parent-reports {
  margin-top: 120px;
}

.parent-reports .header-date {
  margin-right: 24px;
  height: 19px;
  opacity: 0.7;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.56px;
  text-align: right;
  color: var(--grey-blue);
}

.yellow {
  background-color: #ffc501;
}

.orange {
  background-color: #ff641e;
}

.red {
  background-color: #f43d6e;
}

.pink {
  background-color: #f05fca;
}

.purple {
  background-color: #a463f7;
}

.blue {
  background-color: #4d65db;
}

#parent-dashboard-challenges .CircularProgressbar .CircularProgressbar-text {
  font-size: 8px;
}