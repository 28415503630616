#practice-container {
  position: relative;
  height: 100vh;
  min-width: 100vmax;
  margin: auto;
  overflow: hidden;
  /* top: -10px; */
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(/static/media/practice-background.svg);
}

.practice-playground {
  height: inherit;
  position: relative;
  margin: auto;
  padding-top: 60px;
}

.practice-playground h1 {
  position: relative;
  font-weight: bold;
  text-align: center;
  margin: 10px;
  color: white;
  text-transform: capitalize;
  padding-left: 143px;
}

.practice-playground .scrolling-wrapper:first-child {
  padding-left: 60px;
}
