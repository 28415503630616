.recommendation {
    width: 19.188em;
    height: 22.77em;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
}

.recommendation .e382965deccc6469fd2993650208f1fc {
    width: 19.188em;
    height: 8.875em;
    object-fit: contain;
    opacity: 0.83;
    background-image: url('../../assets/images/e-382965-deccc-6469-fd-2993650208-f-1-fc.png');
}

.recommendation .e382965deccc6469fd2993650208f1fa {
    width: 19.188em;
    height: 8.875em;
    object-fit: contain;
    background-color: rgb(77, 100, 218, 0.83);
    color: var(--true-white);
}

.recommendation .GRADE-1-MATH {
    font-size: 14px;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.36;
    letter-spacing: 0.56px;
    display: inline-block;
    /* width: 14px;
    height: 14px;
    font-family: Nunito;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.2px;
    text-align: left;
    margin-bottom: 5px; */
}

.recommendation .Rectangle-5681-0 {
    max-width: 180px;
    padding: 20px 0px 0px 18px;
    line-height: 20px;
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
}

.recommendation .topic-name {
    width: 172px;
    max-height: 40px;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    font-family: Nunito;
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: var(--true-white);
    text-overflow: ellipsis;
    white-space: break-spaces;
}

.recommendation .Rectangle-5684 {
    width: 65px;
    height: 16px;
    opacity: 1;
    border-radius: 8px;
    background-color: #f8edfd;
    margin: 10px 0px 0px 18px;
}

.recommendation .topics-33 {
    width: 50px;
    height: 14px;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-family: Nunito;
    font-size: 10px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.4px;
    text-align: left;
    color: var(--light-purple);
    padding: 2px 6px;
}

.recommendation .Path-5332 {
    width: 64px;
    height: 61px;
    border-radius: 10px;
    background-color: #36ef85;
    margin: 8px 12px 0px 0px;
}

.recommendation .Avg-Score-80 {
    height: 11px;
    font-family: Nunito;
    font-size: 8px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.32px;
    text-align: center;
    color: var(--charcoal-grey);
}

.recommendation .Rectangle-5681 {
    width: 19.188em;
    height: 13.77em;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 18px
}

.recommendation .Questions-15 {
    max-width: 50%;
    height: 14px;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    font-family: Nunito;
    font-size: 14px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.45px;
    text-align: center;
    color: var(--grey-blue);
}

.recommendation .Addition-Subtracti {
    width: 212px;
    height: 100px;
    font-family: Nunito;
    font-size: 1.375em;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: var(--charcoal-grey);
    text-overflow: ellipsis;
    white-space: break-spaces;
}

.recommendation .Path-5298 {
    width: 109px;
    min-width: min-content;
    padding: 0 10px;
    height: 35px;
    box-shadow: 0 2px 2px 0 rgba(153, 40, 249, 0.2);
    background-color: var(--light-purple);
    border-radius: 3px;
    text-align: center;
}

.recommendation .Rectangle-65 {
    width: 35%;
    height: 35px;
    border-radius: 3px;
    box-shadow: 0 1px 1px 0 rgba(147, 164, 181, 0.33);
    border: solid 1px rgba(207, 215, 219, 0.5);
    background-color: var(--true-white);
    text-align: center;
}

.recommendation .np_play_1939965_000000 {
    width: 7.7px;
    height: 9.7px;
    object-fit: contain;
    margin-right: 4px;
    margin-top: -4px;
}

.VIEW-CHALLENGE {
    width: 40px;
    height: 20px;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.50;
    letter-spacing: 0.36px;
    text-align: center;
    color: var(--grey-blue);
    text-transform: uppercase;
}

.Path-5298 {
    width: 60%;
    box-shadow: 0 2px 2px 0 rgba(153, 40, 249, 0.2);
    background-color: var(--light-purple);
    color: var(--true-white);
    border-radius: 3px;
    text-align: center;
}

.Path-5298 .VIEW-CHALLENGE {
    color: var(--true-white);
}

.recommendation .view-challenge-buttons {
    position: relative;
    bottom: 0px;
}

.row .Path-5298 {
    width: 170px;
    padding: 10px;
    margin: 0 30px 30px 0;
}

.lesson-preview-modal {
    max-width: 80%;
    margin: 50px 10% 50px 10%;
    padding-bottom: 40px;
}

/* .lesson-preview-modal .assign-challenge {
    width: inherit;
    position: absolute;
    float: right;
} */