.Dropzone {
  height: 200px;
  width: 200px;
  /* background-color: #fff; */
  /* border: 2px dashed rgb(187, 186, 186); */
  /* border-radius: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('../../assets/images/Rectangle-profile-pic.png');
  margin: auto;
}

.Dropzone .preview {
  /* position: relative; */
  /* height: 110px; */
  /* width: 110px; */
  /* margin-left: 266px; */
  margin-top: 4px;
  border-radius: 20px;
}

.Highlight {
  background-color: rgb(188, 185, 236);
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.FileInput {
  display: none;
}
