.ProgressBar {
  width: 100%;
  height: 8px;
  background-color: var(--dark-sky-blue);
  border-radius: 5px;
}

.Progress {
  background-color: var(--dark-sky-blue);
  height: 100%;
  margin: 0;
  border-radius: 5px;
}
