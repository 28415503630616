.subject-challenge-playground {
    height: inherit;
    position: relative;
    margin: auto;
  }

  .subject-challenge-wrapper {
    position: relative;
    padding-top: 60px;
    width: 100%;
    height: 100%;
    margin: auto;
  }
  
  .subject-challenge-wrapper h1 {
      position: relative;
      font-weight: bold;
      text-align: center;
      margin: 10px;
      color: white;
      text-transform: uppercase;
  }

  .saga-map {
    width: 70%;
    height: 100%;
    margin: auto;
    margin-top: 77px;
  }