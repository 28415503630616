#topic-container {
  position: relative;
  height: 100vh;
  min-height: 1024px;
  width: 98vw;
  margin: auto;
  overflow: hidden;
  top: -10px;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../../assets/images/practice-background.svg');
}

.topic-playground {
  height: inherit;
  position: relative;
  margin: auto;
  padding-top: 30px;
}

.topic-playground .mascot {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  position: fixed;
  background-image: url('../../../assets/images/practice-mascot.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 20px;
  background-size: auto;
}

.topic-wrapper {
  z-index: 3;
  position: relative;
  margin: auto;
  background-image: url('../../../assets/images/Rectangle-topic.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 147px;
  background-size: auto;
  width: 880px;
  height: 620px;
  padding-top: 147px;
}

.topic-wrapper .topic-chapter {
  position: relative;
  width: 78px;
  height: 16px;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.08;
  letter-spacing: 0.2px;
  text-align: center;
  color: #2f3643;
  text-transform: uppercase;
  margin: auto;
}

.topic-wrapper .topic-name {
  position: relative;
  font-weight: bold;
  text-align: center;
  margin: auto;
  padding-top: 5px;
  color: #2f3643;
  text-transform: capitalize;
  white-space: initial;
  word-wrap: break-word;
  text-align: center;
  z-index: 7;
  width: 250px;
}

.topic-card-wrapper {
  z-index: 5;
  position: absolute;
  margin: auto;
  background-image: url('../../../assets/images/math-topic-tray.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 156px;
  background-size: auto;
  width: 875px;
  height: 454px;
  top: 146px;
}

.topic-card-wrapper .inner-card {
  z-index: 6;
  position: relative;
  margin: auto;
  /* background-image: url(/static/media/Mask-Group-1531.6442c616.png); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 164px 214px;
  background-size: 864px 402px;
  width: 964px;
  height: 427px;
  top: 25px;
}

.topic-scrolling-wrapper {
  width: inherit;
  height: 22em;
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
  scrollbar-color: rgb(51, 40, 40, 0.8) transparent;
  scrollbar-width: thin;
}

.topic-scrolling-wrapper::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

.topic-scrolling-wrapper::-webkit-scrollbar-thumb {
  background: rgb(51, 40, 40, 0.8);
  border-radius: 10px;
}

.topic-card-holder {
  width: 154px;
  height: 81px;
  margin: 20px;
}

.topic-card {
  white-space: initial;
  width: 154px;
  height: 81px;
  background: white;
  border-radius: 10%;
  border: 3px white solid;
  font-size: small;
  word-wrap: break-word;
  text-align: center;
}

.topic-card .locked {
  background-color: #332828 !important;
}

.lessonName {
  position: relative;
  margin: 10px;
  top: 10px;
  color: #332828;
  font-weight: 900;
}

.topic-playground .close-topic {
  position: absolute;
  top: 146px;
  right: 10px;
  width: 48px;
  height: 48px;
  z-index: 7;
}

.play-topic {
  position: relative;
  bottom: 60px;
  width: fit-content;
  z-index: 7;
  margin: auto;
}

.play-topic img {
  width: 90px;
  height: 90px;
}

.topic-card .progress-background {
  width: 134px;
  height: 10px;
  border-radius: 20px;
  background: rgb(240, 240, 240);
  font-family: Nunito;
  font-size: 12px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  z-index: 10;
  top: 8px;
  position: relative;
  margin-left: 5px;
  border: 5px solid white;
}

.topic-card .progress-background .progress-details {
  position: relative;
  top: -37px;
  color: var(--true-white);
}

.topic-card .progress-progress {
  height: 10px;
  border-radius: 40px;
  background-color: #19b3fc;
}

.topic-card .topic-lesson-name {
  height: 72px;
}

.topic-card .progress-background span {
  width: 100%;
  margin: auto;
  height: 24px;
  font-family: Nunito;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: 0.36px;
  text-align: center;
  color: var(--true-white);
}
