.sk-header-right .profile-widget-card {
  position: absolute;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 511px;
  width: 330px;
  top: 85px;
  /* right: 130px; */
  background-image: url('../../../assets/images/profile-widget-card.svg');
  z-index: 100;
}

.profile-widget-card ul {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0px 2px;
  z-index: 5;
}

.profile-widget-card li {
  background-color: transparent;
  background-position: left;
  background-repeat: no-repeat;
  background-size: 24px, 24px;
  margin: 11px 15px;
  height: 28px;
  width: 24px;
  margin: 27px 15px 15px 46px;
}

.profile-widget-card li:nth-child(7) {
  margin: 0px 15px 15px 46px;
}

.profile-link {
  background-image: url('../../../assets/images/my-profile-icon.svg');
}

.notifications {
  background-image: url('../../../assets/images/notification.png');
}

.message {
  background-image: url('../../../assets/images/message-icon.svg');
}

.achievements {
  background-image: url('../../../assets/images/achievement-icon.svg');
}

.settings {
  background-image: url('../../../assets/images/settings-icon.svg');
}

.parent {
  background-image: url('../../../assets/images/parents-icon.svg');
}

.logout {
  background-image: url('../../../assets/images/logout-icon.svg');
}

.profile-widget-card li a {
  margin: 0px;
  font-size: 12pt;
  font-weight: 800;
  font-style: normal;
  line-height: 20pt;
  white-space: nowrap;
  margin: 15px 40px;
}
