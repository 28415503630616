.custom-bar-small {
    width: 20%;
}



@media(min-width:768px) {
    .tutorial {
        right: 30px !important;
    }
}


/* .lesson-container{
    width: 90%;
    height: fit-content;
    background-image: radial-gradient(circle, rgb(25, 179, 252), rgb(32, 121, 236));
}

.close {
    background: transparent !important;
}

  .centered-wrapper {
      width:fit-content;
      height:fit-content;
      margin: auto;
  }

  .lesson-card img {
    max-width: 90%;
    z-index: 5;
    position: relative;
    max-height: 80%;
  }

  .lesson-card h1 {
      font-size: xx-large;
  }

  .button-container .option-button {
    width: 310px;
    height: 83px;
    border-radius: 8px;
    border: solid 1px var(--azure);
    background-color: rgb(31,38,46,0.2);  
}

.button-container .option-button span {   
    font-family: Nunito;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.48px;
    text-align: center;
    color: var(--true-white);
  }

  .question-card {
    height: 72px;
    font-family: Nunito;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.48px;
    text-align: center;
    color: var(--true-white);
  } */