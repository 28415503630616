@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700,700i,800,900&display=swap');
:root {
  --charcoal-grey: #2e3039;
  --silver-50: rgba(207, 215, 219, 0.5);
  --grey-blue: #657d95;
  --silver: #cfd7db;
  --white: #fbfbfb;
  --bluey-grey: #93a4b5;
  --black: #000000;
  --coral: #f44747;
  --cerise: #df0a53;
  --white-two-0: rgba(255, 255, 255, 0);
  --charcoal-grey-two: #2f3643;
  --golden-yellow: #f5c818;
  --squash: #e9981e;
  --black-30: rgba(0, 0, 0, 0.3);
  --azure: #19b3fc;
  --lightish-purple: #be55eb;
  --purpley-pink: #cf39ac;
  --black-two: #0a0a09;
  --greenish-teal: #2ecb71;
  --white-two: #ffffff;
  --light-purple: #bf82f3;
  --cool-green: #40d360;
  --marigold: #ffc501;
  --orange-red: #ff641e;
  --red-pink: #f43d6e;
  --purply-pink: #f05fca;
  --lighter-purple: #a463f7;
  --warm-blue: #4d65db;
  --apple: #73bf31;
  --sand: #dde56d;
  --faded-red: #d83d5d;
  --soft-pink: #f6a2c6;
  --coral-two: #f96456;
  --light-salmon: #ffaf91;
  --powder-pink: #feb0dc;
  --amethyst: #ad69d4;
  --warm-blue-two: #5459dc;
  --liliac: #ae97ff;
  --sunflower-yellow: #ffcc00;
  --robins-egg: #57d1ff;
  --tree-green-10: rgba(30, 154, 34, 0.1);
  --tree-green: #1e9a22;
  --orange-red-10: rgba(255, 100, 30, 0.1);
  --electric-purple: #9e2dff;
  --dark-sky-blue: #2ca2d8;
}

.body {
  font-family: Nunito !important;
}

a:hover {
  text-decoration: none !important;
  color: inherit !important;
}

.cursor-pointer {
  cursor: pointer;
}

.home-logo {
  width: 250px;
  position: absolute;
  top: 16px;
  left: 16px;
}

.student-page {
  background: var(--dark-sky-blue);
}

.question-page {
  background: var(--azure);
}

/* 
h4 {
  font-size: 40px !important;
  line-height: 1.35 !important;
  letter-spacing: 0.8px !important;
  text-align: left !important;
  font-family: Nunito !important;
  font-weight: 700 !important;
} */

h1 {
  display: block;
  font-size: 2em !important;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold !important;
}

.bold-text {
  font-weight: 800;
  line-height: 1.5;
  letter-spacing: 0.48px;
}

.sk-container {
  max-width: 1054px;
  margin: 0 auto;
  padding: 0 15px;
}

.full-container {
  margin: 0 auto;
}

h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

.header-bar {
  padding-top: 25px;
  display: inline-block;
  width: 100%;
  padding-bottom: 30px;
}

.header-left {
  width: 50%;
  float: left;
  color: var(--true-white);
  font-weight: bold;
}

.sk-header-right {
  width: 50%;
  float: left;
}

.main-profile {
  float: right;
  position: relative;
  min-width: 370px;
}

.scrolling-wrapper {
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  scrollbar-width: none;
}

.scrolling-wrapper::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.single-item {
  width: 21em;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center top;
}

.all-skills-item .single-item:last-of-type {
  margin-right: 300px;
}

.all-skills-item .single-item:first-child {
  margin-left: 200px;
}

.main-item.item-1 {
  margin: 0 15px;
}

.skills-item-area .main-item {
  width: 17em;
  height: 17.7em;
  top: 1em;
}

.main-item {
  position: relative;
  z-index: 1;
  margin: 0 auto 30px auto !important;
}

.point {
  position: absolute;
  right: 9%;
  top: 5%;
  color: #000;
}

.skills-list {
  padding: 0 1em;
  list-style: none;
  padding: 3em 1.5em;
  height: 12em;
  overflow: hidden;
}

.skills-list li {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.48px;
  color: var(--true-white);
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}

.skills-list li:nth-child(1) {
  text-transform: uppercase;
  opacity: 0.4;
  font-weight: 800;
  line-height: 1.5;
  letter-spacing: 0.16px;
  color: var(--black);
}

.skill-btn-incomplete {
  background-image: url(./assets/images/progress-bar-background.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
}

.skills-list li {
  list-style: none;
  font-size: 20px;
  letter-spacing: 0.5px;
  display: block;
  margin-bottom: 6px;
}

/* .skills-list li:nth-child(1) {
	text-transform: uppercase;
	font-size: 17px;
	font-weight: 600;
	letter-spacing: 1px;
	color: #000;
} */

.skill-btn {
  position: relative;
  width: 13.75em;
  height: 4.375em;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.5;
  letter-spacing: 0.48px;
  text-align: center;
  color: var(--true-white);
  text-transform: uppercase;
}

.skill-btn-incomplete img {
  position: absolute;
  height: 100%;
  width: 20%;
  top: 0px;
  left: 0px;
  object-fit: fill;
}

.skill-btn-incomplete span {
  position: absolute;
  top: 10px;
  left: 40px;
  z-index: 3;
}

.skill-btn-incomplete p {
  position: relative;
  background-image: url(./assets/images/progress-bar-yellow.svg);
  background-repeat: no-repeat;
  height: 100%;
  background-position: left top;
  padding: 0;
  /* background-size: cover; */
  z-index: 1;
}

.point span {
  opacity: 0.8;
  font-size: 18px;
  font-weight: 800;
  line-height: 1.33;
  letter-spacing: 0.18px;
  color: var(--charcoal-grey-two);
}

.clip.text-center {
  text-align: center;
}

.clip img {
  width: 140px;
  height: 120px;
}

.mascot {
  width: 19.625em;
  position: absolute;
  bottom: -130px;
  /* left: -20px; */
  z-index: 99;
}

.profile {
  width: 290px;
  height: 57px;
  background-position: center;
  background-size: cover;
  background-image: url(./assets/images/Levelup-Base.png);
  position: relative;
  z-index: 1;
  margin-right: 40px;
  margin-top: 11px;
}

.profile p {
  color: #000;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 600;
  padding-left: 20px;
  padding-top: 6px;
}

.profile p span {
  margin-left: 45px;
}

.profile b {
  margin-left: 15px;
  text-transform: uppercase;
}

.profile div {
  width: 290px;
}

.pro-bg {
  position: absolute;
  top: 9px;
  left: 8px;
  position: relative;
  background-image: url(./assets/images/level-bar.png);
  background-repeat: no-repeat;
  height: 100%;
  background-position: left top;
  padding: 0;
  height: 59%;
  z-index: 3;
}

.profile strong {
  font-weight: 700;
  font-size: 18px;
  position: relative;
  top: 0px;
  left: 16px;
}

.pro-ic {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  width: 100px;
}

.pro-ic img {
  position: relative;
  width: 70px;
  height: 70px;
  border: 4px var(--true-white) solid;
  border-radius: 25px;
  background: var(--true-white);
}

.pro-ic .sk-badge {
  right: 18px;
  bottom: 1.5em;
}

.profile-widget-card .sk-badge {
  left: 200px;
  bottom: 30px;
}

.update-badge {
  background-color: #f5c818;
}

.notification-badge {
  background-color: #f9695a;
  animation: shadow-pulse 2.5s infinite;
  border: 2px solid var(--true-white);
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}

.sk-badge {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  z-index: 2;
  color: var(--true-white);
  text-align: center;
  line-height: 26px !important;
  font-weight: 900;
}

.footer-btn button {
  background-image: url(./assets/images/btn-bg.svg);
  background-position: center;
  background-size: cover;
  width: 256px;
  max-height: 66px;
  height: 66px;
  display: inline-block;
  text-align: center;
  margin-left: 10px;
  line-height: 63px;
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 800;
  color: #000;
}

.footer-btn .small {
  background-image: url(./assets/images/btn-bg-small.svg);
  width: 220px !important;
  margin-left: 0px;
}

.footer-btn {
  float: right;
}

.footer-btn {
  float: right;
}

.footer-btn button span img {
  width: 36px !important;
  position: relative;
  margin-right: 10px;
}

footer {
  position: absolute;
  width: 100%;
  bottom: 20px;
  z-index: 10;
  right: 0;
}

.header-left h4 {
  margin-bottom: 0;
}

.header-left span {
  font-size: 17px;
  letter-spacing: 0.7px;
}

.skills-item-area {
  position: absolute;
  width: 100%;
  top: 24%;
}

.berger-menu {
  display: none;
}

.menubar {
  width: 35px;
  height: 34px;
  position: relative;
  cursor: pointer;
  display: block;
  float: right;
}

.menubar span {
  background: var(--true-white);
  width: 100%;
  height: 2px;
  display: block;
  margin-bottom: 8px;
}

.berger-menu {
  float: right;
  display: block;
  position: relative;
  display: none;
}

.menu {
  width: 250px;
  background: #ffff;
  padding: 25px;
  position: absolute;
  right: 0;
  top: 35px;
  border-radius: 5px;
  box-shadow: 0 0px 12px #5792c3;
  z-index: 66;
}

.menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu li a img {
  width: 22px;
  margin-right: 10px;
}

.menu ul li {
  float: none;
  display: block;
  margin: 13px 0;
}

.menu li a {
  color: #000;
  font-weight: 600;
  letter-spacing: 0.6px;
  font-size: 17px;
}

/* .menu{
	display: none;
} */

.brk {
  display: none;
}

.menubar {
  width: 35px;
  height: 34px;
  position: relative;
  cursor: pointer;
  display: block;
  float: right;
}

.menubar span {
  background: var(--true-white);
  width: 100%;
  height: 2px;
  display: block;
  margin-bottom: 8px;
}

.berger-menu {
  float: right;
  display: block;
  position: relative;
  display: none;
}

.menu {
  width: 250px;
  background: #ffff;
  padding: 25px;
  position: absolute;
  right: 0;
  top: 35px;
  border-radius: 5px;
  box-shadow: 0 0px 12px #5792c3;
  z-index: 66;
}

.menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu li a img {
  width: 22px;
  margin-right: 10px;
}

.menu ul li {
  float: none;
  display: block;
  margin: 13px 0;
}

.menu li a {
  color: #000;
  font-weight: 600;
  letter-spacing: 0.6px;
  font-size: 17px;
}

/* .menu{
	display: none;
} */

.brk {
  display: none;
}

.sk-header-right {
  width: 20%;
  float: right;
}

.berger-menu {
  display: none;
  margin-top: 18px;
  float: right;
}

@media (min-width: 800px) and (max-width: 910px) {
  .skill-btn {
    margin-top: 9px;
    /* height: 54px; */
  }
  .skill-btn span {
    font-size: 13px;
  }
  .skill-btn p {
    font-size: 13px;
  }
  .point {
    position: absolute;
    right: 15%;
    /* top: 12%; */
    color: #000;
  }
}

@media (min-width: 768px) and (max-width: 799px) {
  .skill-btn {
    /* margin-top: 15px; */
    /* height: 48px; */
  }
  .skill-btn span {
    font-size: 13px;
  }
  .skill-btn p {
    font-size: 13px;
  }
  .point {
    position: absolute;
    right: 10%;
    /* top: 11%; */
    color: #000;
    font-size: 14px !important;
  }
  .skills-list li {
    font-size: 18px;
    letter-spacing: 0.5px;
    margin-bottom: 5px;
  }
  /* .skills-list li:nth-child(1) {
	text-transform: uppercase;
	font-size: 15px;
} */
  .skills-list {
    padding-top: 3em;
  }
  .skill-btn span {
    /* top: 6px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    font-size: 12px; */
  }
  .mascot {
    /* width: 180px;
    position: absolute;
    bottom: 0;
    left: 0; */
    width: 13em;
    position: absolute;
    bottom: -3em;
    left: -1em;
  }
  .footer-btn span {
    width: 225px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .all-skills-item {
    max-width: 400px;
    margin: 0 auto;
  }
  .single-item {
    width: 100%;
    float: left;
    margin-bottom: 50px;
  }
  .all-skills-item .single-item:first-child {
    margin-left: 10px !important;
  }
  .all-skills-item .single-item:last-of-type {
    margin-right: 10px !important;
  }
  .mascot {
    display: none;
  }
  .footer-btn {
    float: none;
    width: 100%;
    text-align: center;
  }
  .skills-item-area {
    position: relative;
    width: 100%;
    top: 0;
    margin-top: 100px;
    margin-bottom: 140px;
    padding-left: 0px;
    z-index: 9999;
  }
  footer {
    position: relative;
    width: 100%;
    bottom: 20px;
    z-index: 99;
    right: 0;
    padding-bottom: 25px !important;
  }
  .all-skills-item {
    max-width: 350px;
    margin: 0 auto;
  }
  .main-profile {
    position: relative;
    display: none;
  }
  .header-left {
    width: 80%;
    float: left;
  }
  .header-left h4 {
    font-size: 22px;
    letter-spacing: 0.5px;
  }
  .header-left span {
    font-size: 14px;
  }
  .berger-menu {
    display: block;
  }
  .berger-menu {
    display: block;
    margin-top: 18px;
  }
  .footer-btn button {
    margin-bottom: 16px;
  }
  .menu {
    z-index: 9999999 !important;
  }
}

.all-skills-item .science {
  background-image: url(./assets/images/science-card.svg);
}

.all-skills-item .science_review {
  background-image: url(./assets/images/science-card.svg);
}

.all-skills-item .math {
  background-image: url(./assets/images/math-card.svg);
}

.all-skills-item .sol_math {
  background-image: url(./assets/images/math-card.svg);
}

.skills-item-area .main-item {
  background-image: url(./assets/images/mask.png);
  background-color: transparent;
  background-position: center 2px;
  background-repeat: no-repeat;
  background-size: cover;
}

.all-skills-item .vocabulary {
  background-image: url(./assets/images/vocabulary-card.svg);
}

.all-skills-item .word_study {
  background-image: url(./assets/images/english-card.svg);
}

.all-skills-item .social_studies {
  background-image: url(./assets/images/social-studies-card.svg);
}

button {
  border: none !important;
  outline: none !important;
  box-shadow: none;
}

button {
  background-color: transparent;
  background-position: left;
  background-repeat: no-repeat;
  background-size: 36px, 36px;
  margin: 11px 15px;
  height: 36px;
  width: 36px;
  margin: 12px 36px;
}

/* Math page style Start*/

.header-back {
  width: 10%;
  float: left;
}

.header-meddle {
  width: 45%;
  float: left;
  text-align: center;
}

.header-back i {
  float: left;
  background: var(--true-white);
  width: 55px;
  height: 50px;
  border-radius: 10px;
  line-height: 50px;
  font-size: 24px;
  color: #19b3fc;
}

.header-meddle h3 {
  font-size: 40px;
  white-space: nowrap;
  font-weight: 700 !important;
  font-family: Nunito !important;
}

/* .pro-ic:after {
  position: absolute;
  right: 0;
  top: 0;
  background: red;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  z-index: 11;
} */

.body-inner {
  background-image: url(./assets/images/path.png);
  background-position: center;
  background-size: cover;
}

.single-math-item {
  width: 33.33%;
}

.single-math {
  width: 100%;
  background-position: center;
  background-size: cover;
  background-image: url(./assets/images/white-challenge-card.svg);
  height: 365px;
  position: relative;
  z-index: 1;
}

.single-math.lock-item {
  width: 100%;
  height: 100%;
  position: relative;
}

.item-list {
  padding-left: 60px;
  padding-top: 60px;
}

.item-list li {
  list-style: none;
  font-size: 20px;
  display: block;
  margin-bottom: 10px;
  color: var(--true-white);
  font-weight: bold;
  letter-spacing: 1px;
  width: 90%;
  min-height: 80%;
}

.star-item:after {
  background-position: center;
  background-image: url(./assets/images/math-partial-card.svg);
  content: '';
  width: 100%;
  height: 68%;
  position: absolute;
  left: 0;
  top: 0;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -2;
}

.star-item-word_study:after {
  background-image: url(./assets/images/english-partial-card.svg);
}

.star-item-math:after {
  background-image: url(./assets/images/math-partial-card.svg);
}

.star-item-vocabulary:after {
  background-image: url(./assets/images/vocabulary-partial-card.svg);
}

.star-item-social_studies:after {
  background-image: url(./assets/images/social_studies-partial-card.svg);
}

.star-item-science:after {
  background-image: url(./assets/images/science-partial-card.svg);
}

.mathpoint-item-word_study {
  background-image: url(./assets/images/english-challenge-card.svg);
}

.mathpoint-item-math {
  background-image: url(./assets/images/math-challenge-card.svg);
}

.mathpoint-item-vocabulary {
  background-image: url(./assets/images/vocabulary-challenge-card.svg);
}

.mathpoint-item-science {
  background-image: url(./assets/images/science-challenge-card.svg);
}

.mathpoint-item-social_studies {
  background-image: url(./assets/images/social_studies-challenge-card.svg);
}

.lock-item .star-item::before {
  background-position: center;
  background-image: url(./assets/images/black-bg.png);
  content: '';
  width: 84%;
  height: 57%;
  position: absolute;
  left: 8%;
  top: 4%;
  background-size: cover;
  background-repeat: no-repeat;
}

.lock-text {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 13%;
  color: #000;
}

.lock-text p strong {
  font-size: 20px;
  text-transform: uppercase;
}

.lock-text p span {
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: bold;
  color: #2f3643;
}

.main-item.mathpoint-item {
  background-size: 100%;
  height: 390px;
  position: relative;
  top: -4px;
}

.mathpoint-item .item-list {
  padding-left: 60px;
  padding-top: 75px;
  height: 12em;
  overflow: hidden;
}

.mathpoint-item .skill-btn {
  /* margin-top: 25px; */
}

img.mathicon {
  display: inline-block;
  animation: wiggle 2.5s infinite;
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(30deg);
  }
  95% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.mathpoint-item .point {
  font-size: 18px;
  position: absolute;
  right: 17%;
  top: 7%;
  color: #000;
}

img.lock {
  position: absolute;
  left: 50%;
  margin-left: -19px;
  top: 25%;
}

.lock-text p strong {
  font-size: 22px;
  text-transform: uppercase;
  color: #2f3643;
}

.single-math .star-area {
  position: absolute;
  top: 53%;
}

.star-center {
  display: inline-block;
  width: 38px;
  margin: 0 -5px;
}

.star-area {
  text-align: center;
  width: 100%;
}

.star-side {
  width: 44px;
}

/* .star-area img:nth-child(2) {
  position: relative;
  top: -7px;
} */

.text-lock {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 12%;
  color: #2f3643;
  font-weight: 600;
}

.text-lock p span {
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 14px;
  display: block;
  margin-top: 5px;
  color: #2f3643;
}

.text-lock strong {
  font-size: 18px;
  color: #2f3643;
  margin-right: 3px;
  font-weight: 700;
}

.math-item-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
  display: inline-block;
  width: 100%;
}

.math-item-area {
  padding-bottom: 100px;
  padding-top: 50px;
  position: absolute;
  z-index: 1;
  margin: auto;
  width: -webkit-fill-available;
  height: max-content;
  top: 12%;
  display: contents;
}

.single-math-item.just-top {
  position: relative;
  top: -20px;
}

.math-inner {
  position: relative;
  z-index: 1;
}

.math-inner:after {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-position: center;
  background-size: contain;
  background-repeat: repeat-y;
  left: 0;
}

.twince {
  background-image: url(./assets/images/challenge-path-x.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.twince2 {
  background-image: url(./assets/images/challenge-path-x.svg);
  background-position: center 140px;
  background-size: cover;
  background-repeat: no-repeat;
}

.twince1 {
  background-image: url(./assets/images/path-x.png);
  background-position: center 140px;
  background-size: cover;
  background-repeat: no-repeat;
}

img.band-sign {
  position: absolute;
  right: 9%;
  top: 5%;
}

.main-item.mathpoint-item {
  background-size: contain;
  height: 22em;
  /* height: 348px; */
  position: relative;
  top: 10px !important;
  background-position: center;
  background-repeat: no-repeat;
  /* width: 100% !important; */
}

.header-meddle h3 {
  font-size: 40px;
  color: var(--true-white);
  text-align: center;
  text-transform: uppercase;
}

.profile d {
  color: var(--true-white);
  font-size: 14px;
  position: relative;
  left: 0px;
  opacity: 0.7;
}

.single-math-item img.mathicon {
  position: absolute;
  top: -1em;
  left: 0.5em;
  width: 3em;
}

.profile b {
  margin-left: 18px;
  text-transform: uppercase;
  font-size: 14px;
}

.header-back img {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
}

.star-area img:nth-child(2) {
  width: 55px;
}

.text-lock p {
  font-size: 14px !important;
}

.main-item.mathpoint-item b {
  font-weight: 600;
  font-size: 14px;
}

.left {
  float: left;
}

.right {
  float: right;
}

/* Math page style Start*/

/* Addition page style Start*/

body.bhoose-cptr-bdy {
  background-image: url(./assets/images/bg-5.png) !important;
  background-position: center;
  background-size: cover;
  background-color: #ddd;
  position: relative;
}

.choose-page #root:before {
  content: '';
  background: url(./assets/images/Cloud.png);
  background-repeat: no-repeat;
  opacity: 0.9;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.science .addition-wrapper {
  background-image: url(./assets/images/science-practice-lessons-card.png);
}

.science_review .addition-wrapper {
  background-image: url(./assets/images/science-practice-lessons-card.png);
}

.vocabulary .addition-wrapper {
  background-image: url(./assets/images/vocabulary-practice-lessons-card.png);
}

.word_study .addition-wrapper {
  background-image: url(./assets/images/english-practice-lessons-card.png);
}

.social_studies .addition-wrapper {
  background-image: url(./assets/images/social_studies-practice-lessons-card.png);
}

.addition-wrapper {
  width: 48em;
  margin: 0 auto;
  background-image: url(./assets/images/math-practice-lessons-card.png);
  background-position: center 3.4em;
  background-size: contain;
  height: 33em;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 1;
  transform: translate(-50%, -50%);
  animation-duration: 1s;
  /* the duration of the animation */
  animation-timing-function: ease-out;
  /* how the animation will behave */
  animation-delay: 0s;
  /* how long to delay the animation from starting */
  animation-iteration-count: 1;
  /* how many times the animation will play */
  animation-name: slideTopicInFromTop;
  /* the name of the animation we defined above */
}

@keyframes slideTopicInFromTop {
  0% {
    top: 0%;
  }
  100% {
    top: 50%;
  }
}

/* .addition-wrapper:after {
  background-position: center;
  background-size: contain;
  background-image: url(./assets/images/big-bg.svg);
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
	background-repeat: no-repeat;
	z-index: -1;;
} */

.main-chapter {
  width: 100%;
}

.single-addition {
  width: 33%;
  float: left;
  margin-bottom: 30px;
}

.item-addition {
  background-image: url(./assets/images/lesson-background.svg);
  width: 15.625em;
  height: 8.688em;
  background-position: center;
  background-size: 15.625em;
  background-repeat: no-repeat;
  display: inline-block;
  text-align: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
  padding: 10px;
}

.addition-txt {
  width: 70%;
  height: 100%;
  padding-top: 15px;
  margin: auto;
  overflow: hidden;
  word-wrap: break-word;
  white-space: initial;
}

.item-addition .sk-badge {
  width: 2em;
  height: 2em;
}

.addition-txt p {
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #2f3643;
  text-align: center;
}

.addition-wrapper {
  padding: 205px 20px 20px 20px;
}

img.sk-badge {
  position: absolute;
  bottom: 0px;
  left: 50%;
  margin-left: -15px;
  border-radius: 100%;
  border: 2px solid var(--true-white);
  z-index: 999;
}

img.top-cart {
  position: absolute;
  top: -27px;
  left: 50%;
  width: 89px;
  height: auto;
  margin-left: -44px;
}

.addition-title h4 {
  font-size: 24px;
  color: #2f3643;
  letter-spacing: 0.5px;
  text-align: center;
}

.addition-title span {
  display: block;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.6px;
  color: #2f3643;
}

.addition-title p {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.48px;
  color: var(--charcoal-grey-two);
}

.addition-title {
  display: inline-block;
  position: absolute;
  top: 12%;
  left: 35%;
  z-index: 22;
  width: 353px;
  text-align: center;
  margin-left: -57px;
}

.progress-background {
  width: 134px;
  height: 10px;
  border-radius: 20px;
  background: #f0f0f0;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  z-index: 10;
  top: -25px;
  position: relative;
  margin-left: -72px;
  border: 5px solid var(--true-white);
  left: 50%;
}

.progress-progress {
  height: 10px;
  border-radius: 40px;
  background-color: #19b3fc;
}

.ply-btn {
  width: 80px;
  position: absolute;
  bottom: 3%;
  left: 50%;
  margin-left: -45px;
}

.path-sngl {
  width: 33.33%;
  float: left;
}

.path-sngl img {
  width: 130px;
}

.path-sngl {
  width: 33.33%;
  float: left;
  position: relative;
  z-index: 1;
}

.path-sngl-1::after {
  position: absolute;
  right: -33px;
  width: 260px;
  height: 47px;
  background-image: url(./assets/images/line-path1.png);
  background-size: cover;
  background-color: transparent;
  content: '';
  background-position: center;
  background-repeat: no-repeat;
  bottom: 75px;
}

.path-sngl-2::after {
  position: absolute;
  right: 42px;
  width: 200px;
  height: 117px;
  background-image: url(./assets/images/line-path-2.png);
  background-size: cover;
  background-color: transparent;
  content: '';
  background-position: center;
  background-repeat: no-repeat;
  bottom: -32px;
  z-index: -1;
}

.path-sngl-3:after {
  display: none;
}

.main-chapter {
  width: 100%;
  overflow: hidden;
}

.path-sngl.path-sngl-1 {
  top: 60px;
}

.path-area {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 3%;
}

.single-pth-itm {
  width: 33.33%;
  float: left;
  text-align: center;
  position: relative;
}

.single-pth-itm img {
  width: 110px;
  text-align: center;
}

.path-area-bottom {
  position: absolute;
  width: 100%;
  bottom: 0px;
  left: 0;
}

body.bhoose-cptr-bdy {
  overflow: hidden;
}

.single-pth-itm.pth-itm-2 {
  position: relative;
  top: 20px;
}

.ine-path img {
  width: 260px;
}

.ine-path {
  position: absolute;
  top: 8px;
  right: -130px;
}

.line-path {
  position: relative;
  right: -150px;
  top: 0;
}

/* Addition page style End*/

/* CHOOSE CHAPTER page style Start*/

.header-bar.math-header.Choose-chapter {
  margin-top: 25px;
}

.choose-item {
  width: 350px;
  display: inline-block;
}

.choose-chapter-item-warp {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.choose-chapter-item-warp {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.choose-chapter-area .scrolling-wrapper:first-child {
  padding-left: 150px;
}

.choose-chapter-area .choose-item:last-of-type {
  margin-right: 450px;
}

.choose-item-inner {
  background-image: url(./assets/images/practice-card-background.svg);
  height: 240px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  position: relative;
  z-index: 1;
}

.addition-txte {
  position: absolute;
  top: 30%;
  margin-top: -15px;
  text-align: center;
  width: 75%;
  overflow: hidden;
  left: 12%;
  word-wrap: break-word;
  white-space: initial;
}

.addition-txte span {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.8px;
  color: var(--true-white);
}

.chapure-title {
  position: absolute;
  text-align: center;
  display: inline-block;
  left: 50%;
  top: 15px;
  width: 100px;
  margin-left: -55px;
}

.chapure-title span {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 0.7px;
}

.choose-main-txt {
  position: absolute;
  bottom: 20%;
  width: 100%;
  text-align: center;
}

.choose-main-txt p {
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.28px;
  text-align: center;
  color: #2f3643;
}

.choose-main-txt p span {
  color: #000;
  margin-right: 2px;
  font-size: 20px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 0.9px;
  text-align: center;
  color: #2f3643;
}

.choose-main-txt .mastered {
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.08;
  letter-spacing: 0.24px;
  text-align: center;
  color: #2f3643;
  opacity: 0.6;
  text-transform: uppercase;
}

.Choose-chapter .header-meddle {
  width: 80%;
  text-align: center;
}

body.choose-page {
  background-image: url(./assets/images/practice-background.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.single-path {
  background-image: url(./assets/images/practice-path-2.png);
  width: 100%;
  float: left;
  text-align: center;
  margin-top: -20px;
  background-size: contain;
  background-position: inherit;
  background-repeat: no-repeat;
}

.single-path-2 {
  background-image: url(./assets/images/practice-path-1.png);
  position: relative;
  top: -35px;
  background-position: 50%;
}

.single-path img {
  width: 115px;
}

.choose-path-area {
  display: inline-block;
  width: 100%;
  margin-top: -20px;
}

.choose-item.choose-item-2 {
  margin-top: -35px;
}

.single-path.single-path-2 {
  margin-top: 40px;
}

.mathtext h4 {
  font-size: 72px;
  letter-spacing: 1px;
  font-weight: 800;
  color: var(--true-white);
  text-align: center;
  text-transform: uppercase;
}

.mathtext {
  margin-top: -25px;
  position: absolute;
  text-align: center;
  bottom: 0;
  width: 100%;
  left: 0;
}

.mathtext span {
  font-size: 40px;
  color: var(--true-white);
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.choose-chapter-area {
  display: inline-block;
  width: 100%;
  margin-top: 60px;
  position: absolute;
  left: 0;
  top: 20%;
}

.cloud-1 {
  position: absolute;
  left: -20%;
  top: 12%;
  width: auto;
  height: 100%;
  z-index: 4;
  opacity: 0.9;
}

.cloud-2 {
  position: absolute;
  right: -10%;
  top: 0;
  width: auto;
  height: 100%;
}

.choose-page {
  overflow: hidden;
}

/* .start-addition .item-addition::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(./assets/images/locked-lesson-card.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  content: '';
  z-index: -1;
}

.start-addition .addition-txt p {
  color: var(--true-white) !important;
} */

.lock-addition .item-addition::after {
  position: absolute;
  left: 0;
  top: 5px;
  width: 100%;
  height: 100%;
  background-image: url(./assets/images/locked-lesson-card.svg);
  background-position: center 2px;
  background-size: 13.75em;
  background-repeat: no-repeat;
  content: '';
  z-index: 1;
}

.lock-addition .item-addition::before {
  position: absolute;
  left: 0;
  top: 30%;
  width: 100%;
  height: 100%;
  text-align: center;
  content: 'Locked';
  z-index: 9999;
  color: var(--true-white);
  font-weight: bold;
  letter-spacing: 0.8px;
  font-size: 14px;
  text-transform: uppercase;
}

.clg-btn {
  position: absolute;
  right: 1%;
  top: 11%;
  cursor: pointer;
}

.choose-item-inner::after {
  position: absolute;
  left: 45%;
  top: -3px;
  background-image: url(./assets/images/math-practice-card.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  content: '';
  width: 75%;
  height: 70%;
  margin-left: -32.9%;
  z-index: -1;
}

.social_studies .choose-item-inner::after {
  background-image: url(./assets/images/social-studies-practice-card.svg) !important;
}

.vocabulary .choose-item-inner::after {
  background-image: url(./assets/images/vocabulary-practice-card.svg) !important;
}

.word_study .choose-item-inner::after {
  background-image: url(./assets/images/english-practice-card.svg) !important;
}

.science .choose-item-inner::after {
  background-image: url(./assets/images/science-practice-card.svg) !important;
}

.science_review .choose-item-inner::after {
  background-image: url(./assets/images/science-practice-card.svg) !important;
}

/* CHOOSE CHAPTER page style End*/

.bhoose-cptr-bdy .main-choose-chapter {
  height: 100vh;
  width: 100%;
  position: relative;
}

.addition-wrapper {
  padding: 140px 20px 20px 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.show-problem {
  z-index: 100;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.show-tutorial {}

/* Questions page style Start*/

.keyboard-page {
  background: #f5c818;
}

html, body, .main-question-area {
  width: 100%;
  height: 100%;
}

.custom-bar {
  width: 59%;
  float: left;
}

.cross-bar {
  text-align: right;
  margin-top: 55px;
}

.cross-bar img {
  z-index: 1000;
}

.timing-bar {
  text-align: center;
  padding-top: 15px;
}

.timing-bar h1 {
  color: var(--charcoal-grey-two);
}

.start-time {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.start-time button {
  width: 120px;
  background: var(--true-white);
  height: 56px;
  border-radius: 14px;
  font-size: 24px;
  color: #2f3643;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 500;
  margin-top: 55px;
  cursor: pointer;
}

.music-bar {
  margin-top: 55px;
}

.start-time img {
  position: absolute;
  width: 60px;
  left: 50%;
  top: 0px;
  margin-left: -30px;
}

.question-timing-bar {
  display: inline-block;
  width: 100%;
  padding-bottom: 25px;
}

.questions-tab-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.questions-tab-list li {
  display: inline;
  position: relative;
  z-index: 1;
}

.questions-tab-list ul li span {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: var(--true-white);
  line-height: 32px;
  font-size: 17px;
  color: #000;
  border-radius: 50px;
  margin: 0 12px;
  margin-top: 18px;
}

.questions-tab-list ul li:after {
  position: absolute;
  right: -28px;
  top: 7px;
  background: var(--true-white);
  content: '';
  width: 45px;
  height: 8px;
  transition: 0.3s;
  z-index: -1;
}

.questions-tab-list .att:after {
  background: #0e85ba !important;
}

.questions-tab-list ul li:last-child:after {
  display: none;
}

.att .active {
  width: 57px !important;
  height: 57px !important;
  top: 0;
  position: relative;
  line-height: 57px !important;
  font-size: 24px !important;
  background: #f5c818 !important;
  border: 3px solid var(--true-white);
}

.att span {
  margin-top: 0px !important;
}

.questions-tab-list .att:before {
  background-position: center;
  background-image: url(./assets/images/small-mascot.svg);
  content: '';
  width: 3em;
  height: 3em;
  position: absolute;
  left: 20px;
  top: -63px;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 5;
}

.questions-tab-list {
  text-align: center;
  position: relative;
  z-index: 1;
  top: -35px;
}

.question-tabs-warp {
  padding-bottom: 50px;
  position: relative;
  height: inherit;
}

.question-form {
  max-width: 550px;
  margin: 0 auto;
  text-align: center;
  margin-top: 15px;
  transition: 0.3s;
}

.incorrect-1 .question-form span {
  font-weight: bold;
  color: #2f3643;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
  display: inline-block;
}

.question-form p {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: center;
  color: var(--true-white);
  margin-bottom: 20px;
}

.question-form input {
  width: 12em;
  height: 2.813em;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  border: none;
  background: var(--dark-sky-blue);
  font-size: 24px;
  color: #fff !important;
  outline: none;
}

.question-submit {
  margin: auto;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--true-white);
  opacity: 0.4;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--true-white);
  opacity: 0.4;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--true-white);
  opacity: 0.4;
}

.keyboard ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.keyboard ul li {
  display: inline;
}

.keyboard {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}

.keyboard.numeric {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.keyboard ul li a {
  width: 3em;
  display: inline-block;
  height: 3em;
  border-radius: 100%;
  background: #dbb215;
  color: var(--true-white);
  font-weight: normal;
  margin: 15px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3em;
  letter-spacing: 0.48px;
  text-align: center;
  color: var(--true-white);
}

.keybord-area {
  position: absolute;
  padding: 0px 0;
  background: #f5c818 !important;
  width: 100vw;
  height: 60%;
  left: 0px;
  bottom: -15%;
}

.keyboard-page {
  position: fixed;
  transform: translate(0%, -0%);
  animation-duration: 1s;
  /* the duration of the animation */
  animation-timing-function: ease-out;
  /* how the animation will behave */
  animation-delay: 0s;
  /* how long to delay the animation from starting */
  animation-iteration-count: 1;
  /* how many times the animation will play */
  animation-name: slidePlatformInFromBottom;
  /* the name of the animation we defined above */
}

@keyframes slidePlatformInFromBottom {
  0% {
    bottom: -50%;
  }
  100% {
    bottom: -15%;
  }
}

a.arrow {
  background: #19b3fc !important;
}

a.arrow img {
  height: 40px;
}

a.cross {
  background: #f44747 !important;
}

a.enter {
  background: #2ecb71 !important;
}

.more-cards {
  position: absolute;
  height: 90px;
  z-index: 99;
  /* opacity: 0.5; */
  cursor: pointer;
}

.more-left {
  left: 0px;
  top: 25%;
}

.more-right {
  right: 0px;
  top: 25%;
}

.more-top {
  left: 7%;
  top: 19%;
}

.more-bottom {
  left: 7%;
  bottom: 17%;
}

/* CQuestions page style End*/

/* The container */

.sk-container-ck {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sk-container-ck .click {
  padding-top: 25px;
}

/* Hide the browser's default checkbox */

.sk-container-ck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 50%;
  left: 11%;
  height: 30px;
  width: 30px;
  background-color: transparent;
  margin-top: -15px;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */

.sk-container-ck:hover:after {
  content: '';
  display: block;
  position: absolute;
  top: -3px;
  bottom: -3px;
  left: -3px;
  right: -3px;
  border-radius: 10px;
  border: 3px solid #fff;
}

.sk-container-ck:hover {
  outline: 4px solid var(--true-white);
}

/* When the checkbox is checked, add a blue background */

.sk-container-ck input:checked~.checkmark {
  /* background-color: var(--true-white); */
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.sk-container-ck input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.sk-container-ck .checkmark:after {
  /* left: 11px;
  top: 7px;
  width: 25px;
  height: 25px;
  border: solid #2ecb71;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); */
  width: 25px;
  height: 25px;
  border: none;
  background-image: url(./assets/images/checked.svg);
  background-position: center;
  background-size: contain;
  transform: rotate(0);
  background-repeat: no-repeat;
  top: 8px;
  left: 9px;
}

.question-bar {
  background: #f5c818;
}

.question-form.question-checkbox {
  max-width: 650px;
}

.single-question-click {
  width: 300px;
  height: 75px;
  background: #2988b5;
  border-radius: 8px;
  margin: 10px;
  font-size: 24px;
  letter-spacing: 0.5px;
  display: inline-block;
}

.single-question-click-large {
  /* width: -webkit-fill-available !important; */
  width: 27em !important;
  height: auto !important;
  max-height: 250px;
}

.single-question-click-large p {
  padding-left: 40px;
  padding-right: 40px;
  overflow-wrap: break-word;
  line-height: 2em;
  padding-bottom: 9px;
}

label.sk-container-ck {
  line-height: 75px;
  display: inline-block;
  width: 99%;
  height: 93%;
}

.single-question-click-large label.sk-container-ck {
  margin-bottom: 0px;
}

.correct-answer.activee {
  background: #2ecb71 !important;
}

.incorrect-1.activee {
  background: #937396 !important;
}

.incorrect-2.activee {
  background: #937396 !important;
}

.incorrect-3.activee {
  background: #937396 !important;
}

.incorrect .inactive {
  color: #ff1414 !important;
}

.correct-question {
  display: inline-block;
  position: absolute;
  z-index: 1;
  transition: 0.3s;
  left: -400px;
  top: 50%;
  left: 0;
  display: block;
}

.wrong-question {
  display: inline-block;
  position: absolute;
  z-index: 1;
  transition: 0.3s;
  right: -400px;
  top: 50%;
  right: 0;
}

.wrong-questions img {
  width: 150px;
}

img.opps {
  position: fixed;
  height: 400px;
  top: 200px;
  right: -54px;
  overflow: hidden;
}

.slide-right {
  animation: 1s slide-right;
}

@keyframes slide-right {
  from {
    margin-right: -20%;
  }
  to {
    margin-right: 0%;
  }
}

img.yoohoo {
  position: fixed;
  height: 400px;
  top: 200px;
  left: -110px;
  overflow: hidden;
}

.slide-left {
  animation: 1s slide-left;
}

@keyframes slide-left {
  from {
    margin-left: -20%;
  }
  to {
    margin-left: 0%;
  }
}

.question-form span {
  font-weight: bold;
  margin-bottom: 15px;
  display: inline-block;
  color: var(--charcoal-grey-two);
}

span.checkmark.wrong:after {
  background-image: url(./assets/images/unchecked.svg);
}

.single-hints {
  width: 33.33%;
  float: left;
}

.single-hints.Submit {
  text-align: center;
}

.single-hints.tutorial {
  text-align: right;
}

.hints-button-area {
  /* background: #19b3fc; */
  display: inline-block;
  width: 100%;
  padding: 40px 0;
}

.single-hints a {
  display: inline-block;
  width: 180px;
  height: 65px;
  border-radius: 14px;
  text-align: center;
  line-height: 65px;
  font-size: 20px;
  color: var(--true-white);
  letter-spacing: 0.5px;
  font-weight: 600;
  text-transform: uppercase;
  background-image: url(./assets/images/btn-bg-2.png);
  background-position: center;
  background-size: contain;
}

.single-hints a span {
  color: #f5c818;
  margin-left: 10px;
}

.single-hints.Submit a {
  background-image: url(./assets/images/submit.png);
  color: #000;
  background-position: center;
  background-size: cover;
}

body.quize-page {
  background: var(--dark-sky-blue) !important;
}

/* .main-question-area.quize-option {
  background: #19b3fc;
} */

.attention-button {
  position: relative;
  height: 30px;
  cursor: pointer;
}

.tutorial-button {
  position: relative;
  height: 66px;
  cursor: pointer;
}

.error-text {
  font-size: 10px !important;
  font-weight: normal !important;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.2 !important;
  letter-spacing: 0.2px !important;
  text-align: left;
  color: #f43d6e !important;
}

/*---------------- Welcome Relly Page  --------------*/

.adult-page {
  background: #f3f3f3;
}

.report-page {
  background: var(--true-white);
}

.welcome-page-title {
  padding: 25px 0;
  display: inline-block;
  width: 100%;
}

.welcome-page-left {
  display: inline-block;
  float: left;
}

.welcome-page-right {
  display: inline-block;
  float: right;
}

.message-box {
  display: block;
  float: left;
  margin-right: 50px;
  padding-top: 15px;
}

.clap-badge {
  animation: shadow-pulse-small 0.5s;
}

@keyframes shadow-pulse-small {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }
}

.message-box img {
  width: 3.75em;
  border-radius: 100%;
}

.techers-profiles {
  display: inline-block;
  float: right;
}

.techers-profiles img {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: #ddd;
}

.welcome-page-left h2 {
  font-size: 36px;
  font-weight: normal;
}

.welcome-page-left h2 span {
  font-weight: bold;
}

.clng-title span {
  color: #2e3039;
  font-size: 18px;
  letter-spacing: 0.5px;
  font-weight: bold;
}

.clng-title {
  margin-bottom: 25px;
}

.single-clng-item {
  /* width: 30.33%; */
  /* float: left; */
  /* margin-right: 30px; */
  background: var(--true-white);
  border-radius: 18px;
  box-shadow: 0 6px 12px 0 rgba(65, 61, 61, 0.16);
  overflow: hidden;
  /* margin: 0 7px; */
  /* width: 100%; */
  width: 96%;
  /* margin: 0 auto; */
  float: none;
}

.challenge-date {
  width: 75%;
  float: left;
}

.challenge-score {
  width: 25%;
  float: left;
}

.date-text {
  padding: 20px 0px 0px 20px;
}

.clng-top-txt {
  display: inline-block;
  width: 100%;
  background: rgb(191, 130, 243, 0.1);
  /* border-radius: 12px 12px 0px 0px; */
}

/* .clng-top-txt {
   display: inline-block;
   width: 100%;
   background: #f3f3f3;
   border-radius: 10px 10px 0px 0px;
 } */

.challenge-score h4 {
  /* font-size: 32px; */
  color: var(--true-white);
  font-size: 32px;
  font-weight: 800;
  line-height: 1.34;
  letter-spacing: 0.64px;
  margin-bottom: 0;
  text-align: center;
}

.challenge-score h4 sub {
  margin: 0;
  position: relative;
  left: -8px;
  top: -8px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  color: white;
}

.date-text span {
  font-size: 14px;
  font-weight: 800;
  line-height: 1.36;
  letter-spacing: 0.56px;
  text-align: left;
  color: var(--bluey-grey);
}

.recent-challenges p {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: 0.36px;
  text-align: left;
  color: var(--grey-blue);
}

.challenge-score {
  width: 25%;
  float: left;
  height: 100%;
  background: var(--light-purple);
  padding: 20px 0;
  text-align: center;
  /* border-radius: 0 13px 0px 0; */
}

.challenge-score p {
  color: white;
  opacity: 0.8;
  margin-top: -5px;
  text-align: center;
  opacity: 0.6;
  font-size: 14px;
  font-weight: 800;
  line-height: 1.36;
  letter-spacing: 0.56px;
  text-transform: uppercase;
}

.recent-plants-txt {
  padding-bottom: 15px;
  border-bottom: 1px dashed #ddd;
  margin: 20px;
  height: 80px;
}

.recent-plants-txt ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.recent-plants-txt ul li {
  font-size: 1.375em;
  font-weight: 600;
  line-height: 1.45;
  text-align: left;
  color: var(--charcoal-grey);
  white-space: pre-wrap;
}

.participant-item {
  width: 33.33%;
  text-align: center;
  float: left;
}

.participant-item span {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 800;
  line-height: 2.36;
  letter-spacing: 0.56px;
  text-align: center;
  color: var(--grey-blue);
}

.participant-item .CircularProgressbar {
  width: 60px;
}

.Participant {
  /* padding-bottom: 10px; */
  display: inline-block;
  width: 100%;
}

.view-txt {
  height: 60px;
  text-align: center;
  background: #fbfbfb;
  display: block;
  font-size: 14px;
  color: var(--light-purple);
  text-transform: uppercase;
  /* border-radius: 0 0 12px 12px; */
  font-size: 16px;
  font-weight: 800;
  line-height: 4.25;
  letter-spacing: 0.72px;
}

.challenge-wrap {
  /* display: inline-block; */
  width: 100%;
  /* margin-bottom: 50px; */
}

.Participant h4 {
  /* margin-bottom: 25px; */
  text-align: center;
  /* font-size: 15px;
  font-weight: 600;
  display: block;
  color: #657d95;*/
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.56px;
  text-align: center;
  color: var(--grey-blue);
  text-transform: uppercase;
}

.Participant h4 span {
  color: #000;
  float: right;
  padding-right: 40px;
}

.single-clng-item {
  margin: 0 7px;
  width: 100%;
}

.challange-warp .single-clng-wrpp:first-of-type {
  margin-left: 9em;
}

.challange-warp .single-clng-wrpp:last-of-type {
  margin-right: 9em;
}

.challange-warp .more-cards {
  top: 35% !important;
}

.single-clng-wrpp {
  width: 20em;
  /* float: left; */
}

.single-clng-item {
  width: 96%;
  margin: 0 auto;
  float: none;
}

.recent-challenges .more-cards {
  top: 33% !important;
}

.single-challenge-wrap {
  width: 30.5%;
  max-width: 21em;
  min-width: 17em;
  /* float: left; */
  display: inline-block;
  margin-bottom: 30px;
  margin-right: 15px;
}

.single-tutorial {
  width: 28em !important;
  max-width: 28em !important;
  min-width: 17em;
}

.challenge-wrap .single-challenge-wrap:last-of-type {
  margin-right: 300px;
}

.challenge-wrap .single-challenge-wrap:first-child {
  margin-left: 200px;
}

.history-box-title h4 {
  font-size: 24px;
  color: #2e3039;
  display: inline-block;
}

.info-wrap {
  display: inline-block;
  float: right;
}

.history-analytics canvas {
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
  padding: 1em;
}

.history-box {
  background: var(--true-white);
  border-radius: 15px;
  border-radius: 16px;
  box-shadow: 0 6px 8px 0 rgba(101, 125, 149, 0.1);
  margin-bottom: 30px;
}

.history-box-title {
  padding: 40px 30px;
}

.tag-box button {
  border: none !important;
  outline: none !important;
  box-shadow: none;
  font-family: Nunito;
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: bold;
  color: #657d95;
  border-radius: 25px;
  background: #f3f3f3;
  width: auto;
  padding: 8px 25px;
  margin: 0 10px;
}

.current {
  background: var(--light-purple) !important;
  color: var(--true-white) !important;
}

.tag-box-duration {
  display: inline-block;
}

.tag-box {
  margin-top: 25px;
}

.tag-box button:first-child {
  margin-left: 0;
}

.history-analytics img {
  width: 100%;
}

.history-chart-text {
  text-align: center;
  padding: 30px;
}

.history-chart-text h4 {
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  color: #2e3039;
}

.history-chart-text p {
  color: #ccc;
  font-size: 14px;
  margin: 0;
  font-weight: 600;
}

.Chapters-list span {
  /* background: #ddd; */
  /* border-radius: 100%; */
  width: 48px;
  height: 48px;
  margin: 0;
  margin-right: 46px;
  position: relative;
  z-index: 1;
}

.Chapters-list {
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 35px;
  margin-right: 1em;
  position: relative;
  z-index: 1;
}

.Chapters-list span:last-child {
  margin-right: 0px;
}

.Chapters-list::after {
  position: absolute;
  left: 0;
  width: 94%;
  height: 2px;
  border-bottom: 2px dashed #f3f3f3;
  content: '';
  top: 50%;
}

.bgc-1 {
  background: #ffc501 !important;
}

.bgc-2 {
  background: #ff641e !important;
}

.bgc-3 {
  background: #f43d6e !important;
}

.bgc-4 {
  background: #f05fca !important;
}

.bgc-5 {
  background: #a463f7 !important;
}

.bgc-6 {
  background: #4d65db !important;
}

.chapter-list-warp {
  text-align: right;
}

.left-degit {
  width: 25%;
  float: left;
  padding-top: 10px;
}

.number-progress {
  width: 34.375em;
  float: right;
}

.single-progress {
  display: inline-block;
  text-align: center;
}

.single-progress:first-of-type {
  margin-left: 0.738em;
}

.single-progress:not(:last-of-type) {
  margin-right: 2em;
}

.left-degit h4 {
  font-size: 20px;
  margin: 0;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: left;
  color: var(--charcoal-grey);
}

.left-degit p {
  color: #93a4b5;
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 14px;
  padding-top: 8px;
}

.single-degit-number {
  padding: 25px 25px 25px 50px;
  background: var(--true-white);
  border-top: 1px solid #f3f3f3;
  display: flow-root;
}

.minimal .single-degit-number {
  border-top: none;
  padding: 0px 0px 25px 50px;
}

.single-degit-number.bg-gray {
  background: #f3f3f3;
}

.digit-number {
  padding-bottom: 25px;
  margin-bottom: 30px;
}

.summary-title h4 {
  font-size: 24px;
  color: #2e3039;
}

.select-css {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #444;
  line-height: 1.7;
  padding: 8px;
  width: fit-content;
  max-width: 500px;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #cfd7db;
  border-radius: 5px;
  -moz-appearance: none;
  -webkit-appearance: inherit;
  appearance: none;
  background-color: var(--true-white);
  background-size: 0.65em auto, 100%;
  color: #657d95;
  letter-spacing: 0.7px;
}

/* .select-css::-ms-expand {
   display: none;
 } */

.select-css:hover {
  border-color: #cfd7db;
}

.select-css:focus {
  outline: none;
}

.select-css option {
  font-weight: normal;
}

.summary-select {
  margin-top: 25px;
  margin-bottom: 50px;
}

.summary-box {
  background: var(--true-white);
  border-radius: 16px;
  box-shadow: 0 6px 8px 0 rgba(101, 125, 149, 0.1);
  margin-bottom: 30px;
  display: flow-root;
}

.smr-warp {
  padding: 50px;
  padding-bottom: 0;
}

.single-perform {
  width: 25%;
  float: left;
  text-align: center;
}

.single-perform .CircularProgressbar {
  width: 160px;
  padding: 8px 8px 30px 8px;
}

.single-progress .CircularProgressbar {
  width: 60px;
  /* padding: 8px; */
}

/* Style the buttons that are used to open the tab content */

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

/* Change background color of buttons on hover */

.tab button:hover {
  background-color: #eff2f4;
}

/* Create an active/current tablink class */

/* .tab button.active {
  background-color: #ccc;
} */

/* Style the tab content */

.tabcontent {
  /* display: none; */
  padding: 6px 12px;
  /* border: 1px solid #ccc;
  border-top: none; */
  border: none;
}

.performance-tab-list {
  display: inline-block;
  width: 100%;
}

.performance-tab-list div button {
  width: 25%;
  background: var(--true-white);
  border-radius: 15px 15px 0 0;
  margin: 0;
  display: inline-block;
  height: auto;
}

.performance-tab-list button h4 {
  font-size: 18px;
  color: #2e3039;
  text-align: center;
  margin-bottom: 5px;
}

/* .performance-tab-list button.active {
  background-color: #eff2f4;
} */

.performance-tab-list .tablinks.incomplete h4 {
  color: #657d95;
}

.performance-tab-list .tablinks.incomplete {
  background: #eff2f4 !important;
}

.performance-tab-list .tablinks.low h4 {
  color: #ffcc00;
}

.performance-tab-list .tablinks.low {
  background: rgb(255, 204, 0, 0.1) !important;
}

.performance-tab-list .tablinks.medium h4 {
  color: #57d1ff;
}

.performance-tab-list .tablinks.medium {
  background: rgb(87, 209, 255, 0.1) !important;
}

.performance-tab-list .tablinks.high h4 {
  color: #40d360;
}

.performance-tab-list .tablinks.high {
  background: rgb(64, 211, 96, 0.1) !important;
}

.performance-tab-list button p {
  color: #657d95;
  letter-spacing: 0.6px;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

.single-performer {
  display: inline-block;
  float: left;
  margin-right: 35px;
}

.left-info-perfo {
  display: inline-block;
  width: 85%;
  height: auto;
  overflow-x: scroll;
  display: inline-flex;
  /* padding-bottom: 20px; */
}

.summary-tabs {
  padding: 0 50px;
  background: #eff2f4;
  padding-top: 25px;
  padding-bottom: 30px;
  min-height: 114px;
}

.summary-tabs.low {
  background: rgb(255, 204, 0, 0.1) !important;
}

.summary-tabs.medium {
  background: rgb(87, 209, 255, 0.1) !important;
}

.summary-tabs.high {
  background: rgb(64, 211, 96, 0.1) !important;
}

.single-performar {
  display: inline-block;
  margin-right: 25px;
  font-size: 14px;
  line-height: 1.36;
  letter-spacing: 0.56px;
  text-align: center;
  color: var(--grey-blue);
}

.single-performar img {
  width: 60px;
  border-radius: 50%;
}

.single-performar span {
  display: block;
}

.performance-tabs {
  margin-bottom: -7px;
}

.sent-alt-box {
  float: right;
  text-align: center;
  font-size: 14px;
  line-height: 1.36;
  letter-spacing: 0.56px;
  text-align: center;
  color: var(--grey-blue);
}

.sent-alt-box img {
  width: 60px;
}

.sent-alt-box span {
  display: block;
}

.single-performer p {
  display: block;
  margin-top: 10px;
}

.summary-select {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.summary-select::before {
  position: absolute;
  right: 12px;
  top: 50%;
  width: 8px;
  height: 8px;
  background: transparent;
  content: '';
  border-right: 3px solid #657d95;
  border-bottom: 3px solid #657d95;
  transform: rotate(45deg);
  margin-top: -8px;
}

/* .summary-main-title h4 {
  font-size: 18px;
  color: #657d95;
  letter-spacing: 0.5px;
} */

.main-tabs-summary li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  padding: 14px 30px;
  background: transparent;
  border-radius: 10px 10px 0 0;
  margin: 0;
  color: var(--grey-blue);
  font-weight: 800;
  text-transform: uppercase;
  font-size: 16px;
  transition: 0.3s;
  line-height: 1.25;
  letter-spacing: 0.7px;
  cursor: pointer;
}

.main-tabs-summary li.active {
  background: var(--true-white);
}

.summary-sub-tab {
  background: var(--true-white);
  margin-top: -2px;
}

.summary-area header {
  margin-bottom: 30px;
  background: #f3f3f3;
  z-index: 300;
}

.history-analytics .chart {
  /* rgb(87, 209, 255, 0.14) blue  */
  /* rgb(64, 211, 96, 0.14)  green */
  /* rgb(255, 204, 0, 0.06) yellow */
  /* background: linear-gradient( 
  to bottom,
  rgb(87, 209, 255, 0.14), 
  rgb(87, 209, 255, 0.14) 20%,
  rgb(64, 211, 96, 0.14) 1px,
  rgb(64, 211, 96, 0.14) 60%,
  rgb(255, 204, 0, 0.06) 1px,
  rgb(255, 204, 0, 0.06) 40%
  ); */
}

.sub-tab-list li {
  list-style: none;
  display: inline-block;
  color: #657d95;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.25;
  letter-spacing: 0.72px;
  padding: 25px 30px;
  text-align: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
  transition: 0.3s;
}

.sub-tab-list li.active {
  border-bottom: 2px solid var(--light-purple);
  color: var(--light-purple);
}

.sub-tab-list {
  display: inline-block;
}

.tab-info-dot {
  display: inline-block;
  float: right;
  margin-top: 20px;
  animation: shadow-pulse 2.5s infinite;
  border-radius: 50%;
}

.tab-info-dot span {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #657d95;
  border-radius: 5px;
  margin: 0 1px;
}

.centered-wrapper-problem .challenge-score {
  background: transparent !important;
}

.student-page .clng-top-txt {
  background: rgb(44, 162, 216, 0.2);
}

.student-page .view-txt {
  color: rgb(44, 162, 216);
}

.choose-page .clng-top-txt {
  background: rgb(44, 162, 216, 0.2);
}

.choose-page .view-txt {
  color: rgb(44, 162, 216);
}

.card-button-tiny img {
  position: relative;
  z-index: 5;
  width: 40px;
}

.navigation-container {
  position: relative;
  top: -35px;
}

.lesson-card {
  position: relative;
  overflow: hidden;
  background-color: transparent;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: contain;
  height: 32em;
  width: 55em;
  margin: 0px auto;
  background-image: url('./assets/images/achievements-base.png');
  overflow: inherit;
  top: -20px;
  padding: 4em;
}

.flip-image {
  height: 30px;
  position: relative;
  float: right;
  padding-top: 25%;
  margin-right: -5%;
}

.flip-card-front {
  height: 26em;
  /* border: 1px solid red; */
  overflow-x: hidden;
  overflow-y: scroll;
  /* scrollbr firefox */
  scrollbar-color: rgb(51, 40, 40, 0.8) transparent;
  scrollbar-width: thin;
}

/* scrollbr chrome */

.flip-card-front::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

.flip-card-front::-webkit-scrollbar-thumb {
  background: rgb(51, 40, 40, 0.8);
  border-radius: 10px;
}

.flip-card-front table {
  margin: 0px auto;
  width: 95%;
  /* width: fit-content; */
  font: var(--charcoal-grey-two) !important;
  height: fit-content;
  margin: 10px auto;
  /* max-width: 900px; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1em;
  /* border: 2px solid red; */
  table-layout: auto;
}

.flip-card-front table img {
  max-height: 23em;
  max-width: 45em;
  /* padding-top: 15px; */
  /* margin: 0 1%; */
}

.sample-solution {
  max-width: -webkit-fill-available;
  max-height: -webkit-fill-available;
  max-width: -moz-available;
  max-height: -moz-available;
}

@media (max-width: 768px) {
  .flip-card-front {
    height: 24em;
  }
}

@media (min-width: 768px) and (max-width: 954px) {
  .flip-card-front {
    height: 24em;
  }
}

@media (min-width: 911px) and (max-width: 1050px) {
  .flip-card-front {
    height: 26em;
  }
}

.flip-card-front td, .flip-card-front th {
  padding: 8px;
  height: 100%;
}

.flip-card-front th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  vertical-align: middle;
}

.flip-card-front ol {
  list-style-type: initial !important;
  padding: 30px;
}

.flip-card-front ul {
  list-style-type: initial !important;
  padding: 10px 30px;
}

.sk-container-white {
  background: white;
}

.spaced-container {
  margin-top: 30px;
  height: fit-content;
}

/*------ dave’s-responses page style start --------*/

.dave’s-responses {
  background: var(--true-white);
}

.s-responses-top-area {
  box-shadow: 0 6px 8px 0 rgba(101, 125, 149, 0.1);
  padding-top: 25px;
  padding-bottom: 20px;
  display: inline-block;
  width: 100%;
  background: var(--true-white);
}

.back-btn {
  display: inline-block;
  float: left;
  height: 100%;
  position: relative;
  margin-top: 10px;
  margin-right: 20px;
}

.s-response-title {
  display: inline-block;
  /* float: left; */
  max-width: 90%;
}

.response-tags li {
  display: inline-block;
  margin-right: 40px;
  color: #657d95;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  /* border-right: ; */
  position: relative;
}

.time-taken {
  /* display: none; */
  margin-right: 40px;
  color: #657d95;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: end;
  width: 22.75em;
}

.metric-title {
  display: table;
  color: #657d95;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.6px;
  position: relative;
  text-align: center;
  width: 60px;
}

.s-response-title h1 {
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1em;
  letter-spacing: normal;
  text-align: left;
  color: var(--charcoal-grey);
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: block;
  max-height: 1em;
}

.completed-response {
  padding: 50px 0;
  background: var(--true-white);
}

.left-completed-response {
  float: left;
  display: inline-block;
}

.completed-response-title {
  display: inline-block;
  width: 100%;
}

.right-completed-response {
  display: inline-block;
  float: right;
  margin-top: 10px;
}

.right-completed-response .correct-responses-indicator {
  display: inline-block;
  background-color: var(--charcoal-grey-light);
  padding: 8px 50px;
  border-radius: 40px;
  position: relative;
  z-index: 1;
  color: var(--true-white);
  font-weight: bold;
  letter-spacing: 0.8px;
  font-size: 12px;
  width: 18.75em;
  height: 2.5em;
  white-space: nowrap;
  font-weight: 800;
  line-height: 1.67;
  text-align: left;
  color: var(--true-white);
}

.right-completed-response .fill {
  position: absolute;
  left: 0;
  top: 0;
  /* width: 60%; */
  height: 100%;
  background: var(--light-purple);
  content: '';
  border-radius: 26px;
  z-index: -1;
}

.right-completed-response .correct-responses-indicator span {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.78;
  letter-spacing: 0.36px;
  text-align: center;
  color: var(--true-white);
  position: relative;
  top: 1px;
  right: 4px;
}

.left-completed-response h4 {
  font-size: 24px;
  color: #2e3039;
  margin-bottom: 5px;
}

.left-completed-response span {
  color: #1e9a22;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.5px;
  background: rgba(30, 154, 34, 0.1);
  padding: 5px 15px;
  border-radius: 12px;
}

.left-comp-text {
  width: 85%;
  float: left;
}

.right-comp-text {
  width: 15%;
  float: right;
}

.complete-incomplete-area {
  margin-top: 50px;
}

.left-comp-text h2 {
  font-size: 24px;
  /* color: ; */
  color: #657d95;
  width: 54px;
  height: 54px;
  background: #f3f3f3;
  text-align: center;
  line-height: 55px;
  border-radius: 5px;
  float: left;
  margin-right: 25px;
}

.com-response-text {
  display: inline-block;
  float: left;
}

.com-response-text p {
  color: #657d95;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 18px;
  margin: 4px 0;
}

.com-response-text h5 {
  text-transform: uppercase;
  color: #657d95;
  font-size: 14px;
  font-weight: 600;
  /* left: ; */
  letter-spacing: 0.6px;
  position: relative;
  padding-left: 10px;
}

.dot-complete {
  width: 10px;
  height: 10px;
  background: var(--grey-blue);
  display: inline-block;
  border-radius: 10px;
  position: relative;
  right: 10px;
}

.low {
  background: #ffc501;
}

.medium {
  background: #57d1ff;
}

.high {
  background: #40d360;
}

.right-comp-text button {
  /* background: var(--light-purple); */
  background: rgb(191, 130, 243, 0.6);
  border-radius: 100%;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 60px;
  display: inline-block;
  margin: 0;
  float: right;
  margin-right: 25px;
}

.single-com-item {
  display: inline-block;
  width: 100%;
  padding: 30px 0;
  border-bottom: 1px solid #f3f3f3;
}

.complete-incomplete-area:last-of-type .single-com-item {
  border: none;
}

.dot-red {
  background: #f43d6e;
}

.dot-pink {
  background: #f05fca;
}

.right-comp-text.cross-btn button svg {
  width: 20px;
  fill: #657d95 !important;
  font-weight: bold;
  line-height: 50px;
}

.right-comp-text.cross-btn button {
  background: #dfe4e6;
}

.back-btn a img {
  width: 25px;
  height: auto;
  font-weight: bold;
  fill: #333 !important;
  margin-top: 3px;
  padding-bottom: 35px;
}

.request-btn {
  float: right;
  text-align: right;
}

.request-btn a {
  background: #dfe4e6;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.6px;
  padding: 15px 35px;
  color: #657d95;
  border-radius: 3px;
}

.request-btn {
  padding-top: 10px;
}

.request-btn.bg-red a {
  /* caption-side: ; */
  color: var(--true-white);
  background: #ff641e;
  box-shadow: 0 3px 6px 0 rgba(153, 40, 249, 0.2);
}

.response-tags li {
  position: relative;
}

.response-tags li:first-child::after {
  position: absolute;
  /* left: ; */
  right: -21px;
  top: 3px;
  width: 1px;
  height: 75%;
  content: '';
  background: #d5d9dc;
}

.red-completed {
  background: rgba(255, 100, 30, 0.1) !important;
  color: #ff641e !important;
}

/****************** Create challenges modals ****************/

.create-challenge-modal {
  position: relative;
  width: 50em;
  height: 35em;
  box-shadow: 0 6px 8px 0 rgba(101, 125, 149, 0.1);
  background-color: var(--true-white);
  border-radius: 1em;
  margin-top: 30px;
  /* transform: translate(-50%, 50%); */
}

.next-step-btn {
  position: absolute;
  margin: 40px 16em;
  bottom: 0;
}

.manage-btn .large {
  width: max-content;
}

.manage-btn {
  display: inline-flex;
}

/****************** Dashboard modals ****************/

.summary-select.active::after {
  transform: rotate(225deg) !important;
  top: 25px;
}

.single-degit-number.bg-gray h4 {
  font-weight: 400;
}

.kids-performance-modal {
  width: 36em;
  height: auto;
  box-shadow: 0 6px 8px 0 rgba(101, 125, 149, 0.1);
  background-color: var(--true-white);
  border-radius: 10px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 10%);
}

.modal-title-area {
  padding: 25px 40px;
  border-bottom: 1px solid #f3f3f3;
  display: block;
  height: 30px;
}

.modal-title {
  display: inline-block;
  float: left;
}

.modal-title h4 {
  font-size: 24px;
  color: #2e3039;
  margin: 0;
  letter-spacing: 0.48px;
}

.close-modal {
  display: inline-block;
  float: right;
}

.single-modal-content {
  padding: 20px 0;
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 5px;
  display: inline-flex;
}

.create-challenge .single-modal-content {
  display: flex;
}

.single-modal-content:last-child {
  border-bottom: none;
}

.kids-performance-modal .modal-bodyy {
  padding-left: 30px !important;
  height: max-content;
  overflow-x: hidden;
  overflow-y: scroll;
  /* scrollbr firefox */
  scrollbar-color: rgb(122, 101, 149, 0.3) transparent;
  scrollbar-width: thin;
}

.modal-bodyy {
  margin: 20px 30px;
  height: 90%;
  max-height: 60vh;
  padding: 10px 10px 60px 10px;
  width: 80%;
  overflow: hidden;
}

#site-home-container .modal-bodyy {
  max-height: inherit !important;
  min-height: 60vh;
}

.modal-bodyy::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

.modal-bodyy::-webkit-scrollbar-thumb {
  background: rgb(122, 101, 149, 0.2);
  border-radius: 10px;
}

.modal-left-text {
  display: inline-block;
  margin-right: 20px;
}

.modal-right-text h4 {
  font-sheca: 36 !important;
  font-size: 18px;
}

.modal-right-text h4 {
  font-size: 18px;
  margin-bottom: 8px;
  color: #2e3039;
  letter-spacing: 0.36px;
}

.modal-right-text p {
  color: #7b8aa4;
  letter-spacing: normal;
  font-weight: normal;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #7b8aa4;
}

.modal-right-text p li {
  list-style-type: disc;
  list-style-position: inside;
  padding: 10px 0 10px 20px;
  text-indent: -1em;
}

.history-analitis {
  position: relative;
}

.avarage-box {
  width: 215px;
  height: auto;
  background: var(--true-white);
  border-radius: 18px;
  box-shadow: 0 6px 12px 0 rgba(65, 61, 61, 0.16);
  position: absolute;
  top: 15px;
  z-index: 99;
  left: 50%;
  text-align: center;
  margin-left: -107px;
  padding-bottom: 15px;
}

.single-avrg {
  width: 33.33%;
  float: left;
}

.single-avrg img {
  width: 50px;
}

.avarage-title {
  background: var(--light-purple);
  padding: 8px 0;
  border-radius: 18px 18px 0 0;
}

.avarage-title h5 {
  margin: 0;
  color: #ffffff;
  letter-spacing: 0.5px;
  font-size: 14px;
  text-transform: uppercase;
}

.avarage-text {
  padding: 8px 0;
  color: #657d95;
  line-height: 1.33;
  letter-spacing: 0.48px;
  font-size: 16px;
  text-transform: uppercase;
  margin: 0;
}

.avarage-text h5 span {
  color: #2e3039;
}

.single-avrg span {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.48px;
  color: #657d95;
}

.avarage-single-progress {
  display: inline-block;
  width: 100%;
  padding-bottom: 10px;
}

.avarage-view a {
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.54px;
  text-align: center;
  color: var(--light-purple);
  font-size: 12px;
}

.single-degit-number.bg-gray h4 {
  font-weight: 400;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: 0.36px;
}

.sign-option a {
  float: right;
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.63px;
  color: var(--light-purple);
  display: inline-block;
  text-transform: uppercase;
}

.sign-option {
  display: inline-block;
  float: right;
  padding-top: 16px;
}

.profiles-main-warpp {
  width: 100%;
  display: inline-block;
}

.profiles-main-warpp .modal-bodyy {
  max-height: max-content !important;
}

.main-pro {
  width: 35%;
  border-right: 1px solid #f3f3f3;
  float: left;
  height: 100%;
  padding: 40px 0;
}

.right-content-warp {
  width: 64%;
  float: left;
}

.just-profile img {
  display: inline-block;
  float: left;
  margin-right: 18px;
  border-radius: 100%;
  height: 60px;
  width: 60px;
}

.just-pro-text {
  display: inline-block;
  float: left;
}

.just-profile {
  display: inline-block;
  width: 100%;
  padding-bottom: 10px;
}

.just-pro-text h4 {
  font-size: 18px;
  letter-spacing: 0.36px;
  color: #2e3039;
  margin: 0;
  font-weight: 700;
}

.just-pro-text p {
  font-size: 14px;
  line-height: 1.36;
  letter-spacing: 0.56px;
  color: #657d95;
  margin: 0;
}

.profile-grade ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.profile-grade ul li {
  display: block;
  padding: 12px 0;
  color: #657d95;
  font-weight: bold;
  line-height: 1.78;
  letter-spacing: 0.36px;
  font-size: 18px;
  padding: 12px 15px;
}

.profile-grade .active {
  background: #f3f3f3;
}

.profile-grade li svg {
  position: relative;
  top: 8px;
  margin-right: 10px;
}

.pro-menu-dot {
  display: inline-block;
  float: right;
}

.pro-menu-dot span {
  display: inline-block;
  width: 4px;
  height: 4px;
  background: #657d95;
  border-radius: 100%;
  margin: 0 1px;
}

.main-profile-inner {
  padding-right: 40px;
}

.profile-grade ul li:first-child {
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
}

.create-grade a {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(101, 125, 149, 0.2);
  background-color: #f8f1fd;
  display: block;
  text-align: center;
  padding: 13px 0;
  font-weight: 800;
  line-height: 1.43;
  letter-spacing: 0.63px;
  text-align: center;
  color: var(--light-purple);
  margin-top: 15px;
}

.create-grade a span {
  font-size: 25px;
  position: relative;
  top: 2px;
  left: -5px;
}

.main-profile-inner ul li img {
  position: relative;
  /* top: 13px; */
  width: 36px;
  height: 36px;
  margin-right: 8px;
  border-radius: 50%;
}

.right-content-inner {
  width: 100%;
}

.help-title h4 {
  font-size: 24px;
  color: #657d95;
  line-height: 1.33;
  letter-spacing: 0.48px;
  font-weight: bold;
  margin: 0;
}

.help-content .modal-bodyy h4 {
  color: var(--charcoal-grey);
}

/* Style the buttons that are used to open and close the accordion panel */

.accordion {
  background-color: var(--true-white);
  color: #2e3039;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  margin: 0;
  padding: 25px 40px;
  display: inline-block;
  font-size: 18px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: 0.36px;
  margin-bottom: 0px;
  position: relative;
  border-bottom: 1px solid #f3f3f3 !important;
  padding-bottom: 55px;
}

button {
  background-color: transparent;
  background-position: left;
  background-repeat: no-repeat;
  background-size: 36px, 36px;
  margin: 11px 15px;
  height: 36px;
  width: 36px;
  margin: 12px 36px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

/* Style the accordion panel. Note: hidden by default */

.panel {
  background-color: #f3f3f3;
  overflow: hidden;
  padding-left: 20px 40px;
}

.super-panel {
  background-color: #f3f3f3;
  margin-left: 40px;
}

.super-panel .accordion {
  color: #7b8aa4 !important;
}

.super-panel .panel {
  margin-left: -40px;
  padding: 20px 120px !important;
}

.right-content-inner {
  width: 100%;
  float: right;
}

.help-title {
  padding: 40px 40px 20px 40px;
}

button.accordion:after {
  position: absolute;
  width: 8px;
  height: 8px;
  background: transparent;
  content: '';
  right: 0;
  top: 33px;
  border-right: 2px solid #2e3039;
  border-bottom: 2px solid #2e3039;
  transform: rotate(45deg);
  transition: 0.3s;
}

button.accordion.active:after {
  transform: rotate(225deg);
  top: 37px;
}

.profile-info {
  padding: 40px;
  padding-top: 0;
}

.pro-pic {
  width: 108px;
  height: 108px;
  border-radius: 100%;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
  transition: 0.3s;
}

.pro-pic span img {
  border-radius: 50%;
  width: 108px;
  height: 108px;
}

.profile-area .pro-pic::after {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  background: transparent;
  background-image: url('assets/images/parent-camera-icon.png');
  width: 30px;
  height: 30px;
}

.single-pro-info span {
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.56px;
  color: #657d95;
}

.right-content-inner .single-pro-info span {
  display: flex;
}

.pro-tags span {
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.56px;
  color: #657d95;
}

.single-pro-info h4 {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: 0.36px;
  color: #2e3039;
  margin: 0;
}

.single-pro-info {
  margin-bottom: 40px;
}

.pr-tag button {
  margin: 5px;
  padding: 9px 15px;
  display: inline-block;
  width: auto;
  height: 36px;
  background: #ddd;
  border-radius: 5px;
  border-radius: 4px;
  border: solid 1px rgba(101, 125, 149, 0.1);
  background-color: rgba(101, 125, 149, 0.1);
  margin-right: 5px;
  color: #657d95;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.63px;
}

.pr-tag {
  margin-top: 15px;
}

.pro-tags {
  margin-top: 30px;
}

/* The switch - the box around the slider */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

/* Hide default HTML checkbox */

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--light-purple);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.setting-on {
  padding-top: 17px;
}

.setting-text h4 {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: 0.36px;
  color: #2e3039;
  margin-bottom: 0px;
}

.setting-text p {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #7b8aa4;
  margin: 0;
}

.setting-text {
  display: inline-block;
  float: left;
}

.setting-on {
  display: inline-block;
  float: right;
}

.setting-itm {
  display: inline-block;
  width: 100%;
  padding: 24px 40px;
  border-bottom: 1px solid #f3f3f3;
}

.setting-feedback {
  display: block;
  width: 100%;
  float: left;
  margin-top: 15px;
}

.setting-feedback textarea {
  width: 100%;
  color: #333;
  padding: 20px;
  height: 160px;
  border-color: #cfd7db;
  border-radius: 5px;
  outline: none;
}

.arrow-left {
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(-45deg);
  display: inline-block;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #657d95;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #657d95;
}

::placeholder {
  color: #657d95;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.32px;
}

.manage-grade-top button {
  margin: 0;
  display: inline-block;
  width: 50%;
  float: left;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  color: #2e3039;
  text-align: left;
}

.manage-grade-top button span {
  width: 14px;
  height: 14px;
  display: inline-block;
  background: transparent;
  border-radius: 100%;
  border: 5px solid #cfd7db;
  position: relative;
  top: 7px;
  margin-right: 10px;
}

span.remove {
  border-color: var(--light-purple) !important;
}

.manage-grade-top {
  display: inline-block;
  width: 100%;
  padding: 30px 0;
  border-bottom: 1px dashed #e8e8e8;
  padding-top: 0;
}

.subject-select span {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: 0.36px;
}

.subject-select button {
  margin-bottom: 10px !important;
  width: auto;
  height: auto;
  padding: 11px 15px;
  text-transform: uppercase;
  border: 1px solid #eff2f4 !important;
  cursor: pointer;
}

.subject-select button.active {
  border-color: var(--light-purple) !important;
  color: var(--light-purple);
}

.subject-select {
  padding: 30px 0;
  padding-bottom: 15px;
  padding-top: 15px;
}

.note-text h4 {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: 0.36px;
  margin: 10px 0;
}

.note-text p {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #657d95;
}

.note-text p span {
  color: #f43d6e;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.manage-btn {
  text-align: center;
  margin-top: 50px;
  padding-bottom: 20px;
}

.manage-btn a {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 15px 42px;
  border: 1px solid #cfd7db;
  border-radius: 4px;
  margin: 0 15px;
}

.right-content-inner .manage-btn a {
  display: initial;
}

.manage-btn span {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 15px 42px;
  border: 1px solid #cfd7db;
  border-radius: 4px;
  margin: 0 15px;
}

.manage-btn .remove {
  box-shadow: 0 3px 6px 0 rgba(153, 40, 249, 0.2);
  background-color: var(--light-purple);
  color: #fff !important;
}

.modal-bodyy {
  padding: 20px 30px;
}

.help-text {
  overflow: hidden;
  display: inline-block;
  width: 100%;
  height: auto;
}

/* ------------------------------ About us page ---------------------------- */

.about-text {
  display: inline-block;
  width: 100%;
  height: fit-content;
}

.about-text .modal-bodyy {
  overflow: visible;
  padding: 20px 10px;
}

.about-text .panel {
  background: var(--true-white);
  height: max-content;
}

.about-text .single-modal-content {
  border: none;
}

/* ------------------------------ About us page ---------------------------- */

.notification-text {
  overflow: hidden;
  display: inline-block;
  width: 104%;
  height: auto;
}

.notification-item {
  overflow: hidden;
  padding: 20px 20px 20px 40px;
}

.active-notification-item {
  background-color: #f3f3f3;
}

.notification-item .setting-text {
  /* overflow-wrap: break-word; */
  width: 80%;
}

.notification-action {
  float: right;
  font-size: 12px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: left;
  color: var(--light-purple);
}

.staric-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.staric-list li {
  display: inline;
}

.staric-list li a {
  display: inline-block;
  /* p�: ; */
  padding: 17px 30px;
  font-size: 16px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.72px;
  color: #657d95;
  border-bottom: 2px solid transparent;
  transition: 0.3s;
}

.staric-list li.active a {
  color: var(--light-purple) !important;
  position: relative;
}

.staric-list {
  border-bottom: 2px solid #f3f3f3;
}

.staric-list li.active a::after {
  position: absolute;
  left: 0;
  bottom: -4px;
  height: 2px !important;
  width: 100% !important;
  background: var(--light-purple);
  content: '';
}

.alessandra-text {
  padding: 0 40px;
  padding-bottom: 50px;
  padding-right: 0;
}

.points-warp h5 {
  color: #657d95;
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  margin-bottom: 20px;
  letter-spacing: 0.56px;
}

.math-boxs {
  display: table;
  float: left;
  margin-right: 40px;
  width: 240px;
  height: 140px;
  margin-bottom: 20px;
}

.math-box-txt h4 {
  font-size: 32px;
  line-height: 0.75;
  letter-spacing: normal;
  color: #2e3039;
  margin-bottom: 20px;
}

.math-box-txt {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  width: 100%;
  margin-bottom: 20px;
}

.math-box-txt {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  width: 100%;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  padding: 20px;
}

.math-box-txt h4 sub {
  font-size: 16px;
  color: #2e3039;
}

.math-box-txt p {
  color: #657d95;
  letter-spacing: 0.4px;
  line-height: 1.5g;
}

.points-warp {
  display: inline-block;
  margin-top: 40px;
}

sub.green {
  color: #40d360 !important;
}

sub.red {
  color: #f43d6e !important;
}

.profile-grade.pro-noti li img {
  width: 35px;
  top: 10px;
}

.profile-grade.pro-noti li:first-child img {
  width: 22px;
  top: 7px;
}

.search-boxx {
  width: 300px;
  height: 48px;
  position: relative;
  float: left;
}

.search-boxx input {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  width: 100%;
  height: 100%;
  padding: 12px;
  padding-left: 55px;
  border-radius: 4px;
  border: 1px solid #cfd7db;
  color: #cfd7db;
  outline: none;
  transition: 0.2s;
}

.search-boxx input:focus {
  border-color: #657d95;
}

.search-boxx button {
  position: absolute;
  left: 8px;
  cursor: pointer;
  top: 8px;
  margin: 0;
}

.message-search {
  padding-top: 30px;
  padding-bottom: 20px;
}

.all-msg-btn button {
  margin: 0;
  width: 188px;
  height: 48px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(153, 40, 249, 0.2);
  background-color: var(--light-purple);
  color: var(--true-white);
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.63px;
  cursor: pointer;
}

.all-msg-btn {
  display: inline-block;
  float: left;
  margin-left: 20px;
}

.add-option {
  display: inline-block;
  float: right;
  margin-left: 25px;
  cursor: pointer;
}

.add-option .tab-info-dot {
  margin-top: 10px;
}

.add-option .tab-menu {
  top: 50px;
}

.add-option ul li a {
  font-size: 14px !important;
  text-transform: uppercase;
  color: #657d95 !important;
}

.add-option .tab-menu li:nth-child(2) a {
  background: none;
  border-radius: 0px 0px 5px 5px;
  color: #657d95 !important;
}

.add-option .tab-menu li a {
  border-bottom: 1px solid #f3f3f3;
}

.add-option .tab-info-dot span {
  width: 4px;
  height: 4px;
}

.student-name {
  display: inline-block;
  float: left;
}

.student-name img {
  width: 40px;
  float: left;
  margin-right: 30px;
}

.student-name span {
  color: #2e3039;
  font-weight: 700;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: 0.36px;
  margin-top: 3px;
  display: inline-block;
}

.student-sms-right {
  display: inline-block;
  float: right;
}

.sms-itm {
  display: inline-block;
  float: left;
}

.sms-itm img {
  width: 40px;
  float: left;
}

.student-sms-info.sms-itm img {
  width: 24px;
  padding-top: 8px;
}

.student-ms-title {
  display: inline-block;
  width: 100%;
  padding: 25px 0;
}

.student-ms-title h4 {
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.56px;
  color: #657d95;
}

.sms-itm {
  display: inline-block;
  float: left;
  position: relative;
  margin-left: 24px;
}

.sms-itm span {
  position: absolute;
  right: -3px;
  width: 20px;
  height: 20px;
  display: block;
  background: var(--light-purple) !important;
  bottom: -5px;
  border-radius: 100%;
  line-height: 20px;
  text-align: center;
}

.sms-itm span img {
  width: 8px;
  padding: 6px;
}

.student-singke-message {
  display: inline-block;
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid #f3f3f3;
}

ul.setting-option li img {
  width: 36px !important;
  top: 1px !important;
  position: relative;
  float: left;
}

ul.setting-option li.active {
  background: #f3f3f3;
}

ul.setting-option li {
  padding: 12px 15px;
}

.main-pro.message-area {
  padding: 0;
}

.search-boxx.ms-src {
  width: 100%;
}

.message-search {
  padding-top: 30px;
  padding-bottom: 20px;
  display: inline-block;
  width: 100%;
}

.profile-grade.ms-grade-inner li {
  border-bottom: 1px solid #f3f3f3;
}

.ms-arrow {
  position: relative;
}

.ms-arrow:after {
  position: absolute;
  width: 8px;
  height: 8px;
  background: transparent;
  content: '';
  right: 10px;
  top: 9px;
  border-right: 2px solid #2e3039;
  border-bottom: 2px solid #2e3039;
  transform: rotate(45deg);
  transition: 0.3s;
}

ul.member-id span {
  background: var(--light-purple);
  width: 36px;
  height: 36px;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  margin-right: 8px;
}

ul.member-id li img {
  width: 16px;
  position: relative;
  left: 3px;
  top: -2px;
}

ul.member-id li {
  color: #2e3039;
  font-size: 17px;
  line-height: 40px;
  border: none !important;
}

.message-st li {
  font-size: 14px !important;
}

.message-st li ul li img {
  width: 40px;
  /* left: ; */
  line-height: 0;
  position: relative;
  top: 4px;
}

.user-txt {
  display: inline-block;
  margin-left: 8px;
}

.user-txt h4 {
  font-size: 18px !important;
  margin: 0;
  color: #2e3039;
  padding: 0 !important;
}

.user-txt p {
  font-size: 14px !important;
  margin: 0;
  line-height: 22px;
  color: #657d95;
}

.message-st li ul li {
  border-top: 1px solid #f3f3f3 !important;
  padding: 10px;
  padding-bottom: 5px;
}

.message-st li h4 {
  font-size: 14px;
  padding: 12px 0;
  display: inline-block;
  padding-bottom: 0;
}

.user-txt h4 {
  font-size: 18px;
  margin: 0;
  color: #2e3039;
  display: block !important;
}

.revived-ms {
  display: initial;
  float: right;
  width: 30px;
  height: 30px;
  top: 2px;
  position: relative;
  color: var(--true-white);
}

.revived-ms span {
  display: initial;
  float: right;
  width: 30px;
  height: 30px;
  top: 2px;
  position: relative;
  color: var(--true-white);
}

ul.member-id li.active {
  background: #f3f3f3;
}

ul.member-id {
  padding-top: 10px;
}

.member-id .ms-arrow {
  position: relative;
  top: 2px;
}

.active-message-pro-title {
  width: 100%;
  background: #f3f3f3;
  z-index: -1;
  margin-top: -6px;
}

.active-message-pro-inner {
  padding: 15px 40px;
  padding-top: 18px;
}

.mes-title h4 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  color: #657d95;
}

.grade-tt span {
  font-size: 12px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  color: #657d95;
  padding: 0 15px;
  border-right: 1px solid #cfd7db;
}

.grade-tt span:first-child {
  padding-left: 0;
}

.grade-tt span:last-child {
  border: none;
}

.ms-tt-info {
  display: inline-block;
  float: right;
  padding-top: 12px;
}

.grade-tt {
  display: inline-block;
  height: auto;
}

.left-md-tt {
  display: inline-block;
}

.ms-tt-info a {
  width: 24px;
  height: 24px;
  background: #ddd;
  display: inline-block;
  text-align: center;
  line-height: 24px;
  border-radius: 100%;
  font-size: 18px;
  color: #657d95;
}

.send-sm-item {
  width: 100%;
  height: 400px;
  background: #eeeeee26;
  position: relative;
}

.message-sent-form-inner {
  padding: 18px 40px;
}

.message-sent-form {
  background: #f3f3f3;
  display: inline-block;
  width: 100%;
}

.emoji-box {
  display: inline-block;
  float: left;
  width: 10%;
  padding-top: 5px;
  cursor: pointer;
}

.ms-sent-write-box {
  position: relative;
  width: 90%;
  display: inline-block;
  float: right;
  height: 48px;
}

.ms-sent-write-box textarea {
  width: 94%;
  height: 30px;
  background: var(--true-white);
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 10px 15px;
}

.message-sent-form-inner {
  padding: 18px 40px;
  display: flow-root;
}

.ms-sent-write-box button {
  position: absolute;
  right: -15px;
  top: -5px;
  z-index: 2;
  cursor: pointer;
}

.chat-text-warp {
  position: absolute;
  width: 100%;
  bottom: 15px;
  left: 0px;
  display: inline-block;
}

.chat-text {
  padding: 0 40px;
}

.single-chat-text {
  background: #f3f3f3;
  width: 220px;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(148, 148, 148, 0.2);
  background-color: #f3f3f3;
  position: relative;
  z-index: 1;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #7b8aa4;
  margin-bottom: 25px;
}

.single-chat-text.align-right {
  float: right;
  margin-top: 30px;
  background: #ddd;
  box-shadow: 0 2px 4px 0 rgba(144, 45, 228, 0.2);
  background-color: var(--light-purple);
  color: var(--true-white);
}

.single-chat-text.align-right:after {
  position: absolute;
  width: 12px;
  height: 12px;
  background: var(--light-purple);
  content: '';
  right: -4px;
  bottom: 15px;
  transform: rotate(45deg);
}

.single-chat-text.align-left:after {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #f3f3f3;
  content: '';
  left: -6px;
  bottom: 15x;
  transform: rotate(45deg);
}

.single-chat-text.align-right span.send-time {
  position: absolute;
  bottom: -22px;
  color: #657d95;
  right: 0;
  font-size: 12px;
  font-size: 12px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
}

.single-chat-text.align-left span.send-time {
  position: absolute;
  bottom: -22px;
  color: #657d95;
  left: 0;
  font-size: 12px;
  font-size: 12px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
}

.math-boxs {
  margin-right: 40px !important;
}

.setting-itm {
  display: inline-block;
  width: 92%;
  padding: 24px 40px;
  border-bottom: 1px solid #f3f3f3;
}

.menu-target {
  width: 40px;
  height: 40px;
  display: inline-block;
  background: #f3f3f3;
  border-radius: 100%;
  text-align: center;
  position: fixed;
  right: 15px;
  bottom: 20px;
  cursor: pointer;
  box-shadow: 0 0 5px #999;
  line-height: 54px;
  display: none;
  z-index: 99999;
}

.menu-target svg {
  width: 25px;
  /* line-height: ; */
}

.Participant {
  /* padding-bottom: 15px; */
  display: inline-block;
  width: 100%;
  position: relative;
  text-align: left;
}

.Participant h4 {
  display: inline-flex;
  text-align: center;
  padding: 0 20px;
  width: inherit;
}

.Participant h4 span {
  display: inline;
  float: right;
}

.clng-skills-warp {
  padding-bottom: 15px;
  display: inline-block;
  width: 100%;
  position: relative;
  text-align: left;
}

.skills-clng {
  width: 87%;
  background: #ddd;
  height: 9px;
  margin: 0 auto;
  position: relative;
  border-radius: 15px;
}

.skills-clng span {
  position: absolute;
  left: 0;
  top: 0;
  width: 80%;
  height: 100%;
  background: #ddd;
  border-radius: 15px;
}

.bg-grn {
  background: #40d360 !important;
}

.bg-bgn {
  background: var(--light-purple) !important;
}

span.bg-bgn {
  width: 30%;
}

.Participant span b {
  color: #40d360;
}

.Participant span b {
  color: #40d360;
}

.left-comp-text h2 {
  font-size: 24px;
  /* color: ; */
  color: #657d95;
  width: 54px;
  height: 54px;
  background: #f3f3f3;
  text-align: center;
  line-height: 55px;
  border-radius: 5px;
  float: left;
  margin-right: 25px;
}

.clng-ques-warp {
  padding: 55px;
  background: var(--true-white);
  border-radius: 15px;
  display: block;
  margin-bottom: 30px;
  box-shadow: 0 6px 8px 0 rgba(101, 125, 149, 0.1);
}

.clng-ques-main-title h4 {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
}

.sub-title-ques span {
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.56px;
  color: #93a4b5;
}

.left-sub-title {
  width: 60%;
  float: left;
}

.right-sub-title {
  float: left;
  width: 40%;
}

.sub-title-ques {
  display: inline-block;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 30px;
}

.com-response-text {
  max-width: 400px;
}

.main-clng-ques {
  width: 100%;
  display: inline-block;
  padding: 10px 0;
}

.right-ques-text p {
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.56px;
  color: #657d95;
  display: inline-block;
  float: left;
  padding-top: 8px;
}

.left-ques-text {
  width: 60%;
  float: left;
}

.left-ques-text .left-comp-text {
  width: 100%;
}

.right-ques-text {
  width: 40%;
  float: left;
  padding-top: 10px;
}

.ques-progress {
  display: inline-block;
  margin-left: 10px;
  background: #f3f3f3;
  width: 280px;
  height: 34px;
  border-radius: 4px;
  background-color: #ededed;
  float: left;
  position: relative;
}

.ques-progress span {
  position: absolute;
  /* width: 80%; */
  height: 100%;
  background: #40d360;
  border-radius: 4px;
  left: 0;
  top: 0;
}

.left-ques-text .com-response-text p {
  padding-bottom: 8px;
}

.left-ques-text .left-comp-text h2 {
  margin-top: 10px;
}

.left-ques-text {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 10px;
}

.main-clng-ques:last-of-type .left-ques-text {
  border: none;
}

.student-pro-ms {
  display: inline-block;
  float: right;
  margin-top: 10px;
}

.s-response-title img {
  float: left;
  margin-right: 15px;
  width: 50px;
  border-radius: 100%;
  position: relative;
  top: 10px;
}

.student-por .s-response-title {
  float: left;
  width: 500px;
}

.s-pro-txt {
  display: inline-block;
}

.s-pro-txt h1 {
  margin-bottom: 0;
}

.s-pro-txt p {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.36;
  margin-bottom: 0;
  letter-spacing: 0.5px;
  color: #657d95;
  text-transform: uppercase;
}

.student-pro-ms a {
  display: inline-block;
  width: 60px;
  height: 60px;
  background: #f3f3f3;
  text-align: center;
  line-height: 76px;
  border-radius: 100%;
  margin-left: 10px;
}

.single-accivement {
  width: 25%;
  text-align: center;
  float: left;
}

.acctivement-warp {
  border-radius: 16px;
  box-shadow: 0 6px 8px 0 rgba(101, 125, 149, 0.1);
  background: var(--true-white);
  display: inline-block;
  width: 100%;
  position: relative;
  padding-bottom: 30px;
  padding-top: 30px;
  margin-top: 25px;
  margin-bottom: 25px;
}

body.student-por {
  background: #f3f3f3;
}

.student-por .s-responses-top-area {
  background: var(--true-white);
}

.single-accivement p {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.36px;
  color: #2e3039;
}

.acctivement-warp h4 {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  padding-left: 35px;
  display: inline-block;
  margin-bottom: 10px;
}

.attention-box {
  background: var(--true-white);
  border-radius: 16px;
  box-shadow: 0 6px 8px 0 rgba(101, 125, 149, 0.1);
  padding: 40px 60px;
  margin-bottom: 25px;
}

.attention-box h4 {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  margin-bottom: 20px;
}

.left-attention {
  width: 60%;
  float: left;
}

.left-attention p {
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: 0.5px;
  color: #657d95;
}

.left-attention p span {
  font-weight: 700 !important;
  letter-spacing: 0.7px;
}

.right-attention a {
  height: 48px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(153, 40, 249, 0.2);
  background-color: var(--light-purple);
  display: inline-block;
  width: 258px;
  text-align: center;
  line-height: 48px;
  font-weight: 600;
  letter-spacing: 0.6px;
  color: var(--true-white);
  float: right;
}

.attention-text {
  display: inline-block;
  width: 100%;
}

.clng-history-waarp {
  background: var(--true-white);
  padding: 40px 60px 20px 60px;
  border-radius: 16px 16px 0px 0px;
}

.clng-history-area .view-txt {
  border-radius: 0px 0px 16px 16px;
  box-shadow: 0 6px 8px 0 rgba(101, 125, 149, 0.1);
  margin-bottom: 30px;
}

.report-page .clng-history-area .view-txt {
  border-radius: 0px !important;
  box-shadow: none !important;
  margin-bottom: 0px !important;
}

.clng-history-area .extra {
  background: #fff !important;
}

.left-hitory-txt h4 {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  margin-bottom: 0;
}

.left-hitory-txt {
  display: inline-block;
  float: left;
}

.right-history-txt {
  display: inline-block;
  float: right;
}

.history-clng-title {
  display: inline-block;
  width: 100%;
  margin-bottom: 40px;
}

.right-history-txt button {
  width: auto;
  margin: 0;
  padding: 0;
  display: inline-block;
  width: 152px;
  height: 42px;
  border-radius: 24px;
  background-color: #f3f3f3;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 42px;
  letter-spacing: 0.54px;
  text-transform: uppercase;
  color: #657d95;
  margin-left: 10px;
}

button.with-bg {
  background: var(--light-purple);
  color: var(--true-white);
}

.right-history-txt button img {
  position: relative;
  top: 6px;
  left: -6px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td {
  text-align: left;
  padding: 8px;
}

tr:first-of-type {
  border-bottom: none !important;
}

tr:not(:last-of-type) {
  border-bottom: 1px solid #f3f3f3;
}

.com-response-text.history-com p {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.78;
  letter-spacing: 0.36px;
  color: #2e3039;
}

.history-table button {
  margin: 0;
  width: auto;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  width: 109px;
  height: 24px;
  border-radius: 12px;
  background-color: rgba(30, 154, 34, 0.1);
  color: #1e9a22;
}

.history-table h4 {
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.89;
  letter-spacing: 0.72px;
  float: right;
}

.history-table span {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.72px;
  color: #657d95;
  font-weight: 600;
}

.text-right {
  float: right;
}

.history-table th {
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.56px;
  color: #657d95;
  padding-bottom: 15px;
}

.history-table td {
  padding: 15px 0;
}

.main-msg-boxx {
  width: 100%;
  height: 620px;
  position: relative;
  z-index: 1;
  background-image: url(./assets/images/ms1.svg);
  background-position: center;
  background-size: cover;
}

.bg-parent-box {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  left: 0;
  top: 0;
  bottom: 0;
}

.message-box-bg {
  position: relative;
  width: 100%px;
  height: 570px;
  margin: 20px 10px;
  background: var(--true-white);
  border-radius: 50% / 10%;
  color: white;
  text-align: center;
  text-indent: 0.1em;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.16);
}

.message-box-bg:before {
  content: '';
  position: absolute;
  top: 8%;
  bottom: 8%;
  right: -4%;
  left: -4%;
  background: inherit;
  border-radius: 5% / 50%;
}

.message-inbox-list {
  width: 40%;
  float: left;
}

.chat-box-main {
  width: 60%;
  float: left;
  background: #f3f3f3;
}

.chat-box-main {
  width: 60%;
  float: left;
  background: #f3f3f3;
  height: 100%;
  position: relative;
  top: 20px;
}

.message-inbox-list {
  width: 40%;
  float: left;
}

.chat-box-main {
  width: 60%;
  float: left;
  height: 100%;
  position: relative;
  top: 20px;
}

.message-inbox-list h4 {
  font-size: 24px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #19b3fc;
  position: relative;
  top: 90px;
  left: 60px;
}

.message-inbox-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: 60px;
  margin-top: 110px;
  margin-right: 30px;
}

ul.inbox li {
  display: block;
  padding: 15px 12px;
  border-top: 1px solid #f3f3f3;
  position: relative;
}

.chat-pro {
  display: inline-block;
  float: left;
  margin-right: 15px;
  width: 55px;
  border-radius: 100%;
  position: relative;
}

.chat-list-txt h5 {
  font-size: 18px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  margin-bottom: 2px;
}

.chat-list-txt span {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.28px;
  color: #2f3643;
  opacity: 0.7;
}

.chat-pro img {
  width: 55px;
  border-radius: 100%;
}

ul.inbox li.active {
  background: #19b3fc47;
}

.active-msg span {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 26px;
  display: inline-block;
  border-radius: 100%;
  font-size: 14px;
  color: var(--true-white);
  background: #19b3fc;
  font-weight: 700;
}

.active-msg {
  position: absolute;
  right: 20px;
  top: 34%;
}

ul.inbox li {
  display: inline-block;
  width: 90%;
}

.chat-list-txt {
  padding-top: 7px;
}

li.active .chat-pro span {
  width: 15px;
  height: 15px;
  display: inline-block;
  background: #70e82b;
  border-radius: 100%;
  position: absolute;
  bottom: 4px;
  right: 5px;
  border-right: 1px solid var(--true-white);
}

.chat-box-warp {
  padding-left: 35px;
  padding-right: 100px;
  padding-top: 90px;
}

.chat-box-main {
  width: 60%;
  float: left;
  height: 98%;
  position: relative;
  top: 0;
  background-image: url(./assets/images/ms6.svg);
  background-position: initial;
  background-size: contain;
  background-repeat: no-repeat;
  left: 35px;
}

.chat-boxxx p {
  width: auto;
  height: auto;
  padding: 25px 27px;
  display: inline-block;
  background-image: url(./assets/images/ms3.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: var(--true-white);
  margin-bottom: 15px;
}

.chat-boxxx p.alignleft {
  background-image: url(./assets/images/ms3.svg);
  float: left;
}

p.alignright {
  float: right;
  color: #333;
}

p.alignleft.big-txt {
  width: 270px;
  float: left;
  background-image: url(./assets/images/ms4.svg);
  height: 82px;
  position: relative;
}

.alada {
  display: inline-block;
  width: 100%;
}

p.alignright {
  float: right;
  color: #333;
  background-image: url(./assets/images/ms4.1.svg);
  padding: 20px 25px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

p.alignright img {
  position: relative;
  margin-left: 10px;
  top: 5px;
}

.send-option {
  margin-top: 30px;
}

.emoji-boxx {
  width: 13%;
  float: left;
  padding-top: 20px;
}

.emoji-boxx img {
  width: 40px;
}

.sent-input-option {
  width: 86%;
  float: left;
}

.sent-input {
  width: 100%;
  background-image: url(./assets/images/ms9.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 75px;
}

.sent-input input {
  width: 92%;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  background: transparent;
  outline: none;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
}

.sent-input button {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 11%;
}

.sent-input img {
  width: 45px;
}

.sent-input form {
  height: 100%;
}

.message-box-main {
  margin-bottom: 50px;
}

.alada {
  position: relative;
}

span.date {
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.08;
  letter-spacing: 0.24px;
  color: #2f3643;
  opacity: 0.5;
  position: absolute;
  bottom: -12px;
  left: 20px;
}

.right-alada span.date {
  float: right;
  left: unset;
  right: 0;
}

span.day-sms {
  position: absolute;
  background: var(--true-white);
  padding: 5px 25px;
  border-radius: 25px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.3px;
  color: #19b3fc;
  left: 50%;
  top: 25px;
  margin-left: -90px;
}

.single-clng-item {
  position: relative;
}

.acctivement-area.another-page {
  margin-top: -45px;
}

.particpnt-warpp {
  text-align: center;
}

.sngl-av {
  width: 33.33%;
  float: left;
}

.sngl-av img {
  width: 55px;
}

.particpnt-warpp h5 {
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.56px;
  text-transform: uppercase;
  color: #657d95;
  margin-bottom: 25px;
}

.particpnt-warpp span {
  color: #2e3039;
}

.sngl-av p {
  text-transform: uppercase;
  font-size: 14px;
}

.clng-date.clng-dy-up {
  width: 100%;
}

.clng-itm-tags {
  padding: 40px 20px;
  padding-bottom: 20px;
  background: var(--true-white);
  border-radius: 12px 12px 0 0;
}

.clng-itm-tags span {
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.56px;
  color: hsl(210, 19%, 64%);
  display: inline-block;
}

.left-clg-itm {
  float: left;
}

.right-clg-itm {
  float: right;
}

.single-clng-2-warp .Participant h4 {
  display: block;
  text-align: left;
  padding: 0 20px;
  padding-top: 10px;
}

.single-clng-2-warp .view-txt a {
  color: #657d95;
}

.view-txt.alrady-sent a {
  color: #cfd7db;
}

.single-clng-wrpp {
  display: inline-block;
}

.single-clng-2-warp .view-txt {
  /* padding: 20px; */
  background: #fbfbfb;
}

.single-clng-2-warp .recent-plants-txt {
  padding-bottom: 25px;
}

.single-clng-wrpp.single-clng-2-warp {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.clng-completed {
  position: absolute;
  right: 0%;
  top: 18%;
  width: 100px;
}

.single-clng-item {
  margin-bottom: 15px;
}

.calander-box tr td {
  padding-left: 15px;
  padding-top: 20px;
  padding-bottom: 35px;
  border: 1px solid #f3f3f3;
  color: #657d95;
  font-size: 14px;
  font-weight: 700;
  width: 14.28%;
}

.calander-box tr th {
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.56px;
  color: #657d95;
  text-align: center;
  border-right: 1px solid #f3f3f3;
  padding-bottom: 12px;
}

.calander-title {
  text-align: center;
  padding: 35px 0;
}

.calander-title h2 {
  color: #657d95;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.1pc;
  display: inline-block;
}

.calender-box-main {
  border-radius: 16px;
  box-shadow: 0 6px 8px 0 rgba(101, 125, 149, 0.1);
  margin: 35px 0;
  padding: 0 40px;
  padding-bottom: 40px;
  background: var(--true-white);
}

.calander-page {
  background-color: #f3f3f2 !important;
}

.calander-page .s-responses-top-area {
  background: var(--true-white);
}

.calander-defination {
  display: inline-block;
  float: right;
  padding-top: 45px;
}

.calander-defination li {
  display: inline-block;
  margin-left: 40px;
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.56px;
  color: #657d95;
  text-transform: uppercase;
}

.calander-defination li span {
  width: 6px;
  height: 6px;
  display: inline-block;
  background: #657d95;
  border-radius: 100%;
  margin-right: 10px;
  position: relative;
  top: -1px;
}

.d-clr-1 {
  background: #ffcc00 !important;
}

.d-clr-2 {
  background: var(--light-purple) !important;
}

.calander-box tr td span {
  width: 6px;
  height: 6px;
  display: block;
  background: #657d95;
  border-radius: 100%;
  position: relative;
  top: 15px;
}

.hday span {
  display: inline-block;
  float: left;
  margin-right: 8px;
}

.calander-box td {
  transition: 0.3s;
}

.calander-box td:hover {
  background: #f3f3f3;
}

.create-clng-warp {
  background: #f3f3f3;
  padding: 15px;
  position: relative;
}

.calander-title span.left-arrow svg {
  width: 18px;
  height: auto;
  fill: #657d95;
  position: relative;
  top: 1px;
  right: 15px;
}

.calander-title span.arrow-right svg {
  width: 18px;
  height: auto;
  fill: #657d95;
  position: relative;
  top: 1px;
  left: 15px;
  transform: rotate(180deg);
}

.single-crt-clng {
  width: 25%;
  float: left;
}

.create-clng-area {
  display: flow-root;
  background: #f3f3f3;
  padding: 15px;
}

.add-clng {
  background: #e4e8ed;
  margin: 0 10px;
  border-radius: 4px;
  padding: 0 15px;
  background-color: #e4e8ed;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.created-clng {
  background: var(--true-white);
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: table-cell;
  vertical-align: middle;
  padding: 00 15px;
}

.single-crt-warp {
  width: 95%;
  margin: 0 auto;
  display: table;
  height: 125px;
}

.add-clng {
  text-align: center;
}

.add-clng h4 {
  width: 50px;
  height: 50px;
  display: inline-block;
  background: #f3f3f3 !important;
  border-radius: 100%;
  text-align: center;
  line-height: 48px;
  font-size: 34px;
  margin: 0 auto;
  margin-bottom: 15px;
}

.add-clng span {
  display: block;
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.63px;
  color: #657d95;
}

.clng-cd-list li {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: 0.28px;
  list-style: none;
  color: #657d95;
}

.clng-cd-list {
  margin-bottom: 12px;
}

.created-skl p {
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.3px;
  color: #657d95;
  font-weight: 600;
}

.cr-skl {
  width: 100%;
  height: 6px;
  background: #ddd;
  border-radius: 25px;
  margin-bottom: 5px;
  position: relative;
}

.created-skl.active-skl .cr-skl span {
  width: 90%;
  height: 100%;
  border-radius: 25px;
  background: #657d95;
  position: absolute;
  left: 0;
  top: 0;
}

.created-skl.txt-cr-cnlg span {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: #657d95;
}

.dft {
  color: #ffcc00 !important;
}

.upcoming {
  color: var(--light-purple) !important;
}

.ms-bar {
  width: 32px;
  height: 32px;
  margin-left: 15px;
  margin-top: 15px;
  cursor: pointer;
  border-radius: 100%;
  text-align: center;
  background-color: var(--true-white);
  line-height: 37px;
  display: none;
}

.ms-bar span {
  display: block;
  margin: 4px 0;
  width: 80%;
  height: 2px;
  background: var(--dark-sky-blue);
}

.ms-bar svg {
  width: 18px;
}

.ms-close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: none;
}

.ms-close img {
  width: 25px;
  cursor: pointer;
}

body.error-page {
  background: var(--true-white);
}

.error-area {
  display: table;
  text-align: center;
  width: 100%;
  height: 100%;
}

.error-area-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.error-txt {
  max-width: 400px;
  margin: 0 auto;
}

.error-txt h4 {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 10px;
}

.error-txt p {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.32px;
  color: #2f3643;
  margin-bottom: 35px;
}

.error-txt a {
  display: inline-block;
  background: url(./assets/images/progress-bar.png);
  background-position: center;
  background-size: contain;
  width: 200px;
  height: 66px;
  line-height: 66px;
  font-size: 20px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.9px;
  color: var(--true-white);
  background-repeat: no-repeat;
}

.quit-box {
  max-width: 320px;
  margin: 0 auto;
  position: relative;
  padding: 0px 40px;
  background-image: url('./assets/images/quit-challenge-base.svg');
  background-position: center;
  background-size: contain;
  height: 19em;
  background-repeat: no-repeat;
  padding-top: 80px;
}

.qcuit-challenge {
  max-width: 370px;
  margin: 0 auto;
  position: relative;
  height: 200px;
}

.qcuit-challenge img {
  position: absolute;
  top: -145px;
  width: 190px;
  left: 50%;
  margin-left: -90px;
}

.quit-box h4 {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: center;
  color: #302e2f;
  margin-top: 15px;
}

.quit-box p {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #2f3643;
}

.quit-box a {
  display: inline-block;
  background-image: url('./assets/images/quit-button.svg');
  background-position: center;
  background-size: contain;
  width: 200px;
  height: 66px;
  line-height: 66px;
  font-size: 20px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1px;
  color: #fff !important;
  background-repeat: no-repeat;
  margin-top: 20px;
}

.cancle-btn a {
  display: inline-block;
  width: 150px;
  height: 56px;
  line-height: 66px;
  font-size: 20px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1px;
  color: var(--true-white) !important;
  margin-top: -15px;
}

a.black.btn {
  color: #333;
  background: none;
  height: 40px;
  margin-top: 15px;
  display: block;
  width: inherit;
}

.applause-name {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: center;
  color: var(--golden-yellow);
  margin-top: 70px;
}

.applause-text {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: center;
  color: var(--true-white);
  margin-top: 10px;
}