.lesson-report {
    padding: 0px !important;
}

.gyan-questions {
  margin-top: 30px;
  height: fit-content;
  border-radius: 16px;
  box-shadow: 0 6px 8px 0 rgba(101, 125, 149, 0.1);
  background-color: #ffffff;
  padding: 55px 60px;
  padding-bottom: 120px;
}

.gyan-questions .recent-title {
    width: 236px;
    height: 32px;
    font-family: Nunito;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.48px;
    text-align: left;
    color: var(--charcoal-grey);
    padding: 55px 0px 30px 0px;
  }

  .lesson-report .gyan-lesson-header {
      background: white;
        width: 100%;
    height: 114px;
    padding: 40px 40px 0px 0px;
  }

  .gyan-questions .questions-title {
    width: 500px;
    height: 19px;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: 0.56px;
    text-align: left;
    color: var(--bluey-grey);
    padding-bottom: 40px;
    text-transform: uppercase;
  }

  .gyan-questions .questions-progress {
    width: 172px;
    height: 19px;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: 0.56px;
    text-align: left;
    color: var(--bluey-grey);
    text-transform: uppercase;
  }

  .gyan-questions .question-row {
      padding-top: 20px;
  }
  .gyan-questions .question-row .questions-title {
      height: fit-content;
      border-bottom: 1px #f3f3f3 solid;
  }
  
  .gyan-questions .question-row:last-child .questions-title {
    border-bottom: none;
  }

  .gyan-questions .question-index {
    width: 54px;
    height: 54px;
    border-radius: 8px;
    background-color: #f3f3f3;
  }

  .gyan-questions .question-index span {
    margin-left: 18px;
    font-family: Nunito;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.33;
    letter-spacing: 0.48px;
    text-align: center;
    color: var(--grey-blue);
  }

  .gyan-questions .question-details {
    width: 360px;
    height: 54px;
    font-family: Nunito;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: var(--grey-blue);
    padding-left: 30px;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .gyan-questions .question-topic {
    width: fit-content;
    height: 19px;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: 0.56px;
    text-align: left;
    color: var(--bluey-grey);
    padding-left: 30px;
  }

  .gyan-questions .question-subject {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 5px 10px 0px 0px;
  }

  .gyan-questions .lesson-participants {
    width: 50px;
    height: 24px;
    font-family: Nunito;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.78;
    letter-spacing: 0.36px;
    text-align: left;
    color: var(--grey-blue);
    padding-left: 52px;
  }

  .gyan-questions .progress-background {
    width: 288px;
    height: 34px;
    border-radius: 4px;
    background-color: #ededed;
  }

  .gyan-questions .progress-progress {
    height: 34px;
    border-radius: 4px;
    background-color: #40d360;
  }