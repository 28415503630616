.parent-recommendation {
  width: 964px;
  height: 117px;
  margin: 120px auto 0px auto;
  border-radius: 16px;
  box-shadow: 0 6px 8px 0 rgba(101, 125, 149, 0.1);
  background-color: var(--true-white);
  padding: 40px 60px;
}

.parent-recommendation .recommendation-title {
    width: 187px;
    height: 32px;
    font-family: Nunito;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.48px;
    text-align: left;
    color: var(--charcoal-grey);
    margin-bottom: 30px;
}

.parent-recommendation .recommendation-text {
    width: 427px;
    height: 55px;
    font-family: Nunito;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: var(--grey-blue);
}

.parent-recommendation button {
  width: 258px;
  height: 48px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(153, 40, 249, 0.2);
  background-color: var(--light-purple);
  margin-top: 66px !important;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.63px;
  text-align: center;
  color: var(--true-white);
}