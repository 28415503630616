.gender-ic img {
  width: 108px;
  border-radius: 50%;
}

.pro-info .active img {
  border: 2px solid var(--light-purple);
}

.hide-edit::after {
  display: none;
}

.modal-bodyy .adult-editable input {
  /* width: 440px; */
  display: block;
}

.modal-bodyy .adult-editable select {
  /* width: 440px; */
  display: block;
}

.modal-bodyy .adult-editable {
  margin: 0px;
  padding: 0px;
}

.modal-bodyy .gender-ic img {
  width: 60px;
}

.create-challenge .teacher-header {
  width: 1024px;
  height: 82px;
  box-shadow: 0 6px 8px 0 rgba(135, 153, 171, 0.1);
  background-color: var(--true-white);
  padding: 60px 0px 0px 0px;
  
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.56px;
  text-align: left;
  color: var(--grey-blue);
}

.create-challenge .teacher-header .title {
  width: 500px;
  height: 49px;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.36;
  color: var(--charcoal-grey);
}

.create-challenge .teacher-header .arrow-point-to-right {
  width: 12px;
  height: 20px;
  object-fit: contain;
  margin: 10px 26px 0px 60px;
}

.create-challenge .teacher-header .progress {
  padding-right: 60px;
}

.create-challenge .progress-background {
  width: 205px;
  height: 10px;
  border-radius: 4px;
  background-color: #ededed;
}

.create-challenge .progress-progress {
  height: 10px;
  border-radius: 4px;
  background-color: #40d360;
}

/* .adult-editable {
  margin: 30px auto;
  padding: 10px 60px;
} */

.right-content-inner .adult-editable {
  margin: 10px;
  padding: 0px;
}

.adult-editable .title {
  width: 500px;
  height: 24px;
  
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: 0.36px;
  text-align: left;
  color: var(--charcoal-grey);
  padding: 30px 0px 14px 0px;
}

.adult-editable select {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: solid 1px var(--silver);
  background-color: var(--true-white);
  
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  text-align: left;
  color: var(--grey-blue);
}

.adult-editable .next-button {
  position: relative;
  width: fit-content;
  height: 48px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(153, 40, 249, 0.2);
  background-color: var(--light-purple);
  top: 226px;
  margin: auto;
  margin-bottom: 40px;
}

.adult-editable button {
  width: 130px;
  height: 48px;
  
  margin: auto;
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.63px;
  text-align: center;
  color: var(--true-white);
}

.adult-editable input {
  padding: 10px;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: solid 1px var(--silver);
  background-color: var(--true-white);
  
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  text-align: left;
  color: var(--grey-blue);
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
}

.create-challenge-modal .adult-editable input {
  width: 40em;
}

.adult-editable .error {
  border: solid 1px #f43d6e;
}
