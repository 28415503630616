.admin-page div {
  margin: 10px;
}

.admin-page li {
  margin: 10px;
  list-style-type: none;
  float: left;
}

.admin-page input {
  margin: 10px;
  list-style-type: none;
}

.admin-page button {
  width: max-content;
}