.student_playground {
    width: 100%;
    overflow: hidden;
    color: white;
    text-align: left;
}

.challenge-header {
    padding: 0px 0px 0px 40px;
    text-align: left;
    width: 351px;
    float: left;
}
.challenge-header h1 {
    margin: 0px;
    font-size: 30pt !important;
    font-weight: 800;
    font-style: normal;
    line-height: 54pt;
    white-space: nowrap;
}
.challenge-header h3 {
    margin: 0px;
    font-size: 16pt !important;
    font-weight: 800;
    font-style: normal;
    line-height: 16pt;
    padding-top: 8px;
}

/* .mascot {
    position: absolute;
    bottom: 0px;
    z-index: 1;
    width: 298px;
    height: 339px;
}

.mascot .mascot-image {
    position: relative;
    z-index: 1;
    margin: auto;
}

.mascot .mascot-platform {
    position: absolute;
    bottom: -158px;
    left: -61px;
    height: 299px;
    width: 280px;
    z-index: -1;
} */

.challenge-wrapper {
    float: left;
    width: 80%;
    height: 620px;
    margin-left: 280px;
}

.inner {
    overflow:hidden;
}
     
.scrolling-wrapper::-webkit-scrollbar {
    width: 0px;
    background: transparent; 
}

.button-wrapper {
    width: fit-content;
    margin: auto;
}

.large-button {
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 11px 15px;
  height: 66px;
  width: 256px;
  float: left;
  background-image: url('../../../assets/images/btn-bg.png');
}

.small-button {
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 11px 15px;
  height: 66px;
  width: 220px;
  float: left;
  background-image: url('../../../assets/images/Rectangle_5.png');
}

button {
  background-color: transparent;
  background-position: left;
  background-repeat: no-repeat;
  background-size: 36px, 36px;
  margin: 11px 15px;
  height: 36px;
  width: 36px;
  margin: 12px 36px;
}

.challenges {
    background-image: url('../../../assets/images/Icn_2.png');
}

.practice {
    background-image: url('../../../assets/images/Icn_3.png');
}

.student_playground button span {
    margin: 0px;
    font-size: 14pt;
    font-weight: 700;
    font-style: normal;
    line-height: 20pt;
    white-space: nowrap;
    padding: 0px 40px;
}