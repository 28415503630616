/* Color palette */

:root {
  --charcoal-grey: #2e3039;
  --charcoal-grey-light: rgba(46, 48, 57, 0.3);
  --silver-50: rgba(207, 215, 219, 0.5);
  --grey-blue: #657d95;
  --silver: #cfd7db;
  --white: #fbfbfb;
  --bluey-grey: #93a4b5;
  --black: #000000;
  --coral: #f44747;
  --cerise: #df0a53;
  --charcoal-grey-two: #2f3643;
  --golden-yellow: #f5c818;
  --squash: #e9981e;
  --black-30: rgba(0, 0, 0, 0.3);
  --azure: #19b3fc;
  --lightish-purple: #be55eb;
  --purpley-pink: #cf39ac;
  --black-two: #0a0a09;
  --greenish-teal: #2ecb71;
  --true-white: #ffffff;
  --science-dark: #F96456;
  --science-light: #FFAF91;
  --math-dark: #73BF31;
  --math-light: #DDE56D;
  --social-studies-dark: #D83D5D;
  --social-studies-light: #F6A2C6;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--true-white);
  font-family: 'Nunito', sans-serif !important;
  font-style: normal;
  font-stretch: normal;
  margin: 0;
  padding: 0;
  background: var(--true-white);
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  text-align: left;
}

#container {
  position: relative;
  min-height: 92vh;
  min-width: 62em;
  margin: auto;
  overflow: hidden;
  padding-top: 20px;
}

#student-container {
  position: relative;
  min-height: 100vh;
  min-width: 98vw;
  margin: auto;
  overflow: hidden;
  /* padding-top: 60px; */
  /* background: var(--dark-sky-blue); */
}

#overlay-container {
  position: fixed;
  height: 100vmax;
  width: 100vmax;
  margin: auto;
  overflow: scroll;
  background: rgb(44, 162, 216, 0.95);
  z-index: 300;
  top: 0px;
  left: 0px;
}

#overlay-container-clear {
  position: fixed;
  height: 100vmax;
  width: 100vmax;
  margin: auto;
  overflow: hidden;
  z-index: 300;
  top: 0px;
  left: 0px;
}

.adult-page #overlay-container {
  background: rgb(122, 101, 149, 0.9) !important;
}

#error-overlay-container {
  position: absolute;
  height: 100vh;
  width: 100vw;
  margin: auto;
  overflow: hidden;
  background: var(--true-white);
  z-index: 300;
  top: 0px;
  left: 0px;
}

#error-overlay-container .center {
  top: 10% !important;
  width: 368px;
  text-align: center;
}

#error-overlay-container .center gio {
  width: 80%;
}

#adult-container {
  position: relative;
  min-height: 100vh;
  height: fit-content;
  width: 90vmax;
  max-width: 68em;
  margin: auto;
  padding-bottom: 40px;
}

#report-container {
  position: relative;
  min-height: 100vh;
  height: fit-content;
  min-width: 62em;
  margin: auto;
  padding-bottom: 40px;
  background: var(--true-white);
}

.science-indicator {
  background: var(--science-light) !important;
}

.science_review-indicator {
  background: #f43d6e !important;
}

.math-indicator {
  background: var(--math-light) !important;
}

.sol_math-indicator {
  background: var(--math-light) !important;
}

.word_study-indicator {
  background: #a463f7 !important;
}

.social_studies-indicator {
  background: #0d9792 !important;
}

ul {
  list-style-type: none !important;
  margin: 0;
  padding: 0;
}

.right {
  float: right;
}

.left {
  float: left;
}

.clearFix {
  clear: both;
}

.charcoal {
  color: rgb(47, 54, 67);
}

.block {
  display: block !important;
}

.scrolling-wrapper {
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
}

.wordwrap {
  white-space: pre-wrap;
  /* CSS3 */
  white-space: -moz-pre-wrap;
  /* Firefox */
  white-space: -pre-wrap;
  /* Opera <7 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* IE */
}

.center-fit {
  width: fit-content;
  margin: auto;
}

a:link {
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

a:active {
  text-decoration: none;
  color: inherit;
}

button {
  border: none !important;
  outline: none !important;
  box-shadow: none;
}

.clear {
  clear: both;
}

.center {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  z-index: 1;
  top: 30%;
  align-items: center;
  justify-content: center;
}

@-moz-document url-prefix() {
  .center {
    display: flex;
  }
}