@media screen and (min-width: 992px) {
	.header, .page-content {
		margin-left: 210px;
	}
}

@media screen and (min-width: 576px) {
	.parent-dashboard .navbar li, .navbar a {
		display: block;
		width: 100%;
	}
	.dropdown-profile-list li a {
		background: var(--true-white) !important;
	}
}

@media screen and (max-width: 1199px) {
	.user-pic img {
		max-width: 42px;
		margin-right: 8px;
	}
	.header, .page-content {
		margin-left: 74px;
	}
	.list-tick li {
		padding-left: 20px;
		font-size: 15px;
	}
	.card-scroller {
		padding: 20px 8px 15px;
	}
	.card-smart-kid {
		padding-left: 22px;
		padding-right: 22px;
	}
	.sk-card .card-body {
		font-size: 15px;
	}
	.card-body-content {
		min-height: 79px;
	}
	.card-challenge-created .card-header {
		padding: 22px 20px 12px;
	}
	.card-challenge-created .card-body {
		padding: 25px 20px 20px;
	}
	.parent-dashboard .navbar a span.nav-text, .icon-purple span {
		display: none;
	}
	.icon-purple {
		padding: 0;
		width: 48px;
	}
	.parent-dashboard .navbar a {
		text-align: center;
		padding: 0;
	}
	.parent-dashboard .navbar a span.nav-icon {
		width: auto;
	}
	.sidebar .nav-pic.dropdown-toggle:after {
		display: none;
	}
	.sidebar .nav-pic .npic {
		margin-right: 0;
	}
	.sidebar .nav-pic .npic-text {
		display: none;
	}
	.sidebar {
		width: 74px;
		height: 90%;
	}
	.parent-dashboard .navbar a svg {
		max-width: 36px;
	}
	.sidebar .logo img {
		mix-blend-mode: darken;
	}
	.steps-header-title h2 {
		font-size: 28px;
	}
	.steps-header-title {
		padding-left: 15px;
	}
	.back_btn {
		margin-top: 3px;
	}
	.progress-summary {
		width: 32%;
	}
}

@media screen and (max-width: 991px) {
	.modal-dialog.modal-lg {
		max-width: 680px;
	}
	.table-fixed td {
		width: auto !important;
	}
}

@media screen and (max-width: 767px) {
	.parent-dashboard .card-scroller {
		padding-left: 0;
		padding-right: 0;
	}
	.card-smart-kid-body {
		min-height: auto;
		margin-bottom: 25px;
	}
	.parent-dashboard .progress-status {
		font-size: 13px;
	}
	h2 {
		font-size: 21px;
	}
	.home-footer {
		padding-top: 15px;
	}
	.home-footer h4 {
		margin-top: 25px;
		margin-bottom: 15px;
	}
	.informative-area h2 {
		font-size: 25px;
	}
	.home-carousel {
		margin-bottom: -10px;
	}
	.home-strip {
		margin-top: -10px;
	}
	.banner-text li {
		font-size: 17px;
	}
	.banner-text h4 {
		font-size: 16px;
	}
	.banner-text h1 {
		font-size: 30px !important;
	}
	.parent-home .navbar-brand img {
		width: 150px;
	}
	.parent-home .navbar li+li {
		margin-left: 0;
	}
}

@media screen and (max-width: 575px) {
	.page-content {
		padding-bottom: 75px;
	}
	.header, .page-content {
		margin-left: 0;
	}
	.header .d-flex {
		width: 100%;
	}
	.info-card-cell {
		position: -webkit-sticky;
		position: sticky;
		left: 0;
	}
	.card-report {
		max-width: 100%;
		margin: 0 0 20px;
	}
	.parent-dashboard .btn-lg.dropdown-toggle {
		font-size: 14px;
	}
	.parent-dashboard .btn-lg.dropdown-toggle::after {
		margin-left: 4px;
	}
	.parent-dashboard .btn-group-lg>.btn, .btn-lg {
		padding-left: 0.7rem;
		padding-right: 0.7rem;
	}
	.challenge-list-created {
		font-size: 12px;
	}
	.challenge-list-created p {
		font-size: 14px;
	}
	.order-1-small {
		-webkit-order: -1;
		-moz-order: -1;
		-ms-order: -1;
		-o-order: -1;
		order: -1;
	}
	.btn-dots img {
		width: 24px;
	}
	.challenge-list-created .challenge-list-item {
		margin-bottom: 20px;
	}
	.sidebar {
		top: auto;
		bottom: 0;
		width: 100%;
		height: 50px;
		line-height: 50px;
		padding-right: 10px;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content: space-between;
		-webkit-box-shadow: 0 0 8px 0 rgba(101, 125, 149, 0.2);
		box-shadow: 0 0 8px 0 rgba(101, 125, 149, 0.2);
	}
	.ur-profile {
		-webkit-order: -1;
		-moz-order: -1;
		-ms-order: -1;
		-o-order: -1;
		order: -1;
		width: 25%;
	}
	.icon-purple {
		width: 42px;
		height: 42px;
		line-height: 42px;
	}
	.sidebar .navbar {
		margin-top: 0;
	}
	.parent-dashboard .navbar {
		flex-grow: 1;
		padding: 0 15px !important;
		justify-content: space-around;
	}
	.parent-dashboard .navbar a {
		border-width: 0 0 4px 0;
		display: block;
		padding: 0 12px;
	}
	.sidebar .nav-pic .npic {
		min-width: auto;
	}
	.sidebar .nav-pic:before {
		top: 5px;
		right: 5px;
		z-index: 5;
		width: 8px;
		opacity: 1;
		left: auto;
		content: "";
		height: 8px;
		bottom: auto;
		visibility: visible;
		border: none;
		position: absolute;
		border-radius: 8px;
		background-color: var(--light-purple);
	}
	.sidebar .nav-pic {
		margin-top: 0;
		position: relative;
	}
	.sidebar .nav-pic img {
		max-width: 40px;
	}
	.dropdown-profile-list {
		width: 100%;
		padding-bottom: 15px;
		background-color: #f5f5f5;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		margin-bottom: 0 !important;
		position: fixed !important;
		bottom: 0 !important;
		margin: auto !important;
		top: auto !important;
		-webkit-transform: none !important;
		-ms-transform: none !important;
		-o-transform: none !important;
		transform: none !important;
	}
	.dropdown-profile-list li {
		max-width: 80%;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.dropdown-profile-list li a {
		font-size: 15px;
		padding: 6px 15px;
		background-color: #ebedef;
	}
	.dropdown-profile-list h3 {
		padding-top: 10px;
		margin-bottom: 15px;
		padding-bottom: 10px;
		color: var(--grey-blue);
		border-bottom: 1px solid var(--grey-blue);
	}
	.dropdown-profile-list .npic {
		max-width: 40px;
		margin-right: 12px;
		-webkit-flex: 0 0 40px;
		-moz-flex: 0 0 40px;
		-ms-flex: 0 0 40px;
		-o-flex: 0 0 40px;
		flex: 0 0 40px;
	}
	.dropdown-profile-list li a .active_icon {
		width: 16px;
	}
	.sk-choose-item {
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.steps-header-progress {
		float: none;
		margin: auto;
		margin-top: 20px;
		max-width: 300px;
	}
	.modalAssignChallenge .modal-header, .modalAssignChallenge .modal-body {
		padding: 25px 15px 15px 15px;
	}
	.modal-header-title h3 {
		font-size: 29px;
	}
	.modal-header-info .level-setup, .steps-header-info .level-setup {
		display: block;
		width: 100%;
		margin: 10px 0 15px;
	}
	.modal-header-info .level-setup+span, .steps-header-info .level-setup+span {
		margin-left: 0;
		border-left: 0;
		padding-left: 0;
	}
	.ms-count {
		width: 40px;
		height: 40px;
		font-size: 18px;
		line-height: 40px;
	}
	.question-section {
		font-size: 16px;
		padding-left: 0;
		margin-bottom: 45px;
	}
	.question-section .ms-count+p {
		padding-left: 55px;
	}
	.challenge-row {
		max-width: 360px;
		width: 100%;
		margin: auto;
	}
	.progress-summary {
		width: auto;
	}
	.progress-summary .progress {
		width: 80px;
	}
}

@media screen and (max-width: 479px) {
	h3 {
		font-size: 16px;
	}
	.card-scroller .flex-nowrap .col-sm-5 {
		min-width: 269px;
	}
	.sk-card .card-body {
		padding: 15px 12px 25px;
	}
	.icon-purple {
		width: 36px;
		height: 36px;
		line-height: 35px;
	}
	.icon-purple img {
		max-width: 50%;
	}
	.header {
		height: 60px;
		line-height: 60px;
	}
	.logo img {
		height: 60px;
	}
	.steps-btns .btn {
		min-width: 90px;
	}
	.gv-item+.gv-item {
		min-width: 50%;
	}
	.time-stamp {
		font-size: 11px;
	}
}