.lessonFinished {
  position: absolute;
  width: 100%;
  height: 88vmax;
  z-index: 100;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  background-color: rgb(44, 162, 216, 0.95);
}

.complete-challenge {
  max-width: 24em;
  margin: 14em auto;
  position: relative;
  height: 16em;
}

.complete-challenge .happy-character {
  position: absolute;
  top: -11em;
  width: 24em;
  left: 0px;
}

.complete-challenge .stars {
  position: absolute;
  left: 6em;
  width: 12.5em;
  top: 20px;
}

.level-box {
  max-width: 24em;
  margin: 0 auto;
  position: relative;
  padding: 40px;
  background-image: url('../../../assets/images/levelup.png');
  background-position: center;
  background-size: contain;
  height: 28em;
  background-repeat: no-repeat;
  padding-top: 90px;
}

.level-box p {
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: center;
  color: var(--charcoal-grey-two-light);
}

.complete-level {
  max-width: 24em;
  margin: 14em auto;
  position: relative;
  height: 16em;
}

.complete-level .happy-character {
  position: absolute;
  top: -12em;
  width: 24em;
  left: 0px;
}

.level-value {
  font-size: 72px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.44px;
  text-align: center;
  color: var(--azure);
}

.level-box a {
  display: inline-block;
  background-image: url('../../../assets/images/small-blue-button.svg');
  background-position: center;
  background-size: contain;
  width: 303px;
  height: 100px;
  line-height: 100px;
  font-size: 20px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1px;
  color: var(--true-white) !important;
  background-repeat: no-repeat;
  margin-top: 2em;
  text-transform: uppercase;
}

.challenge-box {
  max-width: 24em;
  margin: 0 auto;
  position: relative;
  padding: 40px;
  background-image: url('../../../assets/images/challenge-complete-base.svg');
  background-position: center;
  background-size: contain;
  height: 24em;
  background-repeat: no-repeat;
  padding-top: 80px;
}

.challenge-box p {
  text-align: center;
  text-transform: uppercase;
}

.challenge-box a {
  display: inline-block;
  background-image: url('../../../assets/images/review-button.svg');
  background-position: center;
  background-size: contain;
  width: 200px;
  height: 66px;
  line-height: 66px;
  font-size: 20px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1px;
  color: #fff !important;
  background-repeat: no-repeat;
  margin-top: 0;
  text-transform: uppercase;
}

.lessonFinished h1 {
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  
  font-size: 40px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.35;
  letter-spacing: 0.8px;
  text-align: center;
  color: var(--true-white) !important;
  height: 10px;
  margin-top: 60px;
}

.quit-challenge-container {
  height: 100vh;
}

.improve {
  background-image: url('../../../assets/images/improved.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
  width: inherit;
}

.quit-card {
  width: 320px;
  height: 200px;
  background-color: transparent;
  background-image: url('../../../assets/images/quit-challenge-base.png');
  background-repeat: no-repeat;
  background-position: center 2em;
  background-size: inherit;
  text-align: center;
  padding: 4em;
}

.quit-challenge {
  width: 170px;
  height: 32px;
  
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: center;
  color: #302e2f;
  margin: auto;
  padding-top: 30px;
  white-space: nowrap;
}

.quit-challenge-message {
  padding-top: 25px;
  width: 227px;
  
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--charcoal-grey-two);
  margin: auto;
}

.points {
  height: 80%;
  text-transform: uppercase;
  position: relative;
  left: 0px;
  width: 100%;
  top: 20px;
  font-size: 16px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: var(--charcoal-grey-two);
}

.result-card .stars {
  z-index: 9;
  width: 200px;
  position: relative;
  top: 45px;
}

.points-scored {
  width: 60%;
  margin: 40px auto 0px auto;
  padding-bottom: 20px;
  font-size: 40px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--golden-yellow);
  border-bottom: 1px solid rgb(112, 112, 112, 0.2) !important;
}

.lessonFinished .card-button-small {
  position: relative;
  bottom: -50px;
}

.questions-correct {
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  
  font-size: 20px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.9px;
  text-align: center;
  color: var(--greenish-teal);
}

.correct-responses {
  font-size: small;
  padding: 10px;
}

.cancle-btn a {
  color: var(--true-white) !important;
  padding-top: 25px;
  font-size: 20px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: 0.9px;
  text-align: center;
  color: var(--true-white) !important;
  text-transform: uppercase;
}