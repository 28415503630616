.smart-kids {
  width: fit-content;
  font-family: Nunito;
  font-size: 40px;
  font-weight: 800;
  line-height: 1.35;
  letter-spacing: 0.8px;
  text-align: center;
  color: var(--true-white);
  margin: auto;
}

.on-blue a:hover {
  color: var(--true-white);
  text-shadow: 2px 2px var(--charcoal-grey-light);
}

.login-wrapper {
  margin: auto;
  /* max-width: 68em; */
  width: 56em;
  min-height: 75vh;
  background-color: transparent;
  padding-top: 1em;
  background-size: cover;
  background-image: url('./../../assets/images/login-card.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 56em;
  min-height: 40em;
}

.login-mascot {
  position: relative;
  width: 40%;
  margin-left: 5%;
  float: left;
}

.login-wrapper img.top-cart {
  display: none;
}

.login-mascot img {
  vertical-align: middle;
  padding-top: 2.5em;
  width: 100%;
  object-fit: contain;
  margin: auto;
}

.login {
  position: relative;
  width: 48%;
  height: 28.623em;
  margin-right: 2.375em;
  z-index: 100;
  float: right;
}

.login form {
  padding-top: 5.25em;
}

.login .password-form {
  padding-top: 3.25em !important;
}

.password-form div {
  padding: 8px !important;
}

.login-wrapper img.top-cart {
  top: 77px !important;
}

.login form button {
  width: 144px;
  height: 80px;
  margin: auto;
  background: url('../../assets/images/login-button.png') no-repeat;
  background-position: center;
  font-size: 20px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: 0.9px;
  text-align: center;
  color: var(--true-white);
}

.hero-text .login-button button {
  width: 144px;
  height: 80px;
  margin: auto;
  background: url('../../assets/images/login-button.png') no-repeat;
  background-position: center;
  font-size: 20px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: 0.9px;
  text-align: center;
  color: var(--true-white);
}

.login .login-button {
  width: 144px;
  font-size: 20px;
  margin: auto;
}

.login .enter {
  width: 23.125em;
  height: 80px;
  margin: auto;
  background-color: transparent;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../../assets/images/login-input.svg');
}

.login .error {
  background-image: url('../../assets/images/error-input.png');
  background-repeat: no-repeat;
}

.login .enter-text {
  width: 23.125em;
  height: 20px;
  margin: auto;
}

.login .input {
  border: 0px solid #444444;
  height: 75%;
  width: 100%;
  color: black !important;
  font-size: 20px;
  text-align: center;
  border: none;
  background: transparent;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--charcoal-grey-two);
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--charcoal-grey-two);
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--charcoal-grey-two);
}

:-moz-placeholder {
  /* Firefox 18- */
  color: var(--charcoal-grey-two);
}

.login .highlight {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 0.9px;
  text-align: center;
  color: var(--azure);
}

@media screen and (max-width: 1300px) {
  #container {
    width: fit-content !important;
    min-width: 100% !important;
  }
  .login-mascot {
    display: none !important;
  }
  .login-wrapper img.top-cart {
    display: block;
  }
  .login-wrapper {
    height: 30em;
    background: transparent;
    max-width: 100% !important;
    margin: auto !important;
    /* margin-top: 30px !important; */
    /* margin-bottom: 60px !important; */
    padding-top: 60px !important;
    background-size: cover;
    min-width: 30em !important;
    background-color: transparent;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 28em;
    background-image: url('./../../assets/images/login-background.png');
  }
  .login-message {
    height: 60% !important;
    width: 40% !important;
    margin: 0 auto 30px auto !important;
    padding-top: 6em !important;
  }
  .forgot {
    padding-bottom: 50px !important;
  }
  .login {
    float: none;
    margin: auto;
    width: 100%;
  }
}

.login div {
  padding: 10px;
}

.forgot {
  width: 362px;
  height: 46px;
  opacity: 0.6;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  color: var(--charcoal-grey-two);
}

.forgot-password-link {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--grey-blue);
}

.enter-text-links {
  width: fit-content !important;
}

.wide-button {
  width: 260px !important;
  background: url(../../assets/images/blue-button.png) no-repeat !important;
}

.wide-login-button {
  width: 250px;
  font-size: 20px;
  margin: auto;
}

.g-recaptcha {
  /* display: inline-block; */
}

.captcha-container {
  /* text-align: center;
    background-image: none !important; */
  height: fit-content;
}

.login-message {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--charcoal-grey-two);
  opacity: 0.5;
  margin: 60px auto;
  height: 40%;
  width: 80%;
}

.captcha-containers {
  width: fit-content;
  margin: auto;
}

#login footer {
  position: left;
  clear: both;
  color: #ffffff;
  background-color: rgb(243, 243, 243. 0.5);
  text-align: center;
  height: 30px;
}

#login footer ul {
  list-style: initial !important;
  min-width: 696px;
}

#login footer li {
  display: inline !important;
}

#login footer a:hover {
  color: #ffffff;
  text-shadow: 5px 2px var(--charcoal-grey-light);
}