

.gyan-recent .scrolling-wrapper {
    width: inherit;
    margin: 20px 0px 0px 0px;
  }

.gyan-recent .challenge-card {
    position: relative;
    width: 320px;
    height: 430px;
    border-radius: 18px;
    box-shadow: 0 6px 12px 0 rgba(65, 61, 61, 0.16);
    background-color: #ffffff;
    overflow: hidden;
    margin: 0px 50px 10px 0px;
    display: inline-block;
    float: initial;
}

.gyan-recent .challenge-card .header {
  width: 320px;
  height: 90px;
  background-color: rgb(247, 241, 253);
  overflow: hidden;
  text-transform: uppercase;
}

.gyan-recent .challenge-card .header-text {
    font-weight: 800;
    line-height: 1.36;
    letter-spacing: 0.6px;
    font-size: 14px;
    font-weight: bold;
    padding: 20px 0px 20px 20px;
    height: 19px;
    opacity: 0.7;
    color: var(--grey-blue);
}

.gyan-recent .challenge-card .header-date {
    font-size: 10px !important;
    color: rgb(108, 124, 147);
    padding-bottom: 8px;
} 

.gyan-recent .challenge-card .header-score {
    width: 90px;
    height: 90px;
    background-color: var(--light-purple);
    color: white;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: #ffffff;
    padding-top: 20px;
}

.gyan-recent .challenge-card .header-score div {
    margin: auto;
    width: fit-content;
}

.gyan-recent .challenge-card .header-score .score-value {
    width: 39px;
    height: 43px;
    font-size: 32px;
    font-weight: 800;
    line-height: 1.34;
    letter-spacing: 0.6px; 
    
    color: #ffffff;
    text-align: center;
 }

 .gyan-recent .challenge-card .header-score .score-key {  width: 50px;
    height: 19px;
    opacity: 0.6;
    font-size: 14px;
    font-weight: 800;
    line-height: 1.36;
    letter-spacing: 0.6px;
    
    color: #ffffff;
 }

 .challenge-card .lesson-name {
     border-bottom: 1px rgb(108, 124, 147) dashed;
     margin: 20px;
    height: 130px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.45;
    
    
 }

 .challenge-card .lesson-participants {
    width: fit-content;
    height: 19px;
    font-size: 14px;
    font-weight: 800;
    line-height: 1.36;
    letter-spacing: 0.6px;
    
    
    margin: auto;
    text-transform: uppercase;
 }

 .challenge-card .lesson-progress-donut {
    width: 80px;
    height: 100px;
    text-align: center;
 }

 .view-challenge {
    height: 60px;
    background-color: #fbfbfb;
    width: inherit;
    height: 22px;
    font-size: 16px;
    font-weight: 800;
    line-height: 1.25;
    letter-spacing: 0.7px;
    text-align: center;
    color: var(--light-purple);
    text-transform: uppercase;
    padding: 20px;
 }