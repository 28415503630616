.profile-playground {
  height: inherit;
}

.top-left-button {
  position: absolute;
  padding: 0px 40px;
  z-index: 3;
}

.top-right-button {
  position: absolute;
  padding: 0px 40px;
  z-index: 30;
  right: 0px;
}

.profile-wrapper {
  position: relative;
  width: fit-content;
  margin: auto;
  top: 16px;
  height: 280px;
  width: fit-content;
  padding-top: 100px;
}

.avatar-image .sk-badge {
  position: relative;
  background-image: url('../../../assets/images/student-camera-icon.png');
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 40px;
  width: 40px;
  top: -20px;
  left: 100px;
}

.avatar-image {
  position: relative;
  margin: auto;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 120px, 120px;
  height: 120px;
  width: 120px;
  background-image: url('../../../assets/images/Rectangle-profile-pic.png');
  z-index: 1;
}

.avatar-image img {
  position: relative;
  height: 110px;
  width: 110px;
  padding-top: 4px;
  border-radius: 20px;
  display: block;
  margin-left: 5px;
}

.avatar-image .edit-avatar {
  width: 30px !important;
  height: 30px !important;
  position: relative;
  top: -30px;
  left: 90px;
}

.profile-card {
  position: relative;
  margin: auto;
  background-size: 642px, 372px;
  width: 642px;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 642px, 372px;
  height: fit-content;
  width: 642px;
  top: -80px;
  padding-top: 100px;
  background-image: url('../../../assets/images/Rectangle-profile.png');
}

.profile-name h1 {
  position: relative;
  color: rgb(25, 179, 252);
  font-weight: bold;
  text-align: center;
  margin: 10px;
}

.profile-details-table {
  position: relative;
  margin: auto;
  width: 80%;
}

.profile-details {
  position: relative;
  z-index: 25;
  width: 30%;
  float: left;
  border-right: 1px solid gray;
  color: rgb(47, 54, 67);
  text-align: center;
}

.profile-details-table .large {
  width: 49%;
}

.profile-details:last-child {
  border: none;
}

.profile-header {
  text-transform: uppercase;
  padding: 10px;
  font-size: 10pt;
  font-weight: 800;
  font-style: normal;
  line-height: 14pt;
}

.header-value {
  font-size: 14pt;
  font-weight: 900;
  font-style: normal;
  line-height: 14pt;
  padding: 10px 0px;
  white-space: nowrap;
}

.family-wrapper {
  position: relative;
  margin: auto;
  width: 80%;
  padding-top: 30px;
}

.family {
  position: relative;
  margin: auto;
  background: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 604px, 137px;
  height: 137px;
  width: 604px;
  z-index: 2;
  background-image: url('../../../assets/images/Subtraction-9.png');
}

.family .family-details {
  width: 46%;
  border: none;
  float: left;
}

.family-details img {
  border-radius: 50%;
  height: 56px;
  width: 56px;
  float: left;
}

.family-details {
  text-align: left;
  margin-left: 10px;
  white-space: nowrap;
}

.school .profile-details {
  float: left;
}

.school .profile-details img {
  border-radius: 50%;
  height: 56px;
  width: 56px;
  float: left;
  margin-top: 10px;
}

.school .family-details {
  margin-left: 0px !important;
  color: white;
}

.school {
  position: absolute;
  height: 180px;
  width: 100%;
  bottom: 0px;
}

.school .profile-details {
  width: 30%;
  padding-left: 30px;
  padding-top: 30px;
  border: none;
}
