.choose-subject-playground {
  height: 100vh;
  position: fixed;
  margin: auto;
  background-color: transparent;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../../../assets/images/choose-chapter-background.png');
}

.choose-subject-wrapper {
  width: max-content;
  margin: auto;
  text-align: center;
}

.choose-subject-wrapper h1 {
  position: relative;
  font-weight: bold;
  text-align: center;
  margin: 10px;
  color: var(--true-white);
  padding-top: 20px;
}

.choose-subject-card-wrapper ul {
  position: relative;
  width: max-content;
  margin: auto;
  height: 250px;
}

.choose-subject-card-wrapper ul:first-child {
  margin-top: 40px;
}

.choose-subject-wrapper .close-button {
  position: absolute;
  right: 12%;
  top: 19%;
  height: 60px;
  cursor: pointer;
}

.choose-subject-card-wrapper li {
  float: left;
  background-color: transparent;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 223px;
  width: 280px;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: center;
  color: var(--true-white);
}

.choose-subject-card-wrapper li span {
  position: relative;
  top: 20px;
  word-wrap: break-word;
  width: 80%;
}

.marque {
  position: relative;
  width: 113px;
  height: 113px;
  background: white;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -35px;
}

.marque img {
  width: 75px;
  height: 75px;
  margin: 15px;
}

.subject-science {
  background-image: url('../../../assets/images/science-subject.svg');
}

.subject-math {
  background-image: url('../../../assets/images/math-subject.svg');
}

.subject-english {
  background-image: url('../../../assets/images/english-subject.svg');
}

.subject-history {
  background-image: url('../../../assets/images/social_studies-subject.svg');
}

.subject-geography {
  background-image: url('../../../assets/images/geography-subject.svg');
}

.subject-vocabulary {
  background-image: url('../../../assets/images/vocabulary-subject.svg');
}
