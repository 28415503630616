@media only screen and (max-width: 1920px) {
  .choose-chapter-area {
    top: 30%;
  }
  .choose-chapter-item-warp {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 1449px) {
  .choose-chapter-area {
    top: 18%;
  }
  .choose-chapter-item-warp {
    padding-bottom: 20px;
  }
  .math-boxs {
    margin-right: 25px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1920px) {
  .single-math-item img.mathicon {
    top: -15px;
    left: 26px;
  }
}

@media (min-width: 911px) and (max-width: 1050px) {
  .lock-item .star-item::before {
    width: 84%;
    height: 54%;
    left: 8%;
    top: 5%;
  }
  .main-item.mathpoint-item {
    /* height: 365px; */
    top: -4px;
  }
  .single-math {
    height: 335px;
  }
  .cloud-1 {
    position: absolute;
    left: -25%;
    top: 0%;
    width: auto;
    height: 100%;
    z-index: -1;
  }
  .cloud-2 {
    position: absolute;
    right: -25%;
    top: 0%;
    width: auto;
    height: 100%;
    z-index: -1;
  }
  .choose-page {
    overflow: hidden;
  }
  .clng-score h4 {
    font-size: 22px !important;
  }
  .math-boxs {
    margin-right: 25px;
  }
  .setting-itm {
    display: inline-block;
    width: 85%;
    padding: 24px 40px;
    border-bottom: 1px solid #f3f3f3;
  }
  .s-response-title h1 {
    font-size: 28px;
    margin-top: 7px;
  }
  .chat-box-main {
    width: 60%;
    float: left;
    height: 94%;
    position: relative;
    top: 12px;
    background-image: url(./assets/images/ms6.svg);
    background-position: initial;
    background-size: contain;
    background-repeat: no-repeat;
    left: 35px;
  }
  .main-msg-boxx {
    width: 92%;
    height: 500px;
    position: relative;
    z-index: 1;
    background-image: url(./assets/images/ms1.svg);
    background-position: center;
    background-size: cover;
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 954px) {
  .lock-item .star-item::before {
    width: 84%;
    height: 54%;
    left: 8%;
    top: 5%;
  }
  .single-math {
    height: 300px;
  }
  .single-math {
    height: 300px;
  }
  .main-item.mathpoint-item {
    height: 300px;
  }
  .mathpoint-item .item-list {
    padding-left: 45px;
    padding-top: 70px;
  }
  .mathpoint-item .skill-btn {
    margin-top: 25px;
  }
  .star-area {
    top: 8px;
  }
  .item-list {
    padding-left: 3em;
    padding-top: 38px;
  }
  .text-lock {
    bottom: 10%;
  }
  .text-lock strong {
    font-size: 16px;
  }
  .text-lock p span {
    font-size: 12.5px;
  }
  .star-area img {
    display: inline-block;
    /* width: 36px; */
    margin: 0 -3px;
  }
  .point span {
    /* opacity: 0.8; */
    /* font-size: 14px; */
  }
  .star-area img:nth-child(2) {
    width: 40px;
  }
  .cloud-1 {
    position: absolute;
    left: -30%;
    top: 0%;
    width: auto;
    height: 100%;
    z-index: -1;
  }
  .cloud-2 {
    position: absolute;
    right: -30%;
    top: 0%;
    width: auto;
    height: 100%;
    z-index: -1;
  }
  .choose-page {
    overflow: hidden;
  }
  .clng-score h4 {
    font-size: 22px;
  }
  .math-boxs {
    margin-right: 25px;
  }
  .setting-itm {
    display: inline-block;
    width: 85%;
    padding: 20px;
  }
  .s-response-title h1 {
    font-size: 28px;
    margin-top: 7px;
  }
  .main-msg-boxx {
    width: 90%;
    height: 394px;
    margin: 0 auto;
  }
  .message-inbox-list h4 {
    font-size: 20px;
    top: 30px;
    left: 60px;
  }
  .message-inbox-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: 40px;
    margin-top: 50px;
    margin-right: 0;
  }
  .chat-pro img {
    width: 45px;
  }
  .chat-list-txt h5 {
    font-size: 15px;
  }
  .chat-list-txt span {
    font-size: 11px;
  }
  .chat-pro {
    width: 45px;
  }
  ul.inbox li {
    display: block;
    padding: 8px 12px;
  }
  .active-msg span {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
  }
  span.day-sms {
    display: none;
  }
  .chat-boxxx p {
    padding: 17px 15px;
    font-size: 14px;
  }
  p.alignleft.big-txt {
    width: 200px;
    float: left;
    background-image: url(./assets/images/ms4.svg);
    height: 60px;
    position: relative;
  }
  .chat-box-warp {
    padding-left: 25px;
    padding-right: 100px;
    padding-top: 21px;
  }
  .sent-input {
    width: 100%;
    height: 55px;
    border-radius: 15px;
  }
  .send-option {
    margin-top: 18px;
  }
  .emoji-boxx img {
    width: 31px;
  }
  .emoji-boxx {
    padding-top: 13px;
  }
  .sent-input img {
    width: 35px;
  }
  .sent-input button {
    position: absolute;
    cursor: pointer;
    right: -15px;
    top: 2%;
  }
  .message-box-main {
    padding-top: 150px;
  }
}

@media (min-width: 768px) and (max-width: 880px) {
  .single-math {
    height: 17em;
    width: 17em;
  }
  .main-item.mathpoint-item {
    height: 17em;
    width: 17em;
  }
  .item-list li {
    font-size: 18px;
  }
  .star-item:after {
    height: 70%;
    /* left: 3px; */
    z-index: -2;
  }
  .lock-item .star-item::before {
    width: 87%;
    height: 55%;
    left: 7.8%;
    top: 6%;
  }
  .lock-text p strong {
    font-size: 18px;
    text-transform: uppercase;
    color: #2f3643;
  }
  .lock-text p span {
    font-size: 12px;
  }
  .mathpoint-item .skill-btn {
    width: 68%;
    margin: 0 auto;
    margin-top: 11px;
    height: 50px;
    text-align: center;
  }
  .mathpoint-item .skill-btn span {
    font-size: 12px;
  }
  .mathpoint-item .point {
    font-size: 14px;
    top: 9%;
  }
  .Choose-chapter .header-meddle {
    width: 80%;
    float: left;
    text-align: right;
  }
  .header-meddle h3 {
    text-align: right;
    white-space: nowrap;
  }
  .choose-chapter-area {
    display: inline-block;
    top: 30%;
  }
  .chapure-title {
    top: 22px;
    z-index: 99;
  }
  .choose-main-txt {
    bottom: 20%;
  }
  .single-path img {
    width: 90px;
  }
  .choose-path-area {
    margin-top: -25px;
  }
  .clng-score h4 {
    font-size: 22px;
  }
  .setting-itm {
    display: inline-block;
    width: 85%;
  }
  .s-response-title h1 {
    font-size: 28px;
    margin-top: 7px;
  }
  .staric-list li a {
    padding: 17px 20px;
    font-size: 12px;
  }
}

@media (min-width: 800px) and (max-width: 910px) {
  .skill-btn {
    margin-top: 9px;
    /* height: 54px; */
  }
  .skill-btn span {
    font-size: 13px;
  }
  .skill-btn p {
    font-size: 13px;
  }
  .point {
    position: absolute;
    right: 10%;
    /* top: 12%; */
    color: #000;
  }
  .mathpoint-item .item-list {
    padding-left: 48px;
  }
  .mathpoint-item .point {
    font-size: 14px;
    top: 9%;
  }
  .clng-score h4 {
    font-size: 22px;
  }
  .accordion {
    padding: 25px 25px;
    font-size: 15px;
    letter-spacing: 0.35px;
    padding-bottom: 50px;
  }
  .search-boxx {
    width: 250px;
    height: 48px;
  }
  .all-msg-btn button {
    margin: 0;
    width: 160px;
    height: 46px;
    font-size: 12px;
  }
  .s-response-title h1 {
    font-size: 28px;
    margin-top: 7px;
  }
  .main-msg-boxx {
    width: 82%;
    height: 394px;
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 799px) {
  .skill-btn {
    /* margin-top: 15px; */
    /* height: 48px; */
  }
  .skill-btn span {
    font-size: 13px;
  }
  .question-bar {
    width: 100%;
  }
  .skill-btn p {
    font-size: 13px;
  }
  .point {
    position: absolute;
    right: 5%;
    /* top: 17%; */
    color: #000;
    font-size: 14px !important;
  }
  .skills-list li {
    font-size: 18px;
    letter-spacing: 0.5px;
    margin-bottom: 5px;
  }
  .skills-list li:nth-child(1) {
    text-transform: uppercase;
    font-size: 15px;
  }
  .skills-list {
    padding-top: 3em;
  }
  .skill-btn span {
    /* top: 6px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    font-size: 12px; */
  }
  .man {
    width: 180px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .footer-btn a {
    width: 225px;
    font-size: 15px;
  }
  .mathpoint-item .point {
    font-size: 14px;
    top: 9%;
  }
  .cloud-1 {
    position: absolute;
    left: -35%;
    top: 0%;
    width: auto;
    height: 100%;
    z-index: -1;
  }
  .cloud-2 {
    position: absolute;
    right: -35%;
    top: 0%;
    width: auto;
    height: 100%;
    z-index: -1;
  }
  .choose-page {
    overflow: hidden;
  }
  .mathtext h1 {
    font-size: 50px;
  }
  .header-meddle h3 {
    text-align: left;
    margin-top: 10px;
  }
  .choose-item-inner::after {
    position: absolute;
    left: 50%;
    top: 7px;
    background-image: url(./assets/images/green-card.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    content: '';
    width: 66%;
    height: 60%;
    margin-left: -33%;
    z-index: -1;
  }
  .choose-main-txt {
    bottom: 23%;
  }
  .questions-tab-list ul li span {
    display: inline-block;
    width: 28px;
    height: 28px;
    background: #fff;
    line-height: 28px;
    font-size: 14px;
    color: #000;
    border-radius: 50px;
    margin: 0 8px;
  }
  .questions-tab-list ul li span.active {
    width: 40px !important;
    height: 40px !important;
    top: 0;
    position: relative;
    line-height: 40px !important;
    font-size: 20px !important;
    background: #f5c818 !important;
    border: 3px solid #fff;
  }
  .questions-tab-list {
    top: -24px;
  }
  .wrong-question img {
    width: 80px;
  }
  img.opps {
    position: absolute;
    width: 70px !important;
    top: -65px;
    right: 5px;
  }
  .correct-question img {
    width: 100px;
  }
  /*---------------- Welcome Relly Page  --------------*/
  .recent-challenges p {
    font-size: 13px;
  }
  .left-comp-text {
    width: 90%;
    float: left;
  }
  .right-comp-text {
    width: 10%;
    float: right;
  }
  .right-comp-text button {
    /* margin: ; */
    margin: 0;
  }
  .accordion {
    padding: 20px 25px;
    font-size: 14px;
    letter-spacing: 0.25px;
    padding-bottom: 46px;
  }
  button.accordion.active::after {
    top: 30px;
  }
  button.accordion::after {
    top: 27px;
  }
  .search-boxx {
    width: 280px;
    height: 48px;
    position: relative;
    float: left;
  }
  .search-boxx {
    width: 250px;
    height: 48px;
  }
  .all-msg-btn button {
    margin: 0;
    width: 160px;
    height: 46px;
    font-size: 12px;
  }
  .s-response-title h1 {
    font-size: 28px;
    margin-top: 7px;
  }
  .Participant h4 {
    font-size: 11px;
  }
  .recent-plants-txt ul li {
    /* font-size: 16px; */
  }
  .clng-itm-tags span {
    font-size: 12px;
  }
  .clng-completed {
    width: 80px;
  }
  .single-clng-2-warp .view-txt {
    padding: 15;
    font-size: 12px;
  }
  .sub-tab-list li {
    font-size: 12px;
  }
  .main-tabs-summary li {
    font-size: 12px;
  }
  .single-accivement img {
    width: 100px;
  }
  .single-accivement p {
    font-size: 16px;
  }
  .single-accivement {
    margin-top: 10px;
  }
  .com-response-text.history-com p {
    font-size: 15px;
  }
  .history-table h4 {
    font-size: 15px;
  }
  .right-attention a {
    width: 230px;
    font-size: 14px;
  }
  .left-attention p {
    font-size: 15px;
  }
  .single-progress img {
    width: 55px;
    margin: 0 auto;
  }
  .single-progress {
    width: 15%;
    margin-right: 0px !important;
    float: right;
  }
  .single-degit-number.bg-gray h4 {
    font-size: 16px;
  }
  .left-degit h4 {
    font-size: 18px;
  }
  .single-crt-warp {
    width: 95%;
    margin: 0 auto;
    display: table;
    height: 140px;
  }
}

@media only screen and (max-width: 767px) {
  .all-skills-item {
    max-width: 400px;
    margin: 0 auto;
  }
  .single-item {
    width: 100%;
    float: left;
    margin-bottom: 50px;
  }
  .man {
    display: none;
  }
  .footer-btn {
    float: none;
    width: 100%;
    text-align: center;
  }
  .skills-item-area {
    position: relative;
    width: 100%;
    top: 0;
    margin-top: 100px;
    margin-bottom: 140px;
  }
  footer {
    position: relative;
    width: 100%;
    bottom: 20px;
    z-index: 99;
    right: 0;
    /* top: 100px; */
    padding-bottom: 25px !important;
  }
  .skills-item-area {
    z-index: 900;
  }
  #overlay-container {
    z-index: 1000;
  }
  .all-skills-item {
    max-width: 350px;
    margin: 0 auto;
  }
  .main-profile {
    position: relative;
    display: none;
  }
  .header-left {
    width: 80%;
    float: left;
  }
  .header-left h4 {
    font-size: 22px;
    letter-spacing: 0.5px;
  }
  .header-left span {
    font-size: 14px;
  }
  .berger-menu {
    display: block;
  }
  .berger-menu {
    display: block;
    margin-top: 18px;
  }
  .footer-btn a {
    margin-bottom: 16px;
  }
  .menu {
    z-index: 9999999 !important;
  }
  .single-math-item {
    width: 100%;
    float: left;
  }
  .twince {
    max-width: 350px;
    margin: 0 auto;
  }
  .twince:after {
    display: none;
  }
  .single-math-item {
    margin-bottom: 35px;
  }
  .header-meddle {
    width: 60%;
  }
  .lock-item .star-item::before {
    content: '';
    width: 86%;
    height: 54%;
    position: absolute;
    left: 8%;
    top: 6%;
  }
  .star-item::after {
    height: 68%;
    top: 0;
  }
  .text-lock {
    bottom: 14%;
  }
  .choose-chapter-item {
    max-width: 350px;
    margin: 0 auto;
  }
  .choose-item {
    width: 100%;
    float: left;
    margin-bottom: 80px;
  }
  .Choose-chapter .header-meddle {
    width: 80%;
  }
  .header-meddle h3 {
    text-align: right;
  }
  .choose-chapter-area {
    margin-top: 60px;
    position: relative;
    left: 0;
    top: 0;
  }
  .mathtext {
    margin-top: -25px;
    position: relative;
    left: 0;
  }
  .mathtext h1 {
    font-size: 50px;
  }
  .choose-chapter-item-warp {
    width: 100%;
    overflow-x: unset;
    overflow-y: unset;
    white-space: unset;
  }
  .choose-page {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .questions-tab-list ul li span.active {
    width: 40px !important;
    height: 40px !important;
    top: 0;
    position: relative;
    line-height: 40px !important;
    font-size: 20px !important;
    background: #f5c818 !important;
    border: 3px solid #fff;
  }
  .questions-tab-list ul li span {
    margin: 10px 12px;
  }
  .single-hints {
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
    float: none;
  }
  .single-hints.tutorial {
    text-align: center;
    float: none;
  }
  .question-form p {
    font-size: 18px;
  }
  .start-time {
    display: inline-block;
    position: relative;
    z-index: 1;
    width: 100%;
  }
  .start-time button {
    width: 80px;
    height: 50px;
    border-radius: 14px;
    font-size: 16px;
    margin-top: 55px;
    margin-left: 15px;
  }
  .questions-tab-list ul li:after {
    top: 10px;
    height: 5px;
  }
  .more-right {
    display: none;
  }
  /*---------------- Welcome Relly Page  --------------*/
  .single-challenge-wrap {
    width: 100%;
    padding: 25px 0px;
    display: block;
  }
  .single-clng-item {
    width: 350px;
    margin: 0 auto;
    float: none;
  }
  .challenge-wrap {
    margin-top: 25px;
  }
  .clng-title {
    text-align: center;
  }
  .single-perform .CircularProgressbar {
    width: 90px;
  }
  .performance-tab-list button h4 {
    font-size: 14px;
  }
  .performance-tab-list button p {
    font-size: 13px;
  }
  .smr-warp {
    padding: 25px;
    padding-bottom: 0;
  }
  .summary-tabs {
    padding: 0 25px;
    padding-top: 35px;
  }
  .single-progress {
    width: 15%;
    margin-right: 0px !important;
    float: right;
  }
  .questions-tab-list .att:before {
    left: 11px;
    top: -50px;
  }
  .single-progress img {
    width: 50px;
  }
  .left-degit h4 {
    font-size: 16px;
    letter-spacing: 0;
  }
  .single-degit-number {
    padding: 25px;
  }
  .summary-title h4 {
    font-size: 20px;
    color: #2e3039;
  }
  .left-comp-text {
    width: 90%;
    float: left;
  }
  .right-comp-text {
    width: 10%;
    float: right;
  }
  .right-comp-text button {
    /* margin: ; */
    margin: 0;
  }
  .com-response-text p {
    margin-bottom: 15px;
  }
  .main-pro {
    width: 270px;
    border-right: 1px solid #f3f3f3;
    float: left;
    height: 100%;
    padding: 40px 0;
    position: absolute;
    z-index: 9;
    background: #fff;
    left: -500px;
    transition: 0.3s;
    padding-left: 22px;
    margin-top: -5px;
  }
  .right-content-warp {
    width: 100% !important;
    float: left;
  }
  .math-boxs {
    width: 42%;
  }
  .s-response-title h1 {
    font-size: 25px;
    padding-top: 9px;
  }
  .sign-option {
    padding-right: 15px;
  }
  .accordion {
    background-color: #fff;
    color: #2e3039;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    margin: 0;
    padding: 25px 0;
    display: inline-block;
    font-size: 18px;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.78;
    letter-spacing: 0.36px;
    margin-bottom: 0px;
    position: relative;
    border-bottom: 1px solid #f3f3f3 !important;
    padding-bottom: 55px;
  }
  .search-boxx {
    width: 280px;
    height: 48px;
    position: relative;
    float: left;
  }
  .all-msg-btn button {
    margin: 0;
    width: 150px;
    height: 46px;
    font-size: 13px;
  }
  .search-boxx {
    width: 245px;
    height: 46px;
  }
  .s-response-title h1 {
    font-size: 28px;
  }
  .menu-target {
    display: block;
  }
  .main-pro.active {
    left: 0;
    height: 2700px;
  }
  .main-profile-inner {
    padding-right: 15px;
    padding-left: 15px;
  }
  .user-txt h4 {
    font-size: 15px !important;
  }
  .user-txt p {
    font-size: 12px !important;
  }
  .main-pro.message-area {
    width: 295px;
  }
  ul.message-st span {
    background: var(--light-purple);
    width: 22px;
    height: 22px;
    display: inline-block;
    text-align: center;
    border-radius: 100%;
    margin-right: 8px;
    line-height: 22px;
    font-size: 12px;
  }
  .revived-ms {
    width: 22px;
    height: 22px;
    top: 4px;
    right: -4px;
  }
  .student-message-warp {
    margin-bottom: 70px;
  }
  .single-accivement img {
    width: 100px;
  }
  .single-accivement p {
    font-size: 14px;
  }
  .sub-tab-list li {
    font-size: 12px;
    padding: 25px 25px;
  }
  .main-tabs-summary li {
    font-size: 12px;
  }
  .recent-plants-txt ul li {
    /* font-size: 18px; */
  }
  .Participant h4 {
    font-size: 13px;
  }
  .view-txt {
    font-size: 13px;
  }
  .single-clng-item {
    margin: 20px auto;
  }
  .acctivement-warp h4 {
    margin-bottom: 25px;
  }
  .relly-left h2 {
    font-size: 28px;
    font-weight: normal;
  }
  .main-tabs-summary li {
    padding: 12px 18px;
    font-size: 12px;
  }
  .student-por .s-response-title {
    float: left;
    width: auto;
  }
  .left-attention {
    width: 100%;
    text-align: center;
  }
  .right-attention a {
    width: 200px;
    font-size: 12px;
  }
  .left-hitory-txt h4 {
    font-size: 18px;
  }
  .right-history-txt button {
    width: 130px;
    height: 40px;
    font-size: 11px;
    line-height: 40px;
  }
  .com-response-text.history-com p {
    font-size: 14px;
  }
  .history-table h4 {
    font-size: 18px;
  }
  .history-table span {
    font-size: 15px;
  }
  .clng-history-waarp {
    padding: 30px 20px;
  }
  .right-attention a {
    display: inline-block;
    width: 200px;
    font-size: 13px;
  }
  .attention-box h4 {
    font-size: 22px;
    text-align: center;
  }
  .right-attention {
    text-align: center;
    width: 100%;
    display: inline-block;
    margin-top: 25px;
  }
  .single-crt-clng {
    width: 50%;
    float: left;
    margin: 15px 0;
  }
  .calander-box tr th {
    font-size: 11px;
    font-weight: 700;
  }
  .calender-box-main {
    padding: 0 25px;
  }
  .main-msg-boxx {
    background-image: none;
    background-color: #fff;
  }
  .chat-box-main {
    width: 60%;
    top: 0;
    background-image: none;
    background-color: #f3f3f3;
    left: 0;
    height: 100%;
  }
  .message-inbox-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: 15px;
    margin-top: 50px;
    margin-right: 15px;
  }
  .message-inbox-list h4 {
    font-size: 24px;
    top: 25px;
    left: 15px;
  }
  .send-option {
    margin-top: 30px;
    position: absolute;
    width: 87%;
    bottom: 30px;
  }
  .chat-list-txt h5 {
    font-size: 15px;
  }
  .chat-list-txt span {
    font-family: Nunito;
    font-size: 11px;
  }
  .alada {
    position: relative;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 670px) {
  .calander-box th span {
    display: none;
  }
  .calander-defination {
    width: 100%;
    text-align: center;
    margin: 0;
  }
  .calander-defination li:first-child {
    margin: 0;
  }
  .chat-pro img {
    width: 45px;
    border-radius: 100%;
  }
  .chat-pro {
    display: inline-block;
    float: left;
    margin-right: 12px;
    width: 45px;
    border-radius: 100%;
    position: relative;
  }
  .message-inbox-list {
    width: 260px;
    float: left;
    background: #fff;
    position: absolute;
    left: -100%;
    top: 0;
    z-index: 9;
    height: 100%;
    box-shadow: 0 0 4px #ddd;
    transition: 0.3s;
  }
  .message-inbox-list.activee {
    left: 0;
  }
  .chat-box-main {
    width: 100%;
    top: 0;
    background-image: none;
    background-color: #f3f3f3;
    left: 0;
    height: 100%;
  }
  span.day-sms {
    left: 90%;
  }
  .main-msg-boxx {
    overflow: hidden;
  }
  span.day-sms {
    left: unset;
    margin: 0;
    right: 3%;
    top: 3.5%;
  }
  .ms-bar {
    display: block;
  }
  .ms-close {
    display: block;
  }
  .message-box-main .sk-container {
    padding: 0;
  }
  .message-box-main {
    margin-bottom: 0;
  }
  .header-bar.message-header-bar {
    padding-top: 5px;
    display: inline-block;
    width: 100%;
    padding-bottom: 15px;
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 99;
    display: none;
  }
  .message-header-bar .header-meddle h4 {
    color: #19b3fc;
  }
  .main-msg-boxx {
    width: 100%;
    height: 100% !important;
    position: absolute;
    z-index: 1;
    background-image: url(./assets/images/ms1.svg);
    background-position: center;
    background-size: cover;
  }
  .message-inbox-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: 0;
    margin-top: 50px;
    margin-right: 0px;
  }
  ul.inbox li {
    padding: 15px 13px;
  }
  .chat-box-warp {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 80px;
  }
  .right-ques-text {
    width: 100%;
    float: left;
    padding-top: 10px;
  }
  .left-ques-text {
    width: 100%;
    float: left;
  }
  .left-ques-text {
    border-bottom: none;
    padding-bottom: 10px;
  }
  .clng-ques-warp {
    padding: 25px;
  }
  .ques-progress {
    margin-left: 0;
    margin-top: 10px;
  }
  .main-clng-ques {
    padding-bottom: 25px;
    border-bottom: 1px solid #f3f3f3;
  }
  .right-ques-text p {
    display: block;
  }
}

@media only screen and (max-width: 480px) {
  .header-bar.math-header.Choose-chapter {
    margin-top: 10px;
  }
  .header-meddle h3 {
    font-size: 20px;
    margin-top: 17px;
  }
  .single-clng-item {
    margin: 20px auto;
  }
  .cloud-1 {
    position: absolute;
    left: 0;
    top: 8%;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.7;
  }
  .cloud-2 {
    top: 100%;
    width: auto;
    height: 100%;
    opacity: 0.7;
  }
  .choose-item-inner::after {
    position: absolute;
    left: 50%;
    top: -5px;
    background-image: url(./assets/images/green-card.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    content: '';
    width: 70%;
    height: 70%;
    margin-left: -34.9%;
    z-index: -1;
  }
  .start-time button {
    width: 80px;
    height: 50px;
    border-radius: 14px;
    font-size: 16px;
    margin-top: 55px;
  }
  .cross-bar.custom-bar img {
    width: 36px;
  }
  .cross-bar {
    text-align: right;
    margin-top: 30px;
  }
  .music-bar.custom-bar img {
    width: 40px;
  }
  .music-bar {
    margin-top: 30px;
  }
  .questions-tab-list ul li span {
    display: inline-block;
    width: 28px;
    height: 28px;
    background: #fff;
    line-height: 28px;
    font-size: 13px;
    color: #000;
    border-radius: 50px;
    margin: 12px 5px !important;
  }
  .questions-tab-list ul li span.active {
    width: 32px !important;
    height: 32px !important;
    top: 3px;
    position: relative;
    line-height: 34px !important;
    font-size: 18px !important;
    background: #f5c818 !important;
    border: 3px solid #fff;
  }
  .keyboard ul li a {
    width: 60px;
    display: inline-block;
    height: 60px;
    border-radius: 100%;
    background: #dbb215;
    text-align: center;
    line-height: 60px;
    font-size: 22px;
    color: #fff;
    font-weight: normal;
    margin: 12px;
  }
  .question-form input {
    width: 250px;
    height: 2.813em;
    padding: 15px;
    text-align: center;
    border-radius: 8px;
    border: none;
    background: var(--dark-sky-blue);
    font-size: 20px;
    color: #fff !important;
    outline: none;
  }
  /*---------------- Welcome Relly Page  --------------*/
  .single-clng-item {
    width: 320px;
  }
  .main-tabs-summary li {
    padding: 12px 18px;
    font-size: 13px;
  }
  .sub-tab-list li {
    font-size: 12px;
    letter-spacing: 0;
    padding: 20px 15px;
    font-size: 12px;
  }
  .tab-info-dot {
    display: inline-block;
    float: right;
    margin-top: 16px;
  }
  .single-perform img {
    width: 50px;
  }
  .smr-warp {
    padding: 25px 12px;
    padding-bottom: 0;
  }
  .history-box-title h4 {
    font-size: 20px;
  }
  .Chapters-list button {
    background: #ddd;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    margin: 0;
    margin-right: 9px;
    position: relative;
    z-index: 1;
  }
  .Chapters-list button img {
    width: 18px;
  }
  .challenge-score h4 {
    font-size: 24px;
  }
  .tag-box button {
    letter-spacing: 0.2px;
    padding: 7px 18px;
    margin: 0 5px;
  }
  .left-degit {
    width: 100%;
    margin-bottom: 15px;
  }
  .number-progress {
    width: 100%;
    float: left;
  }
  .single-progress img {
    max-width: 45px;
  }
  .welcome-page-left h2 {
    font-size: 22px;
    font-weight: normal;
  }
  .message-box {
    margin-right: 25px;
    padding-top: 7px;
  }
  .techers-profiles {
    margin-top: -7px;
  }
  .com-response-text p {
    margin-bottom: 15px;
  }
  .s-response-title h1 {
    font-size: 20px;
  }
  .back-btn {
    margin-bottom: 20px;
  }
  .s-responses-top-area {
    margin-top: -10px;
  }
  .left-completed-response {
    float: left;
    display: block;
    margin-bottom: 25px;
  }
  .request-btn {
    float: left;
    text-align: right;
  }
  .com-response-text p {
    font-size: 16px;
  }
  .math-boxs {
    width: 240px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .staric-list li a {
    padding: 17px 20px;
    font-size: 12px;
  }
  .alessandra-text {
    padding: 0 15px;
  }
  .s-response-title h1 {
    font-size: 20px;
    padding-top: 12px;
  }
  .sign-option {
    padding-right: 10px;
  }
  .profile-info {
    padding: 35px 10px;
    padding-top: 0;
  }
  .pr-tag button {
    font-size: 12px;
  }
  .help-title {
    padding: 40px 40px 20px 0px;
    margin-bottom: 25px;
  }
  .accordion {
    font-size: 14px !important;
  }
  .modal-bodyy {
    padding: 20px 0px;
  }
  .panel {
    padding: 20px 20px;
  }
  .message-sent-form-inner {
    padding: 18px 10px;
    display: flow-root;
  }
  .emoji-box {
    width: 15%;
  }
  .ms-sent-write-box {
    position: relative;
    width: 80%;
    display: inline-block;
    float: left;
    left: 0;
  }
  .ms-sent-write-box textarea {
    width: 92%;
  }
  .ms-sent-write-box button {
    position: absolute;
    right: -30px;
  }
  .search-boxx {
    width: 100%;
    height: 46px;
  }
  .all-msg-btn {
    margin-top: 15px;
    float: left;
    margin-left: 0;
  }
  .add-option {
    padding-top: 12px;
  }
  .student-ms-title {
    margin-top: 35px;
  }
  .student-name span {
    font-size: 15px;
    margin-top: 1px;
  }
  .student-name img {
    width: 28px;
    float: left;
    margin-right: 10px;
  }
  .sms-itm img {
    width: 25px;
    float: left;
  }
  .sms-itm {
    display: inline-block;
    float: left;
    position: relative;
    margin-left: 15px;
  }
  .sms-itm span {
    position: absolute;
    right: -3px;
    width: 15px;
    height: 15px;
  }
  .add-option ul li a {
    font-size: 12px !important;
    text-transform: uppercase;
    color: #657d95 !important;
  }
  .sms-itm span img {
    width: 6px;
    padding: 5px;
  }
  .s-response-title h1 {
    font-size: 25px;
  }
  .setting-itm {
    display: inline-block;
    width: 85%;
    padding-left: 20px !important;
  }
  .setting-itm {
    width: 87%;
    padding: 24px 10px;
  }
  .setting-feedback textarea {
    width: 90%;
  }
  .main-profile-inner {
    padding-right: 15px;
    padding-left: 15px;
  }
  .sub-tab-list li {
    font-size: 12px;
    letter-spacing: 0;
    padding: 20px 13px;
  }
  .single-clng-item {
    margin-bottom: 15px;
  }
  .single-accivement {
    width: 50%;
    margin: 25px 0;
  }
  .single-accivement img {
    width: 100px;
  }
  .single-accivement p {
    font-size: 16px;
  }
  .sub-tab-list li {
    font-size: 12px;
    letter-spacing: 0;
    padding: 20px 8px;
  }
  .tab-info-dot span {
    width: 4px;
    height: 4px;
  }
  .main-tabs-summary li {
    font-size: 12px;
  }
  .right-attention a {
    float: none;
  }
  .left-hitory-txt {
    display: inline-block;
    float: none;
    text-align: center;
    width: 100%;
    margin-bottom: 25px;
  }
  .left-hitory-txt h4 {
    font-size: 18px;
    text-align: center;
  }
  .history-table button {
    font-size: 10px;
    width: 95px;
    height: 24px;
    border-radius: 12px;
    position: relative;
    top: -40px;
  }
  .history-table h4 {
    font-size: 18px;
    position: relative;
    top: -35px;
  }
  .com-response-text h5 {
    font-size: 11px;
  }
  .calander-box tr th {
    font-size: 9px;
    font-weight: 700;
  }
  .calender-box-main {
    padding: 0 0px;
  }
  .calander-box tr td {
    padding-left: 10px;
    padding-top: 15px;
    padding-bottom: 20px;
    font-size: 11px;
  }
  .calander-box th span {
    display: none;
  }
  .calander-box tr td span {
    width: 5px;
    height: 5px;
    margin: 0 1px;
  }
  .single-crt-warp {
    width: 93%;
    height: 150px;
  }
  .calander-defination li {
    display: inline-block;
    margin-left: 15px;
    font-size: 11px;
  }
  .calander-defination {
    width: 100%;
    text-align: center;
    margin: 0;
  }
  .calander-defination li:first-child {
    margin: 0;
  }
  .chat-box-warp {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 60px;
  }
  .header-back a {
    background: #fff;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 8px;
  }
  .header-back svg {
    width: 24px;
    line-height: 9px !important;
    padding-top: 13px;
  }
  .header-meddle h3 {
    font-size: 24px;
    margin-top: 13px;
  }
  p.alignleft.big-txt {
    width: 235px;
    float: left;
    background-image: url(./assets/images/ms4.svg);
    height: 62px;
    position: relative;
  }
  .sent-input {
    background-size: contain;
    position: relative;
    height: 55px;
  }
  .emoji-boxx img {
    width: 32px;
  }
  .emoji-boxx {
    width: 13%;
    float: left;
    padding-top: 16px;
  }
  .sent-input img {
    width: 38px;
  }
  .sent-input button {
    position: absolute;
    cursor: pointer;
    right: -10px;
    top: 0%;
  }
  .message-box-main {
    margin-bottom: 0;
  }
  .history-box-title {
    padding: 40px 15px;
  }
  .history-box-title h4 {
    font-size: 18px;
  }
  .single-degit-number {
    padding: 20px;
  }
  .single-progress img {
    max-width: 40px;
  }
  .tab button {
    padding: 14px 5px;
    transition: 0.3s;
  }
  .performance-tab-list button h4 {
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0;
    font-weight: bold !important;
  }
  .performance-tab-list button p {
    font-size: 10px;
    font-weight: 600;
  }
  .left-sub-title {
    width: 45%;
    float: left;
  }
  .right-sub-title {
    float: left;
    width: 55%;
  }
  .sub-title-ques span {
    font-size: 12px;
    letter-spacing: 0;
  }
}