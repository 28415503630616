.history-analytics .day-report-card {
  position: relative;
  width: 215px;
  height: 186px;
  border-radius: 18px;
  box-shadow: 0 6px 12px 0 rgba(65, 61, 61, 0.16);
  background-color: white;
  overflow: hidden;
  z-index: 100;
}

.Rectangle-8 {
  width: 215px;
  height: 35px;
  background-color: var(--light-purple);
}

.Avg-Score-80 {
  padding: 8px 0px;
  opacity: 0.8;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: white;
  text-transform: uppercase;
}

.circle-rect {
  height: 108px;
}

.CHALLENGE {
  padding: 8px 0px;
  height: 16px;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: center;
  color: var(--grey-blue);
  text-transform: uppercase;
}

.day-report-card .CircularProgressbar {
  width: 42px;
}

.Rectangle-5440 {
  padding: 14px;
  width: 205px;
  height: 43px;
  background-color: var(--white);
  font-family: Nunito;
  font-size: 12px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.54px;
  text-align: center;
  color: var(--light-purple);
}
