

.str-chat__channel-preview button  {
    min-height: 93px !important;
}

.str-chat__channel-list-team {
    overflow-y: scroll;
    overflow-x: hidden;
}

.str-chat-channel-list {
    width: 40% !important;
}

.message-card-inner div {
    background: white !important;
    color: black !important;
}

.str-chat__channel-preview--active .str-chat__channel-preview-title {
    color: black !important;
}

.foo {
    width: 32px; height: 32px; flex-basis: 32px; line-height: 32px; font-size: 16px;
}

.str-chat-channel.messaging .str-chat__main-panel {
    padding: 0px !important;
}

.str-chat__channel-list-team__header {
    margin-left: 40px;
}

.str-chat-channel.messaging .str-chat__main-panel div {
    background: lightgray !important;
}

.str-chat *, .str-chat *::after, .str-chat *::before {
    font-family: Nunito !important;
}

.str-chat__input-emojiselect, .str-chat__input-fileupload {
    margin-top: -20px !important;
}