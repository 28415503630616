.showToast {
  display: block;
}

.sk-toast {
  width: 100%;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0.6;
  border: solid 1px rgba(0, 0, 0, 0);
  background-color: #332828;
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.79;
  letter-spacing: 0.28px;
  text-align: center;
  color: var(--true-white);
}
