.settings-wrapper {
  position: absolute;
  background-color: transparent;
  background-position: center 4em;
  background-repeat: no-repeat;
  background-size: 35em;
  height: 50em;
  width: 100%;
  margin: 0px auto;
  background-image: url('../../../assets/images/popup-background.png');
}

.settings-box {
  position: relative;
  margin: auto;
  width: 540px;
  /* height: 595px; */
  margin-top: 80px;
  /* background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 520px 570px;
  background-image: url('../../../assets/images/Path-13126.png'); */
}

.settings-box h1 {
  position: relative;
  margin: auto;
  color: white;
  width: max-content;
  padding-top: 20px;
  text-align: center;
}

.settings-box .close {
  float: right;
  padding: 10px;
}

.settings-box-options {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 80%;
  height: 21em;
  margin: auto;
  font-size: 18px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  text-align: left;
  color: var(--charcoal-grey-two);
  /* scrollbr firefox */
  scrollbar-color: rgb(102, 202, 246, 0.2) transparent;
  scrollbar-width: thin;
}

/* scrollbr chrome */
.settings-box-options::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}
.settings-box-options::-webkit-scrollbar-thumb {
  background: rgb(102, 202, 246, 0.2);
  border-radius: 10px;
}

.settings-box-options li {
  background-color: transparent;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 44px;
  background-image: url('../../../assets/images/notification.svg');
}

.settings-box-options div {
  margin-left: 64px;
  min-height: 45px;
}

.settings-box-options .level_up {
  background-image: url('../../../assets/images/level-up.svg');
}

.settings-box-options .applause {
  background-image: url('../../../assets/images/clap-icon.svg');
}

.settings-box-options .complete_request {
  background-image: url('../../../assets/images/subject-icon.svg');
}

.settings-box-options span {
  padding-left: 0px !important;
  opacity: 0.6;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: var(--charcoal-grey-two);
}

.settings-box-options {
  margin: 40px;
  padding: 20px 13px;
}

.settings-box-options li:not(:last-child) {
  border-bottom: 1px solid rgb(112, 112, 112, 0.2) !important;
  margin: 20px 0px;
  padding: 0px 0px 20px 0px;
}

.settings-box-options li span {
  padding-left: 20px;
}

.settings-box-options .complete_request {
  color: #f43d6e !important;
}

.settings-box-options .viewed {
  font-weight: 600 !important;
  color: var(--charcoal-grey-two) !important;
}

.app-version {
  position: fixed;
  width: inherit;
  margin: auto;
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.28px;
  text-align: center;
  color: var(--charcoal-grey-two);
}

.settings-box-options .sound {
  background-color: transparent;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 42px 42px;
  background-image: url('../../../assets/images/sound-icon.svg');
}

.settings-box-options .spacer {
  width: 98px;
  height: 36px;
  opacity: 0.1;
}

.settings-box-options .sound-switch {
  background-color: transparent;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 72px 36px;
  width: 72px;
  background-image: url('../../../assets/images/Rectangle-5302.png');
  float: right;
  padding: 2px;
}

.settings-box-options .update {
  background-color: transparent;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 42px 42px;
  background-image: url('../../../assets/images/level-up.svg');
}

.settings-box-options .update-switch {
  position: relative;
  float: right;
  background: url('../../../assets/images/Rectangle-5303.png') no-repeat;
  cursor: pointer;
  width: 98px;
  height: 36px;
  top: 0px;
}

.settings-box-options .sub-text {
  position: relative;
  top: -10px;
}

.settings-box-options .rate {
  background-color: transparent;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 42px 42px;
  background-image: url('../../../assets/images/Image-51.png');
}

.settings-box-options .help {
  background-color: transparent;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 42px 42px;
  background-image: url('../../../assets/images/Image-50.png');
}

.dismiss-button {
  position: relative;
  width: 144px;
  font-size: 20px;
  height: 80px;
  margin: auto;
  background: url('../../../assets/images/login-button.png') no-repeat;
  background-position: center top;
  background-size: contain;
  font-weight: 800;
  line-height: 1;
  letter-spacing: 0.9px;
  text-align: center;
  color: var(--true-white);
  bottom: 25px;
}

.stopTalking {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}

.achievements-wrapper h1 {
  margin: auto;
  font-size: 38px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.34;
  letter-spacing: normal;
  text-align: center;
  color: var(--true-white);
  padding-top: 1.5em;
}

.achievements-wrapper {
  height: 70%;
  position: absolute;
  background-color: transparent;
  background-position: center 7em;
  background-repeat: no-repeat;
  background-size: 58em;
  height: 50em;
  width: 100%;
  margin: 0px auto;
  background-image: url('../../../assets/images/achievements-base.png');
}

.achievements-wrapper .settings-box {
  width: 54em;
  margin: 2em auto;
  padding-left: 5em;
  padding-right: 3.8em;
  max-height: 80%;
}

.achievements-wrapper ul {
  margin: 30px auto;
}
.achievements-wrapper li {
  width: 46em;
  margin: 20px auto;
  height: 6em;
  background-image: none;
}
.achievements-wrapper li:not(:last-child) {
  border-bottom: 1px solid rgb(112, 112, 112, 0.2) !important;
}

.ach-image {
  float: left;
  width: 15%;
  margin-right: 1em;
}

.ach-image img {
  width: 92px;
}

.arch-wrapper {
  width: 25em;
  float: left;
  margin-left: 23px;
  margin-right: 3em;
}

.ach-content {
  width: 100%;
  float: left;
  /* border: 1px solid palegoldenrod; */
}

.ach-claim {
  width: 10%;
  float: left;
}

.ach-title {
  width: 60%;
  height: 32px;
  font-family: Nunito;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: left;
  color: var(--charcoal-grey-two);
}

.ach-score {
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.28px;
  text-align: right;
  color: var(--charcoal-grey-two);
  float: right;
  margin-bottom: 13px;
}

.ach-score span {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: center;
  color: var(--charcoal-grey-two);
}

.achievements-progress {
  display: inline-block;
  background: #f3f3f3;
  width: 100%;
  height: 18px;
  min-height: 10px !important;
  border-radius: 4px;
  background-color: #ededed;
  float: left;
  position: relative;
  margin-top: 13px;
}

.achievements-progress span {
  position: absolute;
  /* width: 80%; */
  height: 100%;
  background: var(--golden-yellow);
  border-radius: 4px;
  left: 0;
  top: 0;
}
