.Card {
  /* display: flex; */
  /* align-items: flex-start; */
  /* justify-content: flex-start; */
  /* box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08); */
  box-sizing: border-box;
  margin: 40px auto;
  width: fit-content;
  height: 85%;
  width: 80%;
}

.Upload {
  /* display: flex; */
  /* flex-direction: column; */
  /*flex: 1; */
  /* align-items: flex-start; */
  width: inherit;
  margin: auto;
  text-align: left;
  overflow: hidden;
  height: 100%;
}

.Content {
  /* display: flex; */
  flex-direction: row;
  padding-top: 40px;
  box-sizing: border-box;
  width: 100%;
  height: 70%;
  background-color: #f3f3f3;
  border-radius: 10%;
}

.Files {
  /* margin-left: 32px; */
  margin: auto;
  /* align-items: flex-start; */
  /*justify-items: flex-start; */
  /*flex: 1; */
  overflow-y: auto;
}

.Actions {
  /* display: flex; */
  /*flex: 1; */
  width: 100%;
  align-items: flex-end;
  /* flex-direction: column; */
  height: 80px;
  margin: 30px auto;
  position: relative;
}

.Actions button {
  width: 100%;
  padding: 15px;
  text-align: center;
  height: 80px;
  margin: auto;
  background: url('../../assets/images/login-button.png') no-repeat;
  background-position: 50%;
  background-size: contain;
  font-weight: 800;
  line-height: 1;
  letter-spacing: 0.9px;
  text-align: center;
  color: var(--true-white);
}

.Title {
  margin-bottom: 32px;
  color: #555;
}

.Filename {
  margin-bottom: 8px;
  /* font-size: 16px;
  color: #555; */
}

.Row {
  /* display: flex; */
  /*flex: 1; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
  text-align: center;
  width: inherit;
  height: 50px;
  padding: 8px;
  overflow: hidden;
  box-sizing: border-box;
}

.CheckIcon {
  opacity: 0.5;
  margin-left: 32px;
}

.preview {
  width: 100%;
  border-radius: 50%;
}

.ProgressWrapper {
  /* display: flex; */
  /*flex: 1; */
  flex-direction: row;
  align-items: center;
}

/* button {
  font-family: 'Roboto medium', sans-serif;
  font-size: 14px;
  display: inline-block;
  height: 36px;
  min-width: 88px;
  padding: 6px 16px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  border-radius: 2px;
  background: rgba(103, 58, 183, 1);
  color: #fff;
  outline: 0;
} */

button:disabled {
  background: rgb(189, 189, 189);
  cursor: default;
}
