.str-chat {
  height: 100% !important;
}

.message-playground {
  height: inherit;
}

.top-left-button {
  position: absolute;
  padding: 0px 40px;
  z-index: 3;
}

.message-wrapper {
  position: relative;
  width: fit-content;
  margin: auto;
  height: 280px;
  width: fit-content;
}

.message-wrapper h1 {
  position: relative;
  font-weight: bold;
  text-align: center;
  margin: 10px;
  color: white;
}

.message-card {
  position: relative;
  margin: auto;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 37.813em;
  width: 61.25em;
  background-image: url('../../../assets/images/Rectangle-messages.png');
}

.message-card-inner {
  padding: 15px 25px;
  height: 80%;
  width: 85%;
  padding-top: 3.125em;
  margin: auto;
}

.people-pane {
  position: relative;
  float: left;
  background-color: transparent;
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 552px;
  width: 399px;
  background-image: url('../../../assets/images/Subtraction-people.png');
  top: 6px;
}

.people-pane h1 {
  padding: 70px 0px 0px 40px;
  color: rgb(25, 179, 252);
  text-align: left;
}

.message-pane {
  position: relative;
  float: right;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 552px;
  width: 525px;
  top: 6px;
  background-image: url(/static/media/Rectangle-mesesag-details.566a7697.png);
}

.people-wrapper {
  position: relative;
  margin: auto;
  width: 90%;
  height: 70%;
  overflow-x: hidden;
  overflow-y: scroll;
  white-space: nowrap;
}

/* width */

.people-wrapper ::-webkit-scrollbar {
  width: 8px;
  height: 120px;
}

/* Track */

.people-wrapper ::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */

.people-wrapper ::-webkit-scrollbar-thumb {
  background: rgb(25, 179, 252, 0.4);
}

/* Handle on hover */

.people-wrapper ::-webkit-scrollbar-thumb:hover {
  background: rgb(25, 179, 252, 0.4);
}

.family-details {
  width: 336px;
  height: 92px;
}

.people-wrapper li {
  border-bottom: 1px black solid;
}

.people-wrapper li:last-child {
  border: none;
}

.family-details img {
  border-radius: 50%;
  height: 56px;
  width: 56px;
  float: left;
  margin-top: 10px;
  margin-right: 10px;
}

.message-profile-details {
  text-align: left;
  padding-left: 10px;
  white-space: nowrap;
}

.messages-demo {
  margin: 0 auto;
  position: relative;
  padding: 40px;
  background-image: url('../../../assets/images/messages-demo@2x.png');
  background-position: center;
  background-size: contain;
  height: 60vh;
  background-repeat: no-repeat;
  padding-top: 80px;
}

.wiggles {
  animation: wiggles 2.5s infinite;
}

@keyframes wiggles {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(5deg);
  }
  95% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}