.error-handler form button {
  width: 144px;
  height: 80px;
  margin: auto;
  background: url('../../../assets/images/login-button.png') no-repeat;
  background-position: center;
  font-size: 20px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: 0.9px;
  text-align: center;
  color: var(--true-white);
}

.error-handler .wide-login-button {
  margin: auto;
}

.error-handler .cancel {
  color: black;
  padding-top: 25px;
  
  font-size: 20px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 0.9px;
  text-align: center;
  text-transform: uppercase;
}
