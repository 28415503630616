.parent-reports .lesson-progress {
  width: 172px;
  height: 8px;
  
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.56px;
  text-align: left;
  color: var(--bluey-grey);
  text-transform: uppercase;
  overflow: hidden;
}

.parent-reports .progress-background {
  width: initial;
  height: 8px;
  border-radius: 4px;
  background-color: #ededed;
}

.parent-reports .progress-progress {
  height: 8px;
  border-radius: 4px;
  background-color: #40d360;
}

.parent-reports .science {
  background-color: #f43d6e !important;
}

.parent-reports .math {
  background-color: #ffc501 !important;
}

.parent-reports .word_study {
  background-color: #a463f7 !important;
}

.parent-reports .social_studies {
  background-color: #0d9792 !important;
}
