.questions-tab-list .incomplete span {
  background: #0e85ba !important;
  color: var(--true-white);
}

.questions-tab-list .incomplete:after {
  background: #0e85ba !important;
}

.questions-tab-list .active:after {
  background: #0e85ba !important;
}

.questions-tab-list .incorrect a {
  color: #ff1414 !important;
}

.questions-tab-list .hide {
  display: none;
}

.questions-tab-list .highlight {
  opacity: 0.6;
}

.questions-tab-list .highlight:after {
  opacity: 0.5;
}

.questions-tab-list .last:after {
  opacity: 0.5;
}
