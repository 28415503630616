.profile-widget-container {
  width: 340px;
  max-height: fit-content;
  float: right;
  overflow: hidden;
}

.meter-wrapper {
  background-color: transparent;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 308px 59px;
  height: 60px;
  margin-top: 7px;
}

.meter {
  width: 70%;
  overflow: hidden;
  float: left;
}

/* .progress {
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  height: 34px;
  width: 205px;
  margin: 9px 0px 0px 11px;
} */

.level {
  font-size: 14pt;
  font-weight: 800;
  font-style: normal;
  line-height: 20pt;
  margin: 3px 10px;
  float: left;
}

.level span {
  font-size: 10pt;
}

.profile-points {
  font-size: 10pt;
  font-weight: 800;
  font-style: normal;
  line-height: 20pt;
  margin: 5px 10px;
  float: right;
}

.profile-image {
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  margin: 0px;
  height: 70px;
  width: 70px;
  float: right;
}

.profile-image img {
  width: 50px;
  height: 50px;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50px;
  height: 50px;
  z-index: 1;
  top: 7px;
  border-radius: 10px;
}

.pro-ic .online {
  height: 20px;
  width: 20px;
  background-color: rgb(112, 232, 43);
  border-radius: 50%;
  border: 2px white solid;
  display: inline-block;
  position: relative;
  right: 15px;
  bottom: 23px;
  z-index: 2;
}
